export const SHOW_CONFIRMMESSAGE = 'SHOW_CONFIRMMESSAGE'
export const HIDE_CONFIRMMESSAGE = 'HIDE_CONFIRMMESSAGE'

export function showMessage(msg, extra) {
  return dispatch => {
    const action = {
      type: SHOW_CONFIRMMESSAGE,
      payload: msg,
      extra
    }
    dispatch(action)
  }
}

export function hideMessage(msg, extra) {
  return dispatch => {
    const action = {
      type: HIDE_CONFIRMMESSAGE,
      payload: msg,
      extra
    }
    dispatch(action)
  }
}
