import {
  WARRANTY_COURIERLIST_REQUEST,
  WARRANTY_COURIERLIST_SUCCESS,
  WARRANTY_COURIERLIST_FAILURE,
  WARRANTY_COURIERS_DELETE_REQUEST,
  WARRANTY_COURIERS_DELETE_SUCCESS,
  WARRANTY_COURIERS_DELETE_FAILURE,
  // WARRANTY_COURIERSASSIGN_REQUEST,
  WARRANTY_COURIERSASSIGN_SUCCESS,
  // WARRANTY_COURIERSASSIGN_FAILURE,
  UPDATE_WARRANTYASSIGN_FILTERS,
  SET_WARRANTYASSIGN_ACTION_ACTIVE
} from '../../actions/warrantyAssignActions'

import { COURIER_LIST_SUCCESS } from '../../actions/courierActions'

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    needRefresh: false,
    data: [],
    dataDetail: {},
    warrantyAssignData: {},
    courierData: {},
    errors: [],
    filters: {
      search: '',
      page: 1,
      type: [],
      status: [],
      created_at_0: null,
      created_at_1: null,
      ordering: ''
    },
    activeAction: null,
    warrantyAssignConfirmation: ''
  },
  action
) {
  switch (action.type) {
    case WARRANTY_COURIERLIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case WARRANTY_COURIERLIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case WARRANTY_COURIERLIST_FAILURE:
      return Object.assign({}, state, {
        sFetching: false,
        data: action.data
      })

    case WARRANTY_COURIERS_DELETE_REQUEST:
      return Object.assign({}, state, {
        isDeleting: true
      })

    case WARRANTY_COURIERS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        activeAction: null,
        needRefresh: true
      })

    case WARRANTY_COURIERS_DELETE_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false
      })

    case WARRANTY_COURIERSASSIGN_SUCCESS:
      // window.location = `/couriers/warranties/history/${action.data.id}`
      return Object.assign({}, state, {
        isFetching: false,
        warrantyAssignData: action.data
      })

    case UPDATE_WARRANTYASSIGN_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    case COURIER_LIST_SUCCESS:
      return Object.assign({}, state, {
        courierData: action.data
      })

    case SET_WARRANTYASSIGN_ACTION_ACTIVE:
      let newState = action.data
        ? {
            activeAction: action.data
          }
        : {
            warrantyAssignConfirmation: '',
            warrantyAssignErrors: [],
            errors: [],
            activeAction: null
          }
      return Object.assign({}, state, newState)

    default:
      return state
  }
}
