import Infinite from 'react-infinite'
import React, { Component } from 'react'

import { DataCard } from '../04-layouts/Card'
import { timer, limitTime, marginTime } from '../../utils/index'
import { showClient, arrOrderOptions } from '../../utils/index'

const ListItem = props => {
  const { element, orderClick, orderMapClick, orderOptionsClick } = props
  const time = timer(element.created_at)
  const { label, timeago } = time
  const orderOptions = arrOrderOptions(element.status, orderOptionsClick)

  return (
    <DataCard
      key={element.id}
      minutesAgo={timeago.minutes}
      isWillbeCancel={
        !!(
          timeago.minutes >= limitTime - marginTime &&
          timeago.minutes < limitTime &&
          element.type === 1 &&
          element.status === 'searching'
        )
      }
      position={
        element.destinations.length > 0
          ? element.destinations[0].latlon
          : false
      }
      title={showClient(element.client)}
      firstText={{
        vehicle: element.vehicle_type.name,
        url: `${element.vehicle_type.image}`
      }}
      status={element.status}
      programmed_date={element.programmed_date}
      status_label={element.status_label}
      status_destination={element.current_destination_label}
      subtitle1={element.code}
      orderOptions={orderOptions}
      delayedDetail={element.delayed_detail}
      orderId={element.id}
      url={element.url}
      orderFilter={props.orderFilter}
      subtitle2={
        element.destinations.length > 0
          ? element.destinations[0].name_destination
          : '-'
      }
      subtitle3={
        element.destinations.length > 2
          ? `Hay ${element.destinations.length - 1} destino(s) más...`
          : element.destinations[1].name_destination
      }
      leftText={`${element.distance} KM`}
      middleText={element.total_price ? `S/ ${element.total_price}` : '-'}
      rightText={label}
      onButtonClick={e => {
        e.stopPropagation(e)
        orderMapClick(element)
      }}
      onClick={() => orderClick(element)}
      filter={element.status}
    />
  )
}

export class InfiniteList extends Component {
  constructor(props) {
    super(props)
    this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this)
    this.timer = this.timer.bind(this)
    this.state = { isInfiniteLoading: false, elements: [], intervalId: false }
    this.tmpOrders = []
  }

  elementInfiniteLoad() {
    return <div className="infinite-list-item">Loading...</div>
  }

  componentDidMount() {
    const intervalId = setInterval(this.timer, 30000)
    this.setState({ intervalId })
  }

  timer() {
    const newElements = this.buildElements(
      0,
      this.tmpOrders.length,
      this.tmpOrders
    )
    if (newElements.length) {
      this.setState({ elements: newElements })
    }
  }

  buildElements(start, end, elements) {
    const tmpElements = []
    if (elements) {
      for (let i = start; i < end; i++) {
        if (elements[i]) {
          tmpElements.push(
            <ListItem
              orderClick={this.props.orderClick}
              orderMapClick={this.props.orderMapClick}
              orderFilter={this.props.orderFilters}
              orderOptionsClick={this.props.orderOptionsClick}
              element={elements[i]}
              indexKey={i}
              key={i}
            />
          )
        }
      }
    }
    return tmpElements
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.elements !== this.props.elements &&
      nextProps.elements.length !== 0
    ) {
      if (nextProps.elements.current_page === 1) {
        this.tmpOrders = nextProps.elements.results
      } else if (
        nextProps.elements.current_page !== this.props.elements.current_page
      ) {
        this.tmpOrders = this.tmpOrders.concat(nextProps.elements.results)
      } else {
        this.props.updateOrderDashboardFilters({ page: 1 })
        return false
      }
      const newElements = this.buildElements(
        0,
        this.tmpOrders.length,
        this.tmpOrders
      )
      this.setState({ elements: newElements, isInfiniteLoading: false })
    }
  }

  componentWillUnmount() {
    this.setState({ intervalId: false })
    this.timer && clearInterval(this.state.intervalId)
  }

  handleInfiniteLoad() {
    this.setState({
      isInfiniteLoading: true
    })
    if (!this.state.isInfiniteLoading) {
      if (this.props.previusPage < this.props.totalPages) {
        this.props.updateOrderDashboardFilters({
          page: this.props.previusPage + 1
        })
      }
    }
  }

  render() {
    return (
      <Infinite
        elementHeight={150}
        containerHeight={640}
        infiniteLoadBeginEdgeOffset={100}
        onInfiniteLoad={this.handleInfiniteLoad}
        isInfiniteLoading={this.state.isInfiniteLoading}
      >
        {this.state.elements}
      </Infinite>
    )
  }
}
