import React from 'react'
import PropTypes from 'prop-types'

/**
 * Notification Component
 * @param {Object} props
 * @callback props.onClick
 * @param {Array} props.values
 * @param {Array} props.message
 */
const NotificationItem = ({ onClick, values, message }) => {
  const [delayed, total] = values
  const [delayedMessage, totalMessage] = message
  return (
    <li>
      <button
        type="button"
        onClick={onClick}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          color: delayed >= 1 ? '#ed5565' : 'inherit',
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          outline: 'none'
        }}
      >
        <span>
          {delayed} {delayedMessage} {total} {totalMessage}
        </span>
        <span className={`fa fa-bell bell ${delayed >= 1 ? 'ring' : ''}`} />
      </button>
    </li>
  )
}

NotificationItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  message: PropTypes.array.isRequired
}

export default NotificationItem
