import {
  KARDEX_LIST_REQUEST,
  KARDEX_LIST_SUCCESS,
  KARDEX_LIST_FAILURE,
  UPDATE_KARDEX_FILTERS
} from '../../actions/storageActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    data: [],
    filters: {
      search: '',
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case KARDEX_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case KARDEX_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case KARDEX_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case UPDATE_KARDEX_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
