import React from 'react'
import _ from 'lodash'
import { Tabs, Tab } from 'react-bootstrap'

const FilterSearchBy = ({ filterByList, updateCallback, filters }) => {
  const [searchInput, setSearchInput] = React.useState({})
  const [searchCriteria, setSearchCriteria] = React.useState(filterByList[0])
  const [activeKey, setActiveKey] = React.useState(0)

  const updateSearchState = e => {
    setSearchInput({ [e.target.name]: e.target.value })
    if (e.target.value === '') {
      updateCallback({ [e.target.name]: '', page: 1 })
    }
  }

  const sendState = e => {
    if (e.key === 'Enter' && searchInput[e.target.name]) {
      return updateCallback({
        page: 1,
        [e.target.name]: searchInput[e.target.name]
      })
    }
    return false
  }

  const setInitialState = () => {
    filterByList.forEach((item, index) => {
      const prevFilterValue = filters[item.name]
      if (_.has(filters, item.name) && Boolean(prevFilterValue)) {
        setActiveKey(index)
        setSearchInput({ [item.name]: prevFilterValue })
        setSearchCriteria(item)
      }
    })
  }

  const handleTabsSelect = index => {
    const filter = filterByList[index]
    const currentFilterValue = searchInput[searchCriteria.name]
    setActiveKey(index)
    if (currentFilterValue) {
      updateCallback({
        ...filters,
        [searchCriteria.name]: undefined,
        [filter.name]: currentFilterValue
      })
    }
    if (filter) {
      setSearchInput({
        [filter.name]: currentFilterValue
      })
      setSearchCriteria(filter)
    }
  }

  React.useEffect(() => {
    setInitialState()
  }, [])

  return (
    <React.Fragment>
      <label className="filter-label-element">Buscar por</label>
      <input
        type="text"
        name={searchCriteria.name}
        placeholder={searchCriteria.placeholder}
        className="form-control"
        value={searchInput[searchCriteria.name]}
        onKeyPress={sendState}
        onChange={updateSearchState}
        style={{
          height: '2.2rem',
          marginTop: '5px',
          marginLeft: '1rem',
          width: '50%'
        }}
      />

      {filterByList && (
        <div style={{ margin: '1rem' }}>
          <Tabs
            id="filterTabs"
            activeKey={activeKey}
            onSelect={eventKey => handleTabsSelect(eventKey)}
            variant="pills"
          >
            {filterByList.map((item, index) => (
              <Tab key={index} eventKey={index} title={item.label} />
            ))}
          </Tabs>
        </div>
      )}
    </React.Fragment>
  )
}

export default FilterSearchBy
