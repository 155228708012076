import axios from 'axios'

import { hasPermission } from '../utils'
import { permissionLevels } from '../utils/variables'

const BASE_URL = process.env.REACT_APP_BASE_API_URL
const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN
const BASE_LOCAL_URL = 'http://localhost:3004/'
const BASE_URL_STORE = process.env.REACT_APP_STORE_URL // 'http://127.0.0.1:8000/api/' //

let CancelToken
let cancel
const calculateUrl = (isPublic, localAPI, endpoint, storeAPI) => {
  let url
  if (localAPI) {
    url = `${BASE_LOCAL_URL}${endpoint}`
  } else if (isPublic) {
    url = `${BASE_DOMAIN}${endpoint}`
  } else if (storeAPI) {
    url = `${BASE_URL_STORE}${endpoint}`
  } else {
    url = `${BASE_URL}${endpoint}`
  }
  return url
}
export function callApi(
  method,
  endpoint,
  data,
  authenticated,
  localAPI,
  isPublic,
  msg,
  storeAPI
) {
  if (msg === 'order') {
    CancelToken = axios.CancelToken
    if (msg === 'courier') {
      CancelToken = axios.CancelToken
    }
  } else {
    CancelToken = false
  }
  const token = localStorage.getItem('token') || null
  const config = {
    method,
    url: calculateUrl(isPublic, localAPI, endpoint, storeAPI),
    data,
    cancelToken: CancelToken
      ? new CancelToken(c => {
          cancel = c
        })
      : false
  }
  if (authenticated) {
    if (token) {
      config.headers = {
        Authorization: `JWT ${token}`,
        'Accept-Language': 'es',
        'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    } else {
      window.location = '/login/'
      // throw Error("No token saved!")
    }
  }
  return axios(config)
}

export const CALL_API = Symbol('Call API')

export default store => next => action => {
  const callAPI = action[CALL_API]

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  const {
    method,
    endpoint,
    data,
    authenticated,
    types,
    extra,
    localAPI,
    isPublic,
    msg,
    storeAPI
  } = callAPI
  const [requestType, successType, errorType] = types

  if (cancel) cancel()
  next({ type: requestType, extra })

  if (!hasPermission('shop', permissionLevels.READ) && storeAPI) {
    return false
  }

  return callApi(
    method,
    endpoint,
    data,
    authenticated,
    localAPI,
    isPublic,
    msg,
    storeAPI
  )
    .then(response => {
      next({
        extra,
        data: response.data,
        type: successType
      })
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        localStorage.clear()
        window.location = '/login'
      }
      return next({
        errors: error.response ? error.response.data : { error: 'error' },
        type: errorType
      })
    })
}
