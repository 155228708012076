import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const UPDATE_WARRANTY_FILTERS = 'UPDATE_WARRANTY_FILTERS'
export const RESET_WARRANTY = 'RESET_WARRANTY'
export const UPDATE_WARRANTYCOURIER_FILTERS = 'UPDATE_WARRANTYCOURIER_FILTERS'

const warrantyActions = {}

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  const arrType = arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    warrantyActions[type] = type
    return type
  })
  return arrType
}

export function warrantyList(params = {}, opt) {
  const { method, entity, action } = opt
  let url = 'warranties/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method,
      localAPI: false,
      endpoint: url,
      authenticated: true,
      types: genTypeActions(entity, action)
    }
  }
}

export function warrantyReset() {
  return dispatch => {
    const action = {
      type: RESET_WARRANTY
    }
    dispatch(action)
  }
}

export function updateWarrantyFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_WARRANTY_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function createWarranty(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `warranties/`,
      authenticated: true,
      data,
      types: genTypeActions('warranty', 'create')
    }
  }
}

export function editWarranty(data, id) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `warranties/${id}/`,
      authenticated: true,
      data,
      types: genTypeActions('warranty', 'update')
    }
  }
}

export function warrantyDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `warranties/${id}/`,
      authenticated: true,
      types: genTypeActions('warranty', 'detail')
    }
  }
}

export function warrantyDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `warranties/${id}/`,
      authenticated: true,
      types: genTypeActions('warranty', 'delete')
    }
  }
}

genTypeActions('warranty', 'list')
genTypeActions('warranty', 'delete')
genTypeActions('warranty', 'create')
genTypeActions('warranty', 'update')
export default warrantyActions
