import React, { Component } from 'react'

export class FilterInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      [props.name]: ''
    }
    this.updateSearchState = this.updateSearchState.bind(this)
  }

  updateSearchState(e) {
    const { updateCallback } = this.props
    this.setState({ [e.target.name]: e.target.value })
    updateCallback({ [e.target.name]: e.target.value, page: 1 })
  }

  render() {
    const { placeholder, label, name, spaced } = this.props
    return (
      <div style={{ margin: spaced ? '15px 0' : '0' }}>
        {label ? <label>{label}</label> : null}
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          className="form-control"
          value={this.state[name]}
          onChange={this.updateSearchState}
        />
      </div>
    )
  }
}
