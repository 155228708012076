import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { MenuItem, Dropdown } from 'react-bootstrap'

import { smoothlyMenu } from '../00-helpers/Helpers'
import { logoutUser } from '../../actions/userActions'
import { updateOrderDashboardFilters } from '../../actions/orderActions'
import NotificationItem from '../02-molecules/Notification'

import { wsEventsShop, closeWSSop } from '../../actions/wsActions'
import { saleOrderStatus } from '../../actions/saleActions'
import { capitalizeFirstLetter, hasPermission } from '../../utils'
import { permissionLevels } from '../../utils/variables'
import {
  getAdminCities,
  changeCurrentAdminCity
} from '../../actions/all/adminCitiesActions'

class TopHeader extends Component {
  static propTypes = {
    getCities: PropTypes.func.isRequired,
    shopStatus: PropTypes.func.isRequired,
    openShopSocket: PropTypes.func.isRequired,
    closeShopSocket: PropTypes.func.isRequired,
    shopNotifications: PropTypes.objectOf(PropTypes.any).isRequired,
    dashboard: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    update: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    adminCities: PropTypes.objectOf(PropTypes.any).isRequired,
    changeCity: PropTypes.func.isRequired
  }

  componentDidMount() {
    const { getCities } = this.props
    const adminId = localStorage.getItem('adminId')
    getCities(adminId)

    if (hasPermission('shop', permissionLevels.READ)) {
      const { shopStatus, openShopSocket } = this.props
      shopStatus()
      openShopSocket()
    }
  }

  componentWillUnmount() {
    if (hasPermission('shop', permissionLevels.READ)) {
      const { closeShopSocket } = this.props
      closeShopSocket('')
    }
  }

  getCreatedOrdersShop() {
    const { shopNotifications } = this.props
    if (shopNotifications.data.on_going_orders) {
      return shopNotifications.data.on_going_orders
    }
    return 0
  }

  getValueDelayed() {
    const {
      dashboard: { orderDelayed }
    } = this.props
    return orderDelayed
  }

  getValueDelayedShop() {
    const { shopNotifications } = this.props
    if (shopNotifications.data.total_delay_in_confirm) {
      return shopNotifications.data.total_delay_in_confirm
    }
    return 0
  }

  notificationClickHandlerShop = e => {
    e.preventDefault()
    const { history } = this.props
    history.replace('/merchants/sales')
  }

  notificationClickHandler = e => {
    e.preventDefault()
    const { update, history } = this.props
    update({
      delayed: true,
      group: 'ongoing'
    })
    history.replace('/dashboard')
  }

  toggleNavigation = e => {
    e.preventDefault()
    const body = document.querySelector('body')
    body.classList.toggle('mini-navbar')
    smoothlyMenu()
  }

  handleChangeCity = city => e => {
    e.preventDefault()
    const { adminCities, changeCity } = this.props
    if (adminCities.data.current_city_id !== city.city_id) {
      changeCity(adminCities.data.id, {
        current_city_id: city.city_id
      }).then(() => {
        window.location.reload()
      })
    }
  }

  getCurrentCityLabel = () => {
    const { adminCities } = this.props
    if (Object.keys(adminCities.data).length) {
      const [currentCity] = adminCities.data.cities.filter(
        city => city.city_id === adminCities.data.current_city_id
      )
      return capitalizeFirstLetter(currentCity.name)
    }
    return 'Ciudad'
  }

  render() {
    const { adminCities, logout } = this.props
    return (
      <div className="row border-bottom">
        <nav
          className="navbar navbar-static-top white-bg"
          style={{
            marginBottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-minimalize minimalize-styl-2 btn btn-primary "
              onClick={this.toggleNavigation}
            >
              <i className="fa fa-bars" />
            </button>
          </div>

          <ul className="nav navbar-top-links">
            {window.location.pathname.match(
              /(dashboard|orders|merchants|sales)/
            ) && (
              <NotificationItem
                onClick={this.notificationClickHandler}
                values={[this.getValueDelayed()]}
                message={['pedido(s) demorado(s)']}
              />
            )}
            <li className="dropdown" style={{ marginRight: 15 }}>
              <Dropdown id="dropdown-basic">
                <Dropdown.Toggle bsStyle="primary">
                  {this.getCurrentCityLabel()}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: 350,
                    overflow: 'auto'
                  }}
                >
                  {adminCities.data.cities &&
                    adminCities.data.cities.map((city, i) => (
                      <MenuItem
                        key={city.city_id}
                        eventKey={i}
                        active={
                          adminCities.data.current_city_id === city.city_id
                        }
                        onClick={this.handleChangeCity(city)}
                      >
                        {capitalizeFirstLetter(city.name)}
                      </MenuItem>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li>
              <button
                type="button"
                className="btn btn-default"
                onClick={logout}
              >
                <strong>
                  <i className="fa fa-sign-out" /> Salir
                </strong>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  shopNotifications: state.global.shopNotifications,
  dashboard: state.dashboard,
  adminCities: state.global.adminCities
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      shopStatus: saleOrderStatus,
      openShopSocket: wsEventsShop,
      closeShopSocket: closeWSSop,
      update: updateOrderDashboardFilters,
      logout: logoutUser,
      getCities: getAdminCities,
      changeCity: changeCurrentAdminCity
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopHeader))
