import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const FilterDateTime = ({
  updateCallback,
  value,
  name,
  label,
  options,
  spaced,
  customStyle
}) => (
  <div
    className="js-filter-child input-daterange"
    style={{ margin: spaced ? '15px 0' : '0', ...customStyle }}
  >
    {label ? <label>{label}</label> : null}
    <DatePicker
      selected={value[name] ? moment(value[name]) : null}
      onChange={date => updateCallback({ [name]: date.toISOString() })}
      onSelect={date => updateCallback({ [name]: date.toISOString() })}
      showTimeSelect
      timeFormat="HH:mm"
      dateFormat="LLL"
      timeCaption="time"
      className="form-control"
      isClearable
      minDate={moment().add(options.minDate || 0, 'days')}
      maxDate={options.maxDate ? moment().add(options.maxDate, 'days') : null}
      minTime={
        options.minTime
          ? moment()
              .hours(options.minTime[0])
              .minutes(options.minTime[1])
          : null
      }
      maxTime={
        options.minTime
          ? moment()
              .hours(options.maxTime[0])
              .minutes(options.maxTime[1])
          : null
      }
    />
  </div>
)

FilterDateTime.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  updateCallback: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired
}
