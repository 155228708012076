import {
  PURSECOURIER_LIST_FAILURE,
  PURSECOURIER_LIST_SUCCESS,
  PURSECOURIER_LIST_REQUEST,
  UPDATE_PURSECOURIER_FILTERS
} from '../../actions/purseCourierActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    errors: {},
    filters: {
      page: 1
    },
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case PURSECOURIER_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case PURSECOURIER_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case PURSECOURIER_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case UPDATE_PURSECOURIER_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    default:
      return state
  }
}
