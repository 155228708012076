import { CALL_API } from '../../middleware/api'

export const ADMIN_CITIES_REQUEST = 'ADMIN_CITIES_REQUEST'
export const ADMIN_CITIES_SUCCESS = 'ADMIN_CITIES_SUCCESS'
export const ADMIN_CITIES_FAILURE = 'ADMIN_CITIES_FAILURE'

export const CHANGE_ADMIN_CITY_REQUEST = 'CHANGE_ADMIN_CITY_REQUEST'
export const CHANGE_ADMIN_CITY_SUCCESS = 'CHANGE_ADMIN_CITY_SUCCESS'
export const CHANGE_ADMIN_CITY_FAILURE = 'CHANGE_ADMIN_CITY_FAILURE'

export function getAdminCities(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `admin_cities/${id}/`,
      authenticated: true,
      types: [ADMIN_CITIES_REQUEST, ADMIN_CITIES_SUCCESS, ADMIN_CITIES_FAILURE]
    }
  }
}

export function changeCurrentAdminCity(id, data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `admin_cities/${id}/`,
      authenticated: true,
      data,
      types: [
        CHANGE_ADMIN_CITY_REQUEST,
        CHANGE_ADMIN_CITY_SUCCESS,
        CHANGE_ADMIN_CITY_FAILURE
      ]
    }
  }
}
