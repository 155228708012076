import React, { Component } from 'react'
import Dropzone from 'react-dropzone'

class InputFileForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accepted: [],
      rejected: []
    }
  }

  render() {
    return (
      <section>
        <div className="dropzone">
          <Dropzone
            multiple={false}
            accept=".csv, .xlsx"
            style={{
              width: '100% important',
              padding: '10px',
              textAlign: 'center',
              border: '2px dashed'
            }}
            onDrop={(accepted, rejected) => {
              this.props.inputProps.onChange(accepted[0])
              this.setState({ accepted, rejected })
            }}
          >
            <p>Arrastre los archivos que desea cargar aquí.</p>
            <p>Solo *.csv and *.xlsx seran aceptados.</p>
          </Dropzone>
        </div>
        <aside>
          <h2 style={{ marginTop: '1em' }}>Archivos cargados</h2>
          <ul>
            {this.state.accepted.map(f => (
              <li key={f.name}>
                {f.name} - {f.size} bytes
              </li>
            ))}
          </ul>
        </aside>
      </section>
    )
  }
}

export default InputFileForm
