import React from 'react'
import { Link } from 'react-router-dom'

const GroupButton = ({
  redirectTo,
  btnLabel,
  handleSubmit,
  isdisabled,
  customClass,
  hideMessage,
  btnGroupClass,
  handleHide,
  isSubmitting,
  isEmpty
}) => (
  <div className={`form-group ${customClass}`}>
    <div className={`col-sm-3 pull-right u-align-flex ${btnGroupClass}`}>
      {redirectTo && (
        <Link className="btn btn-white" to={redirectTo}>
          Cancel
        </Link>
      )}
      {hideMessage && (
        <button onClick={handleHide} className="btn btn-white">
          Cancelar
        </button>
      )}
      <button
        disabled={isEmpty ? false : isdisabled}
        onClick={handleSubmit}
        className="btn btn-primary"
        type="submit"
      >
        {btnLabel}{' '}
        {isSubmitting && <i className="fa fa-circle-o-notch fa-spin" />}
      </button>
    </div>
  </div>
)

export default GroupButton
