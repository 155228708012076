import {
  ORDER_TYPES_REQUEST,
  ORDER_TYPES_SUCCESS,
  ORDER_TYPES_FAILURE,
  ORDER_TYPE_REQUEST,
  ORDER_TYPE_SUCCESS,
  ORDER_TYPE_FAILURE,
  ORDER_TYPE_DELETE_REQUEST,
  ORDER_TYPE_DELETE_SUCCESS,
  ORDER_TYPE_DELETE_FAILURE,
  UPDATE_ORDERTYPES_LIST_FILTERS,
  CLEAN_DATA
} from '../../actions/orderTypeActions'

const initialState = {
  isFetching: false,
  needRefresh: false,
  singleOrderType: {},
  orderTypesList: {
    results: []
  },
  data: [],
  filters: { page: 1 },
  errors: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_TYPE_DELETE_REQUEST:
    case ORDER_TYPE_REQUEST:
    case ORDER_TYPES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        filters: { page: 1 }
      })

    case ORDER_TYPES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data.results,
        orderTypesList: action.data
      })

    case ORDER_TYPE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        singleOrderType: action.data
      })

    case ORDER_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        deletedOrderType: true,
        needRefresh: true
      }

    case ORDER_TYPE_DELETE_FAILURE:
    case ORDER_TYPE_FAILURE:
    case ORDER_TYPES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_ORDERTYPES_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data
        },
        needRefresh: true
      }

    case CLEAN_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        data: [],
        singleOrderType: {},
        filters: { page: 1 }
      })

    default:
      return state
  }
}
