/* eslint import/prefer-default-export: 0 */
import React, { Component } from 'react'
import Multiselect from 'react-bootstrap-multiselect'
import 'react-bootstrap-multiselect/css/bootstrap-multiselect.css'

import { manageSelect } from '../../utils/index'

export class FilterMultiselect extends Component {
  state = {
    elements: []
  }

  componentDidMount() {
    const { elements: propsElements, filters, name } = this.props
    const { elements: stateElements } = this.state
    const searchedItems = filters[name] || []
    const selectedElementsProps = propsElements.map(item => {
      if (searchedItems.includes(item.value)) {
        return { ...item, selected: !item.selected }
      }
      return item
    })

    if (selectedElementsProps.length && !stateElements.length) {
      this.setState({ elements: selectedElementsProps })
    }
  }

  handleOnChange = e => {
    const { updateCallback, name, options } = this.props
    const { elements } = this.state
    const select = manageSelect(e[0].value, elements, options.multiple)
    const selected = select.reduce(
      (acc, curr) => (curr.selected ? [...acc, curr.value] : acc),
      []
    )
    updateCallback({
      [name]: selected,
      page: 1
    })
    this.setState({ elements: select })
  }

  render() {
    const { name, options, label, title, customStyle } = this.props
    const { elements } = this.state
    return (
      <div className="js-filter-child" style={customStyle}>
        {label ? (
          <label style={{ whiteSpace: 'nowrap', display: 'block' }}>
            {label}
          </label>
        ) : null}
        <Multiselect
          onChange={this.handleOnChange}
          name={name}
          data={elements}
          multiple={options.multiple}
          nonSelectedText={title}
        />
      </div>
    )
  }
}
