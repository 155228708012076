import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const RESET_COUPON = 'RESET_COUPON'
export const UPDATE_COUPON_FILTERS = 'UPDATE_COUPON_FILTERS'
export const UPDATE_COUPONCLIENT_FILTERS = 'UPDATE_COUPONCLIENT_FILTERS'
export const UPDATE_COUPONREDEMPTION_FILTERS =
  'UPDATE_COUPONREDEMPTION_FILTERS'

const couponActions = {}

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  const arrType = arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    couponActions[type] = type
    return type
  })
  return arrType
}

export function couponList(params = {}, opt) {
  const { method, entity, action } = opt
  let url = 'coupons/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method,
      endpoint: url,
      authenticated: true,
      types: genTypeActions(entity, action)
    }
  }
}

export function couponReset() {
  return dispatch => {
    const action = {
      type: RESET_COUPON
    }
    dispatch(action)
  }
}

export function updateCouponFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COUPON_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function createCoupon(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `coupons/`,
      authenticated: true,
      data,
      types: genTypeActions('coupon', 'create')
    }
  }
}

export function editCoupon(data, id) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `coupons/${id}/`,
      authenticated: true,
      data,
      types: genTypeActions('coupon', 'update')
    }
  }
}

export function couponDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `coupons/${id}/`,
      authenticated: true,
      types: genTypeActions('coupon', 'detail')
    }
  }
}

export function clientCouponList(params = {}) {
  let url = 'clients/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: genTypeActions('coupon', 'history')
    }
  }
}

export function updateCouponHistoryFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COUPONCLIENT_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function couponDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `coupons/${id}/`,
      authenticated: true,
      types: genTypeActions('coupon', 'delete')
    }
  }
}

export function couponRedemption(params = {}) {
  let url = 'coupon_redemption/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: genTypeActions('coupon', 'redemption')
    }
  }
}

export function updateCouponRedemptionFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COUPONREDEMPTION_FILTERS,
      data
    }
    dispatch(action)
  }
}

genTypeActions('coupon', 'delete')
genTypeActions('coupon', 'list')
genTypeActions('coupon', 'create')
genTypeActions('coupon', 'update')
genTypeActions('coupon', 'history')
genTypeActions('coupon', 'redemption')
export default couponActions
