import purseCourierActions, {
  PURSECOURIERTRANSACTION_LIST_FAILURE,
  PURSECOURIERTRANSACTION_LIST_REQUEST,
  PURSECOURIERTRANSACTION_LIST_SUCCESS,
  UPDATE_PURSECOURIERTRANSACTION_FILTERS
} from '../../actions/purseCourierActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    errors: {},
    filters: {
      page: 1
    },
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case PURSECOURIERTRANSACTION_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case PURSECOURIERTRANSACTION_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case PURSECOURIERTRANSACTION_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case UPDATE_PURSECOURIERTRANSACTION_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    case purseCourierActions.TRANSACTIONTYPE_GET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        types: action.data
      })
    case purseCourierActions.TRANSACTIONTYPE_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        needRefresh: true
      })
    default:
      return state
  }
}
