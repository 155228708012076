import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import confirmMessageReducer from './all/confirmMessageReducer'
import selectAllReducer from './all/selectAllReducer'
import adminCities from './all/adminCitiesReducer'
import regionalizationReducer from './all/regionalizationReducer'

import shopNotificationsReducer from './globalReducer/shopNotifications'
import login from './login/loginReducer'
import dashboard from './dashboard/dashboardReducer'
import orderRouting from './orderRouting/orderRoutingReducer'
import routingConfiguration from './orderRouting/routingConfigurationReducer'
import kardexList from './storage/kardexListReducer'
import orderList from './order/orderListReducer'
import orderDetail from './order/orderDetailReducer'
import clientList from './client/clientListReducer'
import clientSearch from './client/clientSearchListReducer'
import admins from './admin/adminsReducer'
import myNetwork from './myNetwork/myNetworkReducer'
import clientDetail from './client/clientDetailReducer'
import clientVirtualPoints from './client/clientVirtualPointsReducer'
import courierList from './courier/courierListReducer'
import courierDetail from './courier/courierDetailReducer'
import ConfigPriceReducer from './configuration/ConfigPriceReducer'
import clientInvoicing from './client/clientInvoicingReducer'
import invoiceReducer from './invoice/invoiceReducer'
import invoiceDetailReducer from './invoice/invoiceDetailReducer'
import collectionReducer from './collection/collectionReducer'
import courierCollectionReducer from './courier/courierCollectionReducer'
import candidateListReducer from './candidate/candidateListReducer'
import discoverySourceReducer from './candidate/discoverySourceReducer'
import purseListReducer from './purse/purseListReducer'
import purseTypeReducer from './purse/purseTypeReducer'
import coverageReducer from './coverage/coverageReducer'
import orderTypesReducer from './orderTypes/orderTypesReducer'
import vehicleTypesReducer from './vehicleTypes/vehicleTypesReducer'
import courierReportReducer from './reports/courierReportReducer'
import pricePlansReducer from './pricePlans/pricePlansReducer'
import clientTypeReducer from './clientType/clientTypeReducer'
import clientVehicleReducer from './clientVehicle/clientVehicleReducer'
import couponReducer from './configuration/couponReducer'
import couponClientReducer from './configuration/couponClientReducer'
import couponRedemptionReducer from './configuration/couponRedemptionReducer'
import courierHistoryReducer from './courier/courierHistoryReducer'
import notificationReducer from './notification/NotificationReducer'
import changelogReducer from './notification/ChangelogReducer'
import courierPurseReducer from './reports/courierPurseReducer'
import courierPurseTransactionReducer from './reports/courierPurseTransactionReducer'
import courierPurseRequestReducer from './reports/courierPurseRequestReducer'
import courierPurseReportsReducer from './reports/courierPurseReportsReducer'
import merchantList from './merchant/merchantListReducer'
import paymentList from './payment/paymentListReducer'
import paymentDetail from './payment/paymentDetailReducer'
import paymentRetrieve from './payment/paymentRetrieveReducer'
import globalConfiguration from './configuration/globalConfigurationReducer'

import saleList from './sale/saleListReducer'
import merchantTypeList from './merchant/merchantTypeListReducer'
import packageTypesReducer from './packageTypes/packageTypesReducer'
import warrantyList from './courier/warrantyReducer'
import warrantyAssign from './courier/warrantyCourierAssignReducer'
import bonusList from './courier/bonusReducer'
import cardActivityList from './client/clientCardActivityReducer'

import courierReferral from './courier/courierReferralReducer'

export default combineReducers({
  login,
  dashboard,
  orderRouting,
  routingConfiguration,
  kardexList,
  orderList,
  orderDetail,
  clientList,
  clientSearch,
  admins,
  myNetwork,
  clientDetail,
  clientVirtualPoints,
  courierList,
  courierDetail,
  clientInvoicing,
  courierReferral,
  form: formReducer,
  message: confirmMessageReducer,
  selectAll: selectAllReducer,
  global: combineReducers({
    shopNotifications: shopNotificationsReducer,
    adminCities,
    regionalization: regionalizationReducer,
    orderTypes: orderTypesReducer,
    packageTypes: packageTypesReducer,
    vehicleTypes: vehicleTypesReducer
  }),
  notification: combineReducers({
    list: notificationReducer
  }),
  changelogReducer,
  courier: combineReducers({
    history: courierHistoryReducer
  }),
  clientType: combineReducers({
    clientTypeList: clientTypeReducer
  }),
  clientVehicle: combineReducers({
    clientVehicleList: clientVehicleReducer
  }),
  pricePlans: combineReducers({
    pricePlansList: pricePlansReducer
  }),
  reports: combineReducers({
    couriers: courierReportReducer,
    courier_purse: courierPurseReducer,
    courier_purse_request: courierPurseRequestReducer,
    courier_transaction: courierPurseTransactionReducer,
    courier_purse_reports: courierPurseReportsReducer
  }),
  coverage: combineReducers({
    coverageList: coverageReducer
  }),
  cardActivityList,
  purse: combineReducers({
    purseList: purseListReducer,
    purseType: purseTypeReducer
  }),
  candidates: combineReducers({
    candidateList: candidateListReducer
  }),
  discoverySourceReducer,
  collections: combineReducers({
    collectionList: collectionReducer,
    collection: courierCollectionReducer
  }),
  invoices: combineReducers({
    invoice: invoiceReducer,
    invoiceDetail: invoiceDetailReducer
  }),
  configuration: combineReducers({
    globalConfiguration,
    price: ConfigPriceReducer,
    coupon: couponReducer,
    coupon_client: couponClientReducer,
    coupon_redemption: couponRedemptionReducer
  }),
  merchant: combineReducers({
    merchantList
  }),
  merchanttype: combineReducers({
    merchantTypeList
  }),
  payment: combineReducers({
    paymentList,
    paymentDetail,
    paymentRetrieve
  }),
  sale: combineReducers({
    saleList
  }),
  warrantyList,
  warrantyAssign,
  bonusList
})
