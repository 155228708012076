import {
  MERCHANTTYPE_LIST_REQUEST,
  MERCHANTTYPE_LIST_SUCCESS,
  MERCHANTTYPE_LIST_FAILURE
} from '../../actions/merchantActions'

export default function reducer(
  state = {
    isFetching: false,
    data: false,
    errors: {}
  },
  action
) {
  switch (action.type) {
    case MERCHANTTYPE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case MERCHANTTYPE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case MERCHANTTYPE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    default:
      return state
  }
}
