import React from 'react'
import DatePicker from 'react-datepicker'

import { parseStateToValue, formatValueToState } from '../../utils/events'

const DateForm = ({ name, withTimePicker, value, onChange }) => {
  const toRepresentValue = value => {
    if (!withTimePicker) {
      return value.format('YYYY-MM-DD')
    }
    return formatValueToState(value)
  }

  return (
    <DatePicker
      name={name}
      className="form-control"
      dateFormat={withTimePicker ? 'LL' : 'DD/MM/YYYY'}
      showTimeSelect={withTimePicker}
      selected={parseStateToValue(value)}
      onChange={val => onChange(toRepresentValue(val))}
      placeholderText="Fecha"
      dropdownMode="select"
      showYearDropdown={true}
      showMonthDropdown={true}
    />
  )
}

const DatePickerSelector = field => {
  return (
    <div className="col-lg-12">
      <DateForm {...field} />
    </div>
  )
}

export default DatePickerSelector
