import { CALL_API } from '../middleware/api'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export function loginUser(creds) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'authenticate/jwt/',
      authenticated: false,
      data: creds,
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE]
    }
  }
}

export function logoutUser() {
  return dispatch => {
    dispatch({ type: LOGOUT_REQUEST })
    localStorage.removeItem('token')
    localStorage.removeItem('adminId')
    dispatch({ type: LOGOUT_SUCCESS })
  }
}
