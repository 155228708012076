import changelogActions, {
  UPDATE_CHANGELOG_FILTERS,
  RESET_CHANGELOG,
  SELECT_CHANGELOG,
  CHANGELOG_DELETE_SUCCESS,
  CHANGELOG_DETAIL_REQUEST,
  CHANGELOG_DETAIL_SUCCESS,
  CHANGELOG_DETAIL_FAILURE
} from '../../actions/webUpdatesActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    isDeleting: false,
    data: [],
    selected: {},
    dataDetail: {},
    changelogData: {},
    errors: [],
    filters: {
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case changelogActions.CHANGELOG_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case changelogActions.CHANGELOG_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case changelogActions.CHANGELOG_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case RESET_CHANGELOG:
      return Object.assign({}, state, { data: {}, dataDetail: {} })

    case CHANGELOG_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case CHANGELOG_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        dataDetail: action.data
      })

    case CHANGELOG_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case CHANGELOG_DELETE_SUCCESS:
      window.location = '/notifications/web-updates'
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case SELECT_CHANGELOG:
      return Object.assign({}, state, {
        selected: action.data
      })

    case UPDATE_CHANGELOG_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
