import {
  UPDATE_INVOICEDETAIL_FILTERS,
  INVOICE_DETAIL_REQUEST,
  INVOICE_DETAIL_SUCCESS,
  UPDATE_CREDIT_NOTE_STATUS_SUCCESS,
  UPDATE_CREDIT_NOTE_STATUS_FAILURE
} from '../../actions/InvoiceActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    data: {},
    errors: {},
    filters: {
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case INVOICE_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case INVOICE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case UPDATE_INVOICEDETAIL_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    case UPDATE_CREDIT_NOTE_STATUS_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          credit_notes: state.data.credit_notes.map(c => {
            if (c.id === parseInt(action.data.credi_note_id, 10)) {
              return {
                ...c,
                cdr_status: action.data.cdr_status,
                status: action.data.cdr_status === '0' ? 'Confirmed' : 'Failed'
              }
            }
            return c
          })
        }
      })

    case UPDATE_CREDIT_NOTE_STATUS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false
      })
    default:
      return state
  }
}
