import {
  CLIENT_SUMMARY_REQUEST,
  CLIENT_SUMMARY_SUCCESS,
  CLIENT_SUMMARY_FAILURE
} from '../../actions/clientActions'
import { MARK_SELECTED_ORDER } from '../../actions/orderRoutingActions'

const initialState = {
  isFetchingOrders: false,
  orders: [],
  selectedOrder: {},
  error: {}
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case CLIENT_SUMMARY_REQUEST:
      return Object.assign({}, state, {
        isFetchingOrders: true
      })

    case CLIENT_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        isFetchingOrders: false,
        orders: action.data,
        error: {}
      })

    case CLIENT_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        isFetchingOrders: false,
        error: action.errors
      })

    case MARK_SELECTED_ORDER:
      return Object.assign({}, state, {
        selectedOrder: action.data
      })

    default:
      return state
  }
}

export default reducer
