import React from 'react'
import Lottie from 'react-lottie'

import Icon from '../01-atoms/Icon'
import loader from '../../assets/icons/loader.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const IconCard = ({ type, color = undefined, style = {}, size }) => {
  if (type === 'loader') {
    return (
      <div style={{ height: size, width: size, margin: '10px', ...style }}>
        <Lottie options={defaultOptions} />
      </div>
    )
  }
  return (
    <div style={{ margin: '10px', ...style }}>
      <Icon type={type} size={size} fill={color} />
    </div>
  )
}

export default React.memo(IconCard)
