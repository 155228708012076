import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const DISCOVERY_SOURCE_RESET = 'DISCOVERY_SOURCE_RESET'
export const UPDATE_DISCOVERY_SOURCE_FILTERS =
  'UPDATE_DISCOVERY_SOURCE_FILTERS'
export const SELECT_DISCOVERY_SOURCE = 'SELECT_DISCOVERY_SOURCE'

export const DISCOVERY_SOURCE_DETAIL_REQUEST =
  'DISCOVERY_SOURCE_DETAIL_REQUEST'
export const DISCOVERY_SOURCE_DETAIL_SUCCESS =
  'DISCOVERY_SOURCE_DETAIL_SUCCESS'
export const DISCOVERY_SOURCE_DETAIL_FAILURE =
  'DISCOVERY_SOURCE_DETAIL_FAILURE'

export const DISCOVERY_SOURCE_DELETE_REQUEST =
  'DISCOVERY_SOURCE_DELETE_REQUEST'
export const DISCOVERY_SOURCE_DELETE_SUCCESS =
  'DISCOVERY_SOURCE_DELETE_SUCCESS'
export const DISCOVERY_SOURCE_DELETE_FAILURE =
  'DISCOVERY_SOURCE_DELETE_FAILURE'

const sourceActions = {}

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  const arrType = arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    sourceActions[type] = type
    return type
  })
  return arrType
}

export function discoverySourceList(params = {}, opt) {
  const { method, entity, action } = opt
  let url = 'courier_discovery_sources/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method,
      localAPI: false,
      endpoint: url,
      authenticated: true,
      types: genTypeActions(entity, action)
    }
  }
}

export function discoverySourceReset() {
  return dispatch => {
    const action = {
      type: DISCOVERY_SOURCE_RESET
    }
    dispatch(action)
  }
}

export function updateDiscoverySourceFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_DISCOVERY_SOURCE_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function selectDiscoverySource(obj) {
  return dispatch => {
    const action = {
      type: SELECT_DISCOVERY_SOURCE,
      data: obj
    }
    dispatch(action)
  }
}

export function discoverySourceDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `courier_discovery_sources/${id}/`,
      authenticated: true,
      types: [
        DISCOVERY_SOURCE_DETAIL_REQUEST,
        DISCOVERY_SOURCE_DETAIL_SUCCESS,
        DISCOVERY_SOURCE_DETAIL_FAILURE
      ]
    }
  }
}

export function discoverySourceDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `courier_discovery_sources/${id}/`,
      authenticated: true,
      localAPI: false,
      types: [
        DISCOVERY_SOURCE_DELETE_REQUEST,
        DISCOVERY_SOURCE_DELETE_SUCCESS,
        DISCOVERY_SOURCE_DELETE_FAILURE
      ]
    }
  }
}

genTypeActions('discoverySource', 'list')
export default sourceActions
