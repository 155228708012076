import {
  RESET_COVERAGE,
  COVERAGE_LIST_REQUEST,
  COVERAGE_LIST_SUCCESS,
  COVERAGE_LIST_FAILURE,
  COVERAGE_DELETE_REQUEST,
  COVERAGE_DELETE_SUCCESS,
  COVERAGE_DELETE_FAILURE,
  UPDATE_COVERAGE_FILTERS,
  DISTRICTSCOVERAGE_LIST_REQUEST,
  DISTRICTSCOVERAGE_LIST_SUCCESS,
  DISTRICTSCOVERAGE_LIST_FAILURE
} from '../../actions/coveragesActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    errors: {},
    needRefresh: false,
    filters: {
      search: '',
      page: 1
    },
    districts: []
  },
  action
) {
  switch (action.type) {
    case COVERAGE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case COVERAGE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case COVERAGE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case COVERAGE_DELETE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case COVERAGE_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: true
      })

    case COVERAGE_DELETE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    case RESET_COVERAGE:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false,
        data: [],
        errors: {}
      })

    case UPDATE_COVERAGE_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    case DISTRICTSCOVERAGE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case DISTRICTSCOVERAGE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        districts: action.data
      })

    case DISTRICTSCOVERAGE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    default:
      return state
  }
}
