import purseCourierActions, {
  UPDATE_PURSECOURIERREQUEST_FILTERS
} from '../../actions/purseCourierActions'

export default function reducer(
  state = {
    isFetching: false,
    data: false,
    errors: {},
    filters: {
      page: 1,
      courier: '',
      created_at: null
    },
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case purseCourierActions.PAYMENTREQUEST_GET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case purseCourierActions.PAYMENTREQUEST_GET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    // case COURIER_REPORT_FAILURE:
    //   return Object.assign({}, state, {
    //     isFetching: false,
    //     errors: action.data
    //   })
    case UPDATE_PURSECOURIERREQUEST_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    default:
      return state
  }
}
