import notificationActions, {
  UPDATE_NOTIFICATION_FILTERS,
  RESET_NOTIFICATION
} from '../../actions/notificationActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    data: [],
    dataDetail: {},
    notificationData: {},
    errors: [],
    filters: {
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case notificationActions.NOTIFICATION_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case notificationActions.NOTIFICATION_LIST_FAILED:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case notificationActions.NOTIFICATION_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case RESET_NOTIFICATION:
      return Object.assign({}, state, { data: {}, dataDetail: {} })

    case notificationActions.NOTIFICATION_CREATE_SUCCESS:
      window.location = `/notifications/`
      return Object.assign({}, state, {
        isFetching: false,
        notificationData: action.data
      })

    case UPDATE_NOTIFICATION_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
