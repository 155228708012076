import React from 'react'
import { Field } from 'redux-form'

const SelectForm = ({
  customName,
  options,
  firstOptionLabel,
  customClass,
  normalizeFn,
  formatFn,
  parseFn,
  ...rest
}) => {
  const { disabled } = rest

  return (
    <Field
      parse={parseFn}
      normalize={normalizeFn}
      component="select"
      name={customName}
      format={formatFn}
      className={`form-control ${customClass}`}
      disabled={disabled}
    >
      <option value="">{firstOptionLabel}</option>
      {options &&
        options.map((obj, i) => (
          <option value={obj.id || obj.value} key={i}>
            {obj.name}
          </option>
        ))}
    </Field>
  )
}

export default SelectForm
