import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

/**
 * Show or hide an action body.
 *
 * @param {Object} props
 * @param {string} props.type
 * @param {string} props.icon
 * @param {string} props.label
 * @param {bool} props.isDisabled
 * @callback props.onClick
 */
const ActionButton = ({ type, icon, onClick, label, isDisabled }) => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={label}>{label}</Tooltip>}
  >
    <button
      disabled={isDisabled}
      className={`btn btn-${type} dim pull-right`}
      type="button"
      onClick={onClick}
    >
      <i className={`fa fa-${icon}`} />
    </button>
  </OverlayTrigger>
)

ActionButton.defaultProps = {
  isDisabled: false
}

ActionButton.propTypes = {
  /** This property defines the color. */
  type: PropTypes.string.isRequired,

  /** An icon for the action. */
  icon: PropTypes.string.isRequired,

  /** Function to manage onClick event. */
  onClick: PropTypes.func.isRequired,

  /** Label for the tooltip */
  label: PropTypes.string.isRequired,

  /** Wheather to disable the button or not */
  isDisabled: PropTypes.bool
}

export default ActionButton
