import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS
} from '../../actions/userActions'

export default function reducer(
  state = {
    isAuthenticated: !!localStorage.getItem('token'),
    isFetching: false,
    data: {},
    errors: {},
    groups: false,
    permissions: false
  },
  action
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        creds: action.creds
      })

    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.data.auth_token)
      localStorage.setItem('adminId', action.data.admin.id)
      localStorage.setItem(
        'permissions',
        JSON.stringify(action.data.admin.permissions)
      )
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        data: action.data.admin
      })

    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errors: action.errors
      })

    case LOGOUT_SUCCESS:
      localStorage.clear()
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false
      })

    default:
      return state
  }
}
