import { CALL_API } from '../middleware/api'

// Warranty Couriers List
export const WARRANTY_COURIERLIST_REQUEST = 'WARRANTY_COURIERLIST_REQUEST'
export const WARRANTY_COURIERLIST_SUCCESS = 'WARRANTY_COURIERLIST_SUCCESS'
export const WARRANTY_COURIERLIST_FAILURE = 'WARRANTY_COURIERLIST_FAILURE'

// Warranty Couriers Assign
export const WARRANTY_COURIERSASSIGN_REQUEST =
  'WARRANTY_COURIERSASSIGN_REQUEST'
export const WARRANTY_COURIERSASSIGN_SUCCESS =
  'WARRANTY_COURIERSASSIGN_SUCCESS'
export const WARRANTY_COURIERSASSIGN_FAILURE =
  'WARRANTY_COURIERSASSIGN_FAILURE'

export const WARRANTY_COURIERS_DELETE_REQUEST =
  'WARRANTY_COURIERS_DELETE_REQUEST'
export const WARRANTY_COURIERS_DELETE_SUCCESS =
  'WARRANTY_COURIERS_DELETE_SUCCESS'
export const WARRANTY_COURIERS_DELETE_FAILURE =
  'WARRANTY_COURIERS_DELETE_FAILURE'

export const UPDATE_WARRANTYASSIGN_FILTERS = 'UPDATE_WARRANTYASSIGN_FILTERS'
export const SET_WARRANTYASSIGN_ACTION_ACTIVE =
  'SET_WARRANTYASSIGN_ACTION_ACTIVE'

export function warrantyCouriersList(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `warranties/${id}/couriers/`,
      authenticated: true,
      types: [
        WARRANTY_COURIERLIST_REQUEST,
        WARRANTY_COURIERLIST_SUCCESS,
        WARRANTY_COURIERLIST_FAILURE
      ]
    }
  }
}

export function warrantyCouriersAssign(data, id) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `warranties/${id}/couriers/`,
      authenticated: true,
      data,
      types: [
        WARRANTY_COURIERSASSIGN_REQUEST,
        WARRANTY_COURIERSASSIGN_SUCCESS,
        WARRANTY_COURIERSASSIGN_FAILURE
      ]
    }
  }
}

export function warrantyCouriersDelete(data, id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `warranties/${id}/couriers/`,
      authenticated: true,
      data,
      types: [
        WARRANTY_COURIERS_DELETE_REQUEST,
        WARRANTY_COURIERS_DELETE_SUCCESS,
        WARRANTY_COURIERS_DELETE_FAILURE
      ]
    }
  }
}

export function updateWarrantyAssignFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_WARRANTYASSIGN_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function setWarrantyAssignActionActive(actionName) {
  return dispatch => {
    const action = {
      type: SET_WARRANTYASSIGN_ACTION_ACTIVE,
      data: actionName
    }
    dispatch(action)
  }
}
