import React from 'react'

import { FilterSearch as search } from '../02-molecules/FilterSearch'
import FilterSearchBy from '../02-molecules/FilterSearchBy'
import { FilterSearchByDropDown as searchByDropDown } from '../02-molecules/FilterSearchByDropDown'
import { FilterInput as input } from '../02-molecules/FilterInput'
import { FilterMultiselect as select } from '../02-molecules/FilterMultiselect'
import { FilterSwitch as toggle } from '../02-molecules/FilterSwitch'
import { FilterDatapicker as date } from '../02-molecules/FilterDatapicker'
import { FilterDateTime as dateTime } from '../02-molecules/FilterDateTime'
import { FilterForm } from '../04-layouts/FilterForm'

const filtersGroup = {
  search,
  input,
  select,
  toggle,
  date,
  dateTime,
  searchByDropDown
}

const filterFormWrapper = (el, props) => {
  const { value, expand, filters, updateCallback, spaced } = props
  return (
    <FilterForm
      key={el.name}
      name={el.name}
      value={value}
      options={el.options}
      size={el.size}
      expand={expand}
      spaced={spaced}
      label={el.label}
      propertyName={el.propertyName}
      filters={filters}
      updateCallback={updateCallback}
      placeholder={el.placeholder}
      elements={el.elements}
      title={el.title}
      component={filtersGroup[el.type]}
      customStyle={el.customStyle}
    />
  )
}

const FilterSwitchWrapper = ({ toggleFilters, ...props }) => (
  <div>
    {toggleFilters.length ? (
      <label className="filter-label-element">Filtrar por </label>
    ) : (
      ''
    )}
    <div className="checkbox-filter-list">
      {toggleFilters.map(el => filterFormWrapper(el, props))}
    </div>
  </div>
)

export const FilterList = props => {
  const { expand, config, className, style } = props
  const searchByFilters = config.filter(el => el.type === 'searchBy')
  const toggleFilters = config.filter(el => el.type === 'toggle')
  const genericFilters = config.filter(
    el => el.type !== 'toggle' && el.type !== 'searchBy'
  )
  return (
    <div className={expand ? '' : 'u-center-filters'}>
      {searchByFilters.length ? (
        <FilterSearchBy {...props} filterByList={searchByFilters} />
      ) : (
        ''
      )}
      <div className={className || 'u-flex-center'} style={style}>
        {genericFilters.map(el => filterFormWrapper(el, props))}
        {toggleFilters.length ? (
          <FilterSwitchWrapper {...props} toggleFilters={toggleFilters} />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
