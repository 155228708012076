import { CALL_API } from '../middleware/api'

export const PACKAGE_TYPES_REQUEST = 'PACKAGE_TYPES_REQUEST'
export const PACKAGE_TYPES_SUCCESS = 'PACKAGE_TYPES_SUCCESS'
export const PACKAGE_TYPES_FAILURE = 'PACKAGE_TYPES_FAILURE'

export const ONE_PACKAGE_TYPE_REQUEST = 'ONE_PACKAGE_TYPE_REQUEST'
export const ONE_PACKAGE_TYPE_SUCCESS = 'ONE_PACKAGE_TYPE_SUCCESS'
export const ONE_PACKAGE_TYPE_FAILURE = 'ONE_PACKAGE_TYPE_FAILURE'

export const RESET_PACKAGE_TYPES = 'RESET_PACKAGE_TYPES'

export function getPackageTypes() {
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: 'package_types/',
      authenticated: true,
      types: [
        PACKAGE_TYPES_REQUEST,
        PACKAGE_TYPES_SUCCESS,
        PACKAGE_TYPES_FAILURE
      ]
    }
  }
}

export function getOnePackageType(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `package_types/${id}/`,
      authenticated: true,
      types: [
        ONE_PACKAGE_TYPE_REQUEST,
        ONE_PACKAGE_TYPE_SUCCESS,
        ONE_PACKAGE_TYPE_FAILURE
      ]
    }
  }
}

export function resetPackageTypes() {
  return dispatch => {
    const action = {
      type: RESET_PACKAGE_TYPES
    }
    dispatch(action)
  }
}
