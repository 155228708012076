import React from 'react'
import _ from 'lodash'
import { MenuItem, Dropdown } from 'react-bootstrap'

export const FilterSearchByDropDown = ({
  updateCallback,
  filters,
  elements
}) => {
  const [searchInput, setSearchInput] = React.useState({})
  const [searchCriteria, setSearchCriteria] = React.useState(elements[0])

  const updateSearchState = e => {
    setSearchInput({ [e.target.name]: e.target.value })
    if (e.target.value === '') {
      updateCallback({ [e.target.name]: '', page: 1 })
    }
  }

  const updateSearchCriteria = React.useCallback(
    e => {
      const filter = elements.find(input => input.name === e.target.name)
      const isSearchCriteriaUpdated = filter
        ? searchCriteria.name !== filter.name
        : false
      const currentFilterValue = searchInput[searchCriteria.name]

      if (filter) {
        setSearchInput(inputObject => ({
          [filter.name]: inputObject[searchCriteria.name]
        }))
        setSearchCriteria(filter)
      }
      if (currentFilterValue && isSearchCriteriaUpdated) {
        updateCallback({
          ...filters,
          [searchCriteria.name]: undefined,
          [filter.name]: currentFilterValue
        })
      }
    },
    [searchInput, searchCriteria]
  )

  const sendState = e => {
    if (e.key === 'Enter' && searchInput[e.target.name]) {
      return updateCallback({
        page: 1,
        [e.target.name]: searchInput[e.target.name]
      })
    }
    return false
  }

  const setInitialState = () => {
    let initialStateIndex = 0
    elements.forEach((item, i) => {
      const prevFilterValue = filters[item.name]
      if (_.has(filters, item.name) && Boolean(prevFilterValue)) {
        initialStateIndex = i
        setSearchInput({ [item.name]: prevFilterValue })
        setSearchCriteria(item)
      }
    })
    return initialStateIndex
  }

  React.useEffect(() => {
    setInitialState()
  }, [])

  return (
    <div>
      <label className="filter-label-element">Buscar por</label>
      <div
        className="js-filter-child"
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          maxWidth: '365px'
        }}
      >
        <Dropdown id="dropdown">
          <Dropdown.Toggle style={{ minWidth: 100 }}>
            {searchCriteria.label}
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              maxHeight: 350,
              overflow: 'auto'
            }}
          >
            {elements &&
              elements.map((criteria, i) => (
                <MenuItem
                  key={criteria.name}
                  name={criteria.name}
                  eventKey={i}
                  onClick={updateSearchCriteria}
                >
                  {criteria.label}
                </MenuItem>
              ))}
          </Dropdown.Menu>
        </Dropdown>

        <input
          type="text"
          name={searchCriteria.name}
          placeholder={searchCriteria.placeholder}
          className="form-control"
          style={{ maxWidth: 370 }}
          onChange={updateSearchState}
          onKeyPress={sendState}
          value={searchInput[searchCriteria.name]}
        />
      </div>
    </div>
  )
}
