import warrantyActions, {
  UPDATE_WARRANTY_FILTERS,
  RESET_WARRANTY
} from '../../actions/warrantyActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    data: [],
    dataDetail: {},
    warrantyData: {},
    errors: [],
    filters: {
      search: '',
      page: 1,
      is_active: true
    }
  },
  action
) {
  switch (action.type) {
    case warrantyActions.WARRANTY_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case warrantyActions.WARRANTY_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case warrantyActions.WARRANTY_LIST_FAILED:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case warrantyActions.WARRANTY_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: true
      })

    case RESET_WARRANTY:
      return Object.assign({}, state, {
        data: {},
        dataDetail: {}
      })

    case warrantyActions.WARRANTY_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case warrantyActions.WARRANTY_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        dataDetail: action.data
      })

    case warrantyActions.WARRANTY_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case warrantyActions.WARRANTY_CREATE_SUCCESS:
      window.location = `/couriers/warranties/${action.data.id}`
      return Object.assign({}, state, {
        isFetching: false,
        warrantyData: action.data
      })

    case warrantyActions.WARRANTY_UPDATE_SUCCESS:
      window.location = `/couriers/warranties`
      return Object.assign({}, state, {
        isFetching: false,
        warrantyData: {}
      })

    case UPDATE_WARRANTY_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
