import _ from 'lodash'
import moment from 'moment'

import orderActions, {
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_FAILURE,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_NOTE_REQUEST,
  ORDER_UPDATE_NOTE_FAILURE,
  ORDER_UPDATE_NOTE_SUCCESS,
  ORDER_LIST_DASHBOARD_SUCCESS,
  UPDATE_ORDER_DASHBOARD_FILTERS,
  CLEAN_ORDER_FILTER
} from '../../actions/orderActions'
import {
  ORDER_MAP_CLICK,
  ORDER_DETAIL_CLOSE,
  COURIER_MAP_CLICK,
  DELETE_COURIER_ACTIVE,
  ORDER_CLICK_SUCCESS,
  TRACKER_REQUEST,
  TRACKER_SUCCESS,
  TRACKER_FAILURE,
  USE_TRACKER_REQUEST,
  USE_TRACKER_SUCCESS,
  USE_TRACKER_FAILURE
} from '../../actions/dashboardActions'
import { ADMIN_CITIES_SUCCESS } from '../../actions/all/adminCitiesActions'
import {
  COURIER_LIST_SUCCESS,
  COURIER_LIST_REQUEST,
  UPDATE_COURIER_FILTERS
} from '../../actions/courierActions'
import { WS_MESSAGE } from '../../actions/wsActions'
import { defaultMapCenter } from '../../utils/variables'
import { getPosition } from '../../utils'

function getCoordinates(data) {
  const { current_city_id, cities } = data
  const [cuttentCity] = cities.filter(city => city.city_id === current_city_id)
  return cuttentCity.latlon
}

export default function reducer(
  state = {
    needRefresh: false,
    orderIsUpdating: false,
    orderData: {},
    data: {},
    orderErrors: false,
    courierIsFetching: false,
    courierData: [],
    courierErrors: false,
    mapCenter: null,
    zoom: 13,
    orderDetailVisibility: false,
    orderMapVisibility: false,
    orderActive: false,
    notes: {},
    orderDelayed: 0,
    orderNotification: false,
    courierNotification: false,
    courierDetailVisibility: false,
    courierMapVisibility: true,
    courierActive: '',
    orderFilters: {
      page: 1,
      group: 'pending',
      created_at_0: moment()
        .startOf('day')
        .toISOString(),
      created_at_1: null,
      courier: ''
    },
    useTracker: false
  },
  action
) {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return Object.assign({}, state, {
        orderIsUpdating: true
      })

    case ORDER_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        orderIsUpdating: false,
        orderActive: action.data,
        orderDataUpdate: Object.assign(state.orderData, {
          results: _.unionBy([action.data], state.orderData.results, 'id')
        })
      })

    case ORDER_UPDATE_FAILURE:
      return Object.assign({}, state, {
        orderIsUpdating: false,
        orderErrors: action.data
      })

    // ws Events
    case WS_MESSAGE:
      //TODO: new logic here

      if (action.data.stream === 'eta') {
        const { payload } = action.data
      }
      if (
        action.data.stream === 'order' ||
        action.data.stream === 'destination'
      ) {
        const { payload } = action.data
        if (
          payload.data.delayed_orders &&
          payload.data.delayed_orders.total_delayed_orders
        ) {
          return Object.assign({}, state, {
            orderDelayed: payload.data.delayed_orders.total_delayed_orders
          })
        }

        return Object.assign({}, state, {
          orderNotification: true
        })
      }
      if (action.data.stream === 'courier' && state.courierData.length) {
        const { payload } = action.data
        const index = state.courierData.findIndex(x => x.id === payload.id)
        const courier = state.courierData.find(x => x.id === payload.id)
        if (courier) {
          const { position, ...rest } = payload.data
          const updatedCourier = {
            ...courier,
            ...rest,
            id: payload.id
          }
          const courierData = [
            ...state.courierData.slice(0, index),
            updatedCourier,
            ...state.courierData.slice(index + 1)
          ]

          if (!updatedCourier.is_online) {
            courierData.splice(index, 1)
          }
          return Object.assign({}, state, {
            courierData
          })
        }
        return Object.assign({}, state, {
          courierData: [
            ...state.courierData,
            {
              id: payload.id,
              busy_level: 'free',
              ...payload.data
            }
          ]
        })
      }
      return state

    // UI actions
    case ORDER_CLICK_SUCCESS:
      return Object.assign({}, state, {
        orderDetailVisibility: true,
        orderActive: action.data
      })

    case ORDER_MAP_CLICK:
      return Object.assign({}, state, {
        orderDetailVisibility: false,
        orderMapVisibility: true,
        orderActive: action.order,
        mapCenter: getPosition(action.order.destinations[0].latlon)
      })

    case COURIER_MAP_CLICK:
      return Object.assign({}, state, {
        courierDetailVisibility: false,
        courierMapVisibility: true,
        courierActive: action.courier,
        zoom: 17,
        orderFilters: Object.assign({}, state.orderFilters, {
          courier: action.courier.id,
          group: 'ongoing'
        }),
        mapCenter: action.courier.position.coordinates
      })

    case ORDER_DETAIL_CLOSE:
      return Object.assign({}, state, {
        orderDetailVisibility: false,
        orderMapVisibility: false,
        orderActive: undefined
      })

    case ORDER_LIST_DASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        orderData: action.data,
        needRefresh: false,
        orderNotification: false
      })

    case ORDER_UPDATE_NOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case ORDER_UPDATE_NOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        notes: action.data
      })

    case ORDER_UPDATE_NOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_ORDER_DASHBOARD_FILTERS:
      return Object.assign({}, state, {
        orderFilters: Object.assign({}, state.orderFilters, action.data),
        needRefresh: true
      })

    case COURIER_LIST_REQUEST:
      return Object.assign({}, state, {
        state: Array.isArray(state.data) ? state.data : [],
        courierIsFetching: true,
        needRefresh: false,
        courierNotification: false
      })

    case COURIER_LIST_SUCCESS:
      return Object.assign({}, state, {
        data: false,
        courierData: action.data,
        courierNotification: false
      })

    case UPDATE_COURIER_FILTERS:
      return Object.assign({}, state, {
        data: false,
        courierData: []
      })

    case CLEAN_ORDER_FILTER:
      return Object.assign({}, state, {
        orderData: {},
        orderFilters: {
          page: 1,
          group: 'pending',
          created_at_0: moment()
            .startOf('day')
            .toISOString(),
          created_at_1: null
        }
      })

    case orderActions.ORDER_SETOPTIONS_SUCCESS:
      return Object.assign({}, state, {
        needRefresh: true
      })

    case DELETE_COURIER_ACTIVE:
      return Object.assign({}, state, {
        courierActive: false,
        orderFilters: Object.assign({}, state.orderFilters, {
          group: 'pending',
          courier: ''
        }),
        mapCenter:
          JSON.parse(localStorage.getItem('adminCity')) || defaultMapCenter,
        zoom: 13
      })

    case TRACKER_REQUEST:
      return Object.assign({}, state, {
        isPolling: true
      })
    case TRACKER_SUCCESS:
      //ToDO: Verificar y modificar el cambio de las coordenadas
      const updateCouriersData = state.courierData.map(el => {
        const courier = action.data.find(curr => curr.id === el.id)
        if (courier && courier.latitude && courier.longitude) {
          return {
            ...el,
            position: {
              coordinates: [courier.latitude, courier.longitude]
            }
          }
        }
        return el
      })
      return {
        ...state,
        isPolling: false,
        courierData: updateCouriersData
      }
    case TRACKER_FAILURE:
      return Object.assign({}, state, {
        isPolling: false
      })

    case USE_TRACKER_REQUEST:
      return Object.assign({}, state, {
        useTracker: false
      })
    case USE_TRACKER_SUCCESS:
      return Object.assign({}, state, {
        useTracker: action.data.new_tracking_service
      })
    case USE_TRACKER_FAILURE:
      return Object.assign({}, state, {
        useTracker: false
      })

    case ADMIN_CITIES_SUCCESS:
      return Object.assign({}, state, {
        mapCenter: getPosition(getCoordinates(action.data)) || defaultMapCenter
      })

    default:
      return state
  }
}
