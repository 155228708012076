import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import logo from '../../assets/img/logo.png'
import { hasPermission } from '../../utils'
import { permissionLevels } from '../../utils/variables'

class Navigation extends Component {
  constructor(props) {
    super(props)

    // TODO(Arturo) Maybe put in a middleware
    try {
      const jwtData = jwtDecode(localStorage.getItem('token'))
      this.state = {
        name: jwtData.email
      }
    } catch (e) {}
    // ?Se dehabilita modulo de 'Nueva Orden'
    // const { token } = localStorage
    // const pathbase = process.env.REACT_APP_CLIENT_WEB_URL
    // const url = `${pathbase}/login/?token=${token}`

    // this.state = {
    //   url
    // }
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  secondLevelActive(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? 'nav nav-second-level collapse in'
      : 'nav nav-second-level collapse'
  }

  render() {
    // ?Se dehabilita modulo de 'Nueva Orden'
    // const { url } = this.state

    return (
      <nav className="navbar-default navbar-static-side">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element">
              <span />
              <button
                data-toggle="dropdown"
                className="dropdown-toggle"
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: '#dfe4ed'
                }}
              >
                <span className="clear">
                  <span className="block m-t-xs">
                    <strong className="font-bold">{this.state.name}</strong>
                  </span>
                  <span className="text-muted text-xs block">
                    Administrador
                  </span>
                </span>
              </button>
            </div>
            <div className="logo-element">
              <img src={logo} alt="" style={{ width: '30px' }} />
            </div>
          </li>
          {hasPermission('dashboard', permissionLevels.READ) && (
            <li className={this.activeRoute('/dashboard')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="dashboard">Dashboard</Tooltip>}
              >
                <Link to="/dashboard">
                  <i className="fa fa-th-large" />{' '}
                  <span className="nav-label">Dashboard</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
          {hasPermission('administrator', permissionLevels.READ) && (
            <li className={this.activeRoute('/admins')}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="administrators">Administradores</Tooltip>
                }
              >
                <Link to="/admins">
                  <i className="fa fa-user-circle" />{' '}
                  <span className="nav-label">Administradores</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
          {hasPermission('organizationcontract', permissionLevels.READ) && (
            <li className={this.activeRoute('/my-network')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="networks">Mis Redes</Tooltip>}
              >
                <Link to="/my-network">
                  <i className="fa fa-globe" />{' '}
                  <span className="nav-label">Mis Redes</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
          {hasPermission('orderbatch', permissionLevels.READ) && (
            <li className={this.activeRoute('/groups')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="routeOrders">Agrupar pedidos</Tooltip>}
              >
                <Link to="/groups">
                  <i className="fa fa-location-arrow" />{' '}
                  <span className="nav-label">Agrupar pedidos</span>
                </Link>
              </OverlayTrigger>
              <ul className="nav nav-second-level collapse in">
                <li className={this.activeRoute('/groups')}>
                  <Link to="/groups">Listar grupos</Link>
                </li>
                <li className={this.activeRoute('/groups/massive')}>
                  <Link to="/groups/massive">Carga Masiva</Link>
                </li>
              </ul>
            </li>
          )}
          {hasPermission('order', permissionLevels.READ) && (
            <>
              <li className={this.activeRoute('/orders')}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="orders">Pedidos</Tooltip>}
                >
                  <Link to="/orders">
                    <i className="fa fa-shopping-cart" />{' '}
                    <span className="nav-label">Pedidos</span>
                  </Link>
                </OverlayTrigger>
              </li>
              {/* Implementamos el nuevo modulo dentro de 'Ordenes' para proceder con permisos */}
              <li className={this.activeRoute('/inventory')}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="inventory">Almacenamiento</Tooltip>}
                >
                  <Link to="/inventory">
                    <i className="fa fa-cubes" />
                    <span className="nav-label">Almacenamiento</span>
                  </Link>
                </OverlayTrigger>
                <ul className="nav nav-second-level collapse in">
                  <li className={this.activeRoute('/inventory')}>
                    <Link to="/inventory">Listado</Link>
                  </li>
                  <li className={this.activeRoute('/inventory/items')}>
                    <Link to="/inventory/items">Artículos</Link>
                  </li>
                </ul>
              </li>
            </>
          )}
          {hasPermission('pushnotification', permissionLevels.READ) && (
            <li className={this.activeRoute('/notifications')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="notifications">Novedades</Tooltip>}
              >
                <Link to="/notifications">
                  <i className="fa fa-send" />{' '}
                  <span className="nav-label">Novedades</span>
                </Link>
              </OverlayTrigger>
              <ul className="nav nav-second-level collapse in">
                <li className={this.activeRoute('/notifications')}>
                  <Link to="/notifications">Notificaciones</Link>
                </li>
                <li className={this.activeRoute('/notifications/web-updates')}>
                  <Link to="/notifications/web-updates">
                    Actualizaciones web
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {hasPermission('client', permissionLevels.READ) && (
            <li className={this.activeRoute('/clients')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="clients">Clientes</Tooltip>}
              >
                <Link to="/clients">
                  <i className="fa fa-users" />{' '}
                  <span className="nav-label">Clientes</span>
                </Link>
              </OverlayTrigger>
              <ul className="nav nav-second-level collapse in">
                <li className={this.activeRoute('/clients')}>
                  <Link to="/clients">Listado</Link>
                </li>
                <li className={this.activeRoute('/clients/members')}>
                  <Link to="/clients/members">Miembros</Link>
                </li>
              </ul>
            </li>
          )}
          {hasPermission('shop', permissionLevels.READ) && (
            <li className={this.activeRoute('/merchants')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="merchants">Tiendas</Tooltip>}
              >
                <Link to="/merchants">
                  <i className="fa fa-shopping-basket" />
                  <span className="nav-label">Tiendas</span>
                </Link>
              </OverlayTrigger>
              <ul className="nav nav-second-level collapse in">
                <li className={this.activeRoute('/merchants/sales')}>
                  <Link to="/merchants">Listado</Link>
                </li>
                <li className={this.activeRoute('/merchants/sales')}>
                  <Link to="/merchants/sales">Pedidos</Link>
                </li>
                <li className={this.activeRoute('/merchants/payments')}>
                  <Link to="/merchants/payments">Pagos</Link>
                </li>
              </ul>
            </li>
          )}
          {hasPermission('courier', permissionLevels.READ) && (
            <li className={this.activeRoute('/couriers')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="couriers">Mensajeros</Tooltip>}
              >
                <Link to="/couriers">
                  <i className="fa fa-motorcycle" />{' '}
                  <span className="nav-label">Mensajeros</span>
                </Link>
              </OverlayTrigger>
              <ul className="nav nav-second-level collapse in">
                <li className={this.activeRoute('/couriers')}>
                  <Link to="/couriers">Listado</Link>
                </li>
                <li>
                  <Link to="/couriers/warranties">Garantías</Link>
                </li>
                <li>
                  <Link to="/couriers/bonuses">Bonos</Link>
                </li>
                {hasPermission('couriertransport', permissionLevels.READ) && (
                  <li className={this.activeRoute('/couriers/reports')}>
                    <Link to="/couriers/reports">Reporte de mensajeros</Link>
                  </li>
                )}
                {hasPermission(
                  'courierpursetransaction',
                  permissionLevels.READ
                ) && (
                  <li className={this.activeRoute('/couriers/purse')}>
                    <Link to="/couriers/purse">Monedero de mensajeros</Link>
                  </li>
                )}
              </ul>
            </li>
          )}
          {hasPermission('invoice', permissionLevels.READ) && (
            <li className={this.activeRoute('/invoices')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="invoices">Facturas</Tooltip>}
              >
                <Link to="/invoices">
                  <i className="fa fa-money" />{' '}
                  <span className="nav-label">Facturas</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
          {hasPermission('courierapplication', permissionLevels.READ) && (
            <li className={this.activeRoute('/candidates')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="candidates">Postulantes</Tooltip>}
              >
                <Link to="/candidates">
                  <i className="fa fa-address-book-o" />{' '}
                  <span className="nav-label">Postulantes</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
          {hasPermission('vehicletype', permissionLevels.READ) && (
            <li className={this.activeRoute('/vehicle-types')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="vehicle-types">Vehículos</Tooltip>}
              >
                <Link to="/vehicle-types">
                  <i className="fa fa-car" />{' '}
                  <span className="nav-label">Tipos de vehículos</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
          {hasPermission('ordertype', permissionLevels.READ) && (
            <li className={this.activeRoute('/order-types')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="order-types">Tipos de orden</Tooltip>}
              >
                <Link to="/order-types">
                  <i className="fa fa-bolt" />{' '}
                  <span className="nav-label">Tipos de orden</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
          {hasPermission('purse', permissionLevels.READ) && (
            <li className={this.activeRoute('/purses')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="purse">Monedero</Tooltip>}
              >
                <Link to="/purses">
                  <i className="fa fa-bitcoin" />{' '}
                  <span className="nav-label">Monedero</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
          {/* {hasPermission('order', permissionLevels.READ) && (
          // ?Se dehabilita modulo de 'Nueva Orden'
            <li className={this.activeRoute('/create')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="newOrder">Nueva Orden</Tooltip>}
              >
                <a href={`${url}`} target="blank">
                  <i className="fa fa-cart-plus" />{' '}
                  <span className="nav-label">Nueva Orden</span>
                </a>
              </OverlayTrigger>
            </li>
          )} */}
          {(hasPermission('formula', permissionLevels.READ) ||
            hasPermission('coupon', permissionLevels.READ)) && (
            <li className={this.activeRoute('/configuration')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="configuration">Configuración</Tooltip>}
              >
                <Link to="/configuration/prices">
                  <i className="fa fa-flask" />
                  <span className="nav-label">Configuración</span>
                </Link>
              </OverlayTrigger>
              <ul className="nav nav-second-level collapse in">
                {hasPermission('formula', permissionLevels.READ) && (
                  <li className={this.activeRoute('/configuration/prices')}>
                    <Link to="/configuration/prices">Precios</Link>
                  </li>
                )}
                {hasPermission('coupon', permissionLevels.READ) && (
                  <li className={this.activeRoute('/configuration/coupons')}>
                    <Link to="/configuration/coupons">Cupones</Link>
                  </li>
                )}
                {hasPermission('priceplan', permissionLevels.READ) && (
                  <li
                    className={this.activeRoute('/configuration/price-plan')}
                  >
                    <Link to="/configuration/price-plan">Plan de Precios</Link>
                  </li>
                )}
              </ul>
            </li>
          )}
          {hasPermission('districtcoverage', permissionLevels.READ) && (
            <li className={this.activeRoute('/coverages')}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="coverages">Coberturas</Tooltip>}
              >
                <Link to="/coverages">
                  <i className="fa fa-map" />{' '}
                  <span className="nav-label">Coberturas</span>
                </Link>
              </OverlayTrigger>
            </li>
          )}
        </ul>
      </nav>
    )
  }
}

export default Navigation
