export const SELECT_ALL = 'SELECT_ALL'
export const CLEAN_SELECT_ALL = 'CLEAN_SELECT_ALL'
export const SELECT_ELEMENT = 'SELECT_ELEMENT'
export const CLEAN_PER_PAGE = 'CLEAN_PER_PAGE'
export const SELECT_ONE_ELEMENT = 'SELECT_ONE_ELEMENT'

export function selectAll(msg, data, page) {
  return dispatch => {
    const action = {
      type: SELECT_ALL,
      payload: msg,
      data,
      page
    }
    dispatch(action)
  }
}

export function cleanSelectAll(msg) {
  return dispatch => {
    const action = {
      type: CLEAN_SELECT_ALL,
      payload: msg
    }
    dispatch(action)
  }
}

export function selectOnePerOne(element, msg, page) {
  return dispatch => {
    const action = {
      type: SELECT_ELEMENT,
      data: element,
      payload: msg,
      page
    }
    dispatch(action)
  }
}

export function cleanPerPage(msg, page) {
  return dispatch => {
    const action = {
      type: CLEAN_PER_PAGE,
      payload: msg,
      page
    }
    dispatch(action)
  }
}

export function selectOnlyOne(element, msg) {
  return dispatch => {
    const action = {
      type: SELECT_ONE_ELEMENT,
      data: element,
      payload: msg
    }
    dispatch(action)
  }
}
