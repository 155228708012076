import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const SpanTip = ({ text }) => (
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={text}>{text}</Tooltip>}
  >
    <span className="client-table-group overflow-text">{text}</span>
  </OverlayTrigger>
)
