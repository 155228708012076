import {
  SELECT_COURIER,
  COURIER_DELETE_SUCCESS,
  UPDATE_COURIER_FILTERS,
  COURIER_LIST_REQUEST,
  COURIER_DEACTIVATE_SUCCESS,
  COURIER_LIST_SUCCESS,
  COURIER_LIST_FAILURE,
  COURIER_GET_VEHICLES_REQUEST,
  COURIER_GET_VEHICLES_SUCCESS,
  COURIER_GET_VEHICLES_FAILURE,
  COURIER_CHANGE_ACTIVE_STATE_SUCCESS
} from '../../actions/courierActions'

const toggleCourierActive = (couriers, action) => {
  const toggle = couriers.map(courier => {
    if (courier.id !== action.extra.id[0]) {
      return courier
    }
    return {
      ...courier,
      is_active: action.data.is_active,
      is_online: !action.data.is_active
        ? action.data.is_active
        : courier.is_online
    }
  })
  return toggle
}

const updateCourierDataList = (state, action) => {
  const { results } = state.data
  return Object.assign({}, state.data, {
    results: toggleCourierActive(results, action)
  })
}

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    isUpdating: false,
    data: {},
    selectedCouriers: [],
    errors: {},
    selected: {},
    courierListData: false,
    filters: {
      page: 1,
      search: '',
      // is_active: true,
      // is_busy:false,
      // is_online:false,
      only_receivable: false
    },
    activeAction: null,
    needRefresh: false,
    message: false
  },
  action
) {
  switch (action.type) {
    case COURIER_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        data: {}
      })

    case COURIER_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case COURIER_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_COURIER_FILTERS:
      // state.data = Array.isArray(state.data) ? {} : state.data
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
        data: {}
      })

    case COURIER_DELETE_SUCCESS:
      window.location = '/couriers/'
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })
    case COURIER_DEACTIVATE_SUCCESS:
      return Object.assign({}, state, {
        needRefresh: true
      })
    case SELECT_COURIER:
      return Object.assign({}, state, {
        selected: action.data
      })

    case COURIER_GET_VEHICLES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case COURIER_GET_VEHICLES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        selectedCouriers: action.data
      })
    case COURIER_GET_VEHICLES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })
    case COURIER_CHANGE_ACTIVE_STATE_SUCCESS:
      return Object.assign({}, state, {
        data: updateCourierDataList(state, action)
      })
    default:
      return state
  }
}
