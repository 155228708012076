const CONFIG = [
  {
    title: 'Precio',
    value: 'price',
    elements: [
      {
        label: 'Precio base',
        value: 'price'
      },
      {
        label: 'Tiempo de espera',
        value: 'waiting_time_price'
      },
      {
        label: 'Precio por cancelación',
        value: 'cancelation_price'
      },
      {
        label: 'Retorno agregado',
        value: 'return_price'
      },
      {
        label: 'Modificación / Descuento',
        value: 'price_modification',
        isInput: true
      },
      {
        label: 'Comentario',
        value: 'price_modification_comment',
        placeholder: 'Ingresar comentario',
        isInput: true
      },
      {
        label: 'Precio total',
        value: 'total_price',
        isInput: true
      }
    ]
  },
  {
    title: 'Costo',
    value: 'cost',
    elements: [
      {
        label: 'Costo base',
        value: 'cost'
      },
      {
        label: 'Tiempo de espera',
        value: 'waiting_time_cost'
      },
      {
        label: 'Costo por cancelación',
        value: 'cancelation_cost'
      },
      {
        label: 'Retorno agregado',
        value: 'return_cost'
      },
      {
        label: 'Modificación / Descuento',
        value: 'cost_modification',
        isInput: true
      },
      {
        label: 'Comentario',
        value: 'cost_modification_comment',
        placeholder: 'Ingresar comentario',
        isInput: true
      },
      {
        label: 'Costo total',
        value: 'total_cost',
        isInput: true
      }
    ]
  }
]

export default CONFIG
