import { CALL_API } from '../middleware/api'

// CLIENT_VEHICLE GENERAL INFORMATION
export const CLIENT_VEHICLE_LIST_REQUEST = 'CLIENT_VEHICLE_LIST_REQUEST'
export const CLIENT_VEHICLE_LIST_SUCCESS = 'CLIENT_VEHICLE_LIST_SUCCESS'
export const CLIENT_VEHICLE_LIST_FAILURE = 'CLIENT_VEHICLE_LIST_FAILURE'

export function clientVehicleList() {
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: 'api/admin/vehicle_types/',
      authenticated: true,
      isPublic: true,
      types: [
        CLIENT_VEHICLE_LIST_REQUEST,
        CLIENT_VEHICLE_LIST_SUCCESS,
        CLIENT_VEHICLE_LIST_FAILURE
      ]
    }
  }
}
