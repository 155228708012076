export const WS_CONNECT = 'WS_CONNECT'
export const WS_CONNECT_SUCCESS = 'WS_CONNECT_SUCCESS'
export const WS_CONNECT_FAILURE = 'WS_CONNECT_FAILURE'
export const WS_MESSAGE = 'WS_MESSAGE'

export const WS_CONNECT_SHOP_REQUEST = 'WS_CONNECT_SHOP_REQUEST'
export const WS_CONNECT_SHOP_SUCCESS = 'WS_CONNECT_SHOP_SUCCESS'
export const WS_CONNECT_SHOP_FAILURE = 'WS_CONNECT_SHOP_FAILURE'
export const WS_MESSAGE_SHOP = 'WS_MESSAGE_SHOP'

let ws
export function wsEvents() {
  return dispatch => {
    dispatch({
      type: WS_CONNECT
    })

    ws = new WebSocket(
      `${
        process.env.REACT_APP_BASE_WS_URL
      }/ws/admin/?token=${localStorage.getItem('token')}`
    )

    ws.onopen = () => {
      dispatch({
        type: WS_CONNECT_SUCCESS
      })
    }

    ws.onmessage = e => {
      dispatch({
        type: WS_MESSAGE,
        data: JSON.parse(e.data)
      })
    }
  }
}

export function closeWS(type) {
  return dispatch => {
    dispatch({
      type: `WS_CLOSE`,
      entity: type
    })
    ws.close()
  }
}

let wsShop
export function wsEventsShop() {
  return dispatch => {
    dispatch({
      type: WS_CONNECT_SHOP_REQUEST
    })

    wsShop = new WebSocket(
      `${
        process.env.REACT_APP_BASE_WS_SHOP_URL
      }/stream/?token=${localStorage.getItem('token')}`
    )

    wsShop.onopen = () => {
      dispatch({
        type: WS_CONNECT_SHOP_SUCCESS
      })
    }

    wsShop.onmessage = e => {
      dispatch({
        type: WS_MESSAGE_SHOP,
        data: JSON.parse(e.data)
      })
    }
  }
}

export function closeWSSop(type) {
  return dispatch => {
    dispatch({
      type: `WS_CLOSE_SHOP`,
      entity: type
    })
    wsShop.close()
  }
}
