import React from 'react'

const Toast = ({ message, type, onClose }) => {
  const toastClassName = `toast ${type}`
  const toastStyles = {
    paddingTop: '7px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    background:
      type === 'error'
        ? 'rgba(255, 107, 107, 0.5)'
        : type === 'success'
        ? 'rgba(76, 206, 146, 0.5)'
        : '',
    color: '#fff'
  }
  const buttonStyles = {
    color: '#fff',
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  }

  return (
    <div className={toastClassName} style={toastStyles}>
      <p>{message}</p>
      <button className="close-button" style={buttonStyles} onClick={onClose}>
        X
      </button>
    </div>
  )
}

export default Toast
