import orderActions, {
  UPDATE_COURIERHISTORY_FILTERS,
  RESET_COURIER_HISTORY
} from '../../actions/orderActions'
import { COURIER_HISTORYDETAIL_SUCCESS } from '../../actions/courierActions'

export default function reducer(
  state = {
    data: {},
    errors: {},
    courierListData: false,
    filters: {
      page: 1,
      group: 'finished',
      end_date_1: null,
      end_date_0: null
    },
    needRefresh: false,
    selected: [],
    courier: {}
  },
  action
) {
  switch (action.type) {
    case orderActions.ORDER_COURIERHISTORY_REQUEST:
      state.data = {}
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case orderActions.ORDER_COURIERHISTORY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case orderActions.ORDER_COURIERHISTORY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_COURIERHISTORY_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    case RESET_COURIER_HISTORY:
      return Object.assign({}, state, {
        filters: {
          page: 1,
          group: 'finished',
          end_date_1: null,
          end_date_0: null
        }
      })
    case COURIER_HISTORYDETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        courier: action.data
      })

    default:
      return state
  }
}
