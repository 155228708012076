import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const ORDER_TYPES_REQUEST = 'ORDER_TYPES_REQUEST'
export const ORDER_TYPES_SUCCESS = 'ORDER_TYPES_SUCCESS'
export const ORDER_TYPES_FAILURE = 'ORDER_TYPES_FAILURE'

export const ORDER_TYPE_REQUEST = 'ORDER_TYPE_REQUEST'
export const ORDER_TYPE_SUCCESS = 'ORDER_TYPE_SUCCESS'
export const ORDER_TYPE_FAILURE = 'ORDER_TYPE_FAILURE'

export const ORDER_TYPE_DELETE_REQUEST = 'ORDER_TYPE_DELETE_REQUEST'
export const ORDER_TYPE_DELETE_SUCCESS = 'ORDER_TYPE_DELETE_SUCCESS'
export const ORDER_TYPE_DELETE_FAILURE = 'ORDER_TYPE_DELETE_FAILURE'

export const UPDATE_ORDERTYPES_LIST_FILTERS = 'UPDATE_ORDERTYPES_LIST_FILTERS'
export const CLEAN_DATA = 'CLEAN_DATA'

export function getOrderTypes(params = {}) {
  let url = 'order_types/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [ORDER_TYPES_REQUEST, ORDER_TYPES_SUCCESS, ORDER_TYPES_FAILURE]
    }
  }
}

export function getOrderTypeById(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: `order_types/${id}/`,
      authenticated: true,
      types: [ORDER_TYPE_REQUEST, ORDER_TYPE_SUCCESS, ORDER_TYPE_FAILURE]
    }
  }
}

export function deleteOrderType(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `order_types/${id}/`,
      authenticated: true,
      types: [
        ORDER_TYPE_DELETE_REQUEST,
        ORDER_TYPE_DELETE_SUCCESS,
        ORDER_TYPE_DELETE_FAILURE
      ]
    }
  }
}

export function updateListFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_ORDERTYPES_LIST_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function resetOrderTypes() {
  return dispatch => {
    const action = {
      type: CLEAN_DATA
    }
    dispatch(action)
  }
}
