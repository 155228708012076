import _ from 'lodash'
import {
  SELECT_ONE_ELEMENT,
  SELECT_ALL,
  CLEAN_SELECT_ALL,
  SELECT_ELEMENT,
  CLEAN_PER_PAGE
} from '../../actions/all/selectAllActions'

import * as orderActions from '../../actions/orderActions'
import * as orderRoutingActions from '../../actions/orderRoutingActions'

const elementsPerPage = (page, groupPages) => {
  const isSelected = groupPages.includes(page)
  if (isSelected) {
    _.remove(groupPages, n => n === page)
  } else {
    groupPages.push(page)
  }
  return page ? groupPages : []
}

const selectElements = (data, page, array, action) => {
  const pageValid = array.filter(el => el.page === page)
  let newArray = [...array]
  if (!pageValid.length) {
    newArray = [...newArray, { page, data }]
  } else {
    newArray = newArray.map(el => {
      const element = { ...el }
      if (element.page === page) {
        if (action === 'CLEAN_PAGE') {
          element.data = []
        } else if (action === 'SELECT_PAGE') {
          element.data = _.unionBy(data, element.data, 'id')
        } else {
          element.data = _.xorBy(data, element.data, 'id')
        }
      }
      return element
    })
  }
  return newArray
}

export default function reducer(
  state = {
    selected: [],
    type: '',
    pages: [],
    element: {}
  },
  action
) {
  switch (action.type) {
    case SELECT_ALL:
      return Object.assign({}, state, {
        selected: selectElements(
          action.data,
          action.page,
          state.selected,
          'SELECT_PAGE'
        ),
        type: action.payload,
        pages: elementsPerPage(action.page, state.pages)
      })
    case CLEAN_SELECT_ALL:
    case orderRoutingActions.GROUP_ORDERS_RELEASE_SUCCESS:
    case orderRoutingActions.ROUTE_ASSIGNATION_SUCCESS:
    case orderActions.ORDER_BATCH_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        selected: [],
        type: '',
        pages: [],
        element: {}
      })
    case CLEAN_PER_PAGE:
      return Object.assign({}, state, {
        selected: selectElements(_, action.page, state.selected, 'CLEAN_PAGE'),
        type: action.payload,
        pages: elementsPerPage(action.page, state.pages)
      })
    case SELECT_ELEMENT:
      return Object.assign({}, state, {
        selected: selectElements([action.data], action.page, state.selected)
      })
    case SELECT_ONE_ELEMENT:
      return Object.assign({}, state, {
        element: action.data
      })
    default:
      return state
  }
}
