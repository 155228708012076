import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'

import SelectForm from './SelectForm'
import DateInputForm from './DateForm'
import DatePickerForm from './DatePickerForm'
import MultiSelectForm from './MultiSelectForm'
import TimeInputForm from './TimeForm'
import InputCheckbox from '../02-molecules/InputCheckbox'
import FileInputForm from '../02-molecules/InputFileForm'
import InputFileForm from '../03-components/InputFileForm'
import InputFileImage from '../03-components/InputFileImage'
import InputJSONFileForm from '../03-components/InputJSONFileForm'
import TimeOnlyInputForm from './TimeOnlyForm'
import DaySelector from './DateSelector'
import DatePickerSelector from './DatePickerSelector'

const validationClass = (touched, error) => {
  if (touched)
    if (error) return 'has-error'
    else return 'has-success'
  return ''
}

const showErrorMessages = (touched, error) => {
  let finalError = []
  if (touched && error) {
    finalError = [error]
  }

  return finalError.map((el, index) => (
    <span key={index} className="control-label">
      {el}
    </span>
  ))
}

const chooseTypeInput = (
  type,
  input,
  label,
  placeholder,
  options,
  rest,
  minDate,
  maxDate
) => {
  switch (type) {
    case 'image':
      return <img width="100" src={`${input.value}`} alt="" />
    case 'select':
      return (
        <SelectForm
          options={options}
          customName={input.name}
          {...rest}
          firstOptionLabel="Seleccione una opcion"
        />
      )
    case 'multi_select':
      return <MultiSelectForm options={options} {...input} {...rest} />
    case 'timepicker':
      return <TimeInputForm options={options} {...input} {...rest} />
    case 'datepicker':
      return <DateInputForm placeholder={placeholder} {...input} {...rest} />
    case 'dateminpicker':
      return (
        <DatePickerForm
          placeholder={placeholder}
          {...input}
          {...rest}
          {...minDate}
          {...maxDate}
        />
      )
    case 'datepickerselector':
      return <DatePickerSelector {...input} {...rest} />
    case 'dayselector':
      return <DaySelector options={options} {...input} {...rest} />
    case 'timeonlypicker':
      return <TimeOnlyInputForm options={options} {...input} {...rest} />
    case 'datetimepicker':
      return <DateInputForm {...input} withTimePicker />
    case 'textarea':
      return <textarea className="form-control" {...input} />
    case 'photo':
      return <InputFileImage inputProps={input} image={input.value} />
    case 'file':
      return <InputFileForm inputProps={input} />
    case 'excel_file':
      return <FileInputForm inputProps={input} />
    case 'file_json':
      return <InputJSONFileForm inputProps={input} />
    case 'typeahead':
      return (
        <Typeahead
          options={options || []}
          placeholder={placeholder}
          ignoreDiacritics
          {...input}
          {...rest}
        />
      )
    case 'checkbox':
      return <InputCheckbox {...input} {...rest} />
    default:
      return (
        <input
          className="form-control"
          {...input}
          placeholder={placeholder || label}
          type={type}
          {...rest}
        />
      )
  }
}

const InputForm = ({
  input,
  label,
  type,
  onlyInput,
  noLabel,
  placeholder,
  handleKeyPress,
  options,
  defaultChecked,
  meta: { touched, error },
  selectedDays,
  ...rest
}) => {
  const isCreateUrl = !!window.location.pathname.match(/create/gi)

  return (
    <div className={`form-group col-lg-12 ${validationClass(touched, error)}`}>
      {onlyInput ? (
        <div>
          <input
            defaultChecked={defaultChecked}
            onKeyPress={handleKeyPress}
            className="form-control"
            {...input}
            placeholder={label}
            type={type}
          />
          {touched && error && <span className="control-label">{error}</span>}
        </div>
      ) : (
        <div style={{ display: 'inline-block', width: '100%' }}>
          {noLabel ? null : (
            <label className="col-sm-2 control-label">
              {isCreateUrl && type === 'image' ? '' : label}
            </label>
          )}
          <div className={noLabel ? '' : 'col-sm-10'}>
            {chooseTypeInput(
              type,
              input,
              label,
              placeholder,
              options,
              rest,
              selectedDays
            )}
            {showErrorMessages(touched, error, input)}
          </div>
        </div>
      )}
    </div>
  )
}
export default InputForm
