import {
  AVAILABLE_COUNTRIES_REQUEST,
  AVAILABLE_COUNTRIES_SUCCESS,
  AVAILABLE_COUNTRIES_FAILURE
} from '../../actions/all/regionalizationActions'

const initialState = {
  isFetching: false,
  countries: [],
  errors: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AVAILABLE_COUNTRIES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case AVAILABLE_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        countries: action.data.results.map(country => ({
          id: country.country_id,
          name: country.name,
          cities: country.cities.map(city => ({
            id: city.city_id,
            name: city.city_name,
            districts: city.city_districts.map(district => ({
              name: district
            }))
          }))
        }))
      })
    case AVAILABLE_COUNTRIES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    default:
      return state
  }
}
