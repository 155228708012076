import React from 'react'

/**
 * Site footer.
 */
const Footer = () => (
  <div className="footer">
    <div>
      <strong>Urbaner</strong> &copy; {new Date().getFullYear()}
    </div>
  </div>
)

export default Footer
