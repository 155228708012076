import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import 'animate.css/animate.min.css'
import './assets/styles/style.css'
import './assets/styles/custom.css'

import Raven from 'raven-js'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './store'
import LoginContainer from './containers/LoginContainer'
import MainContainer from './containers/MainContainer'

if (process.env.NODE_ENV === 'production') {
  Raven.config(process.env.REACT_APP_RAVENJS_URL).install()
}

const ProtectedRouter = () => {
  const isAuthenticated = Boolean(localStorage.getItem('token'))
  return isAuthenticated ? (
    <Route path="/" component={MainContainer} />
  ) : (
    <Redirect to="/login" />
  )
}

ReactDOM.render(
  <React.Suspense fallback={<div />}>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" component={LoginContainer} />
          <ProtectedRouter />
        </Switch>
      </Router>
    </Provider>
  </React.Suspense>,
  document.getElementById('root')
)

// registerServiceWorker()
