import React, { Component } from 'react'

import { dateFormat } from '../../../../utils/formats'

let notes = []
export default class OrderNote extends Component {
  constructor(props) {
    super(props)
    this.state = { notes: [], content: '' }
    this.addNote = this.addNote.bind(this)
    this.handleNoteChange = this.handleNoteChange.bind(this)
  }

  componentDidMount() {
    notes = this.props.orderNotes || []
    this.setState({ notes })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newNotes !== this.props.newNotes) {
      notes.unshift(nextProps.newNotes)
      this.setState({ notes })
    }
    if (nextProps.orderNotes !== this.props.orderNotes) {
      notes = nextProps.orderNotes
      this.setState({ notes })
    }
  }

  handleNoteChange(event) {
    this.setState({ content: event.target.value })
  }

  addNote(e) {
    const data = { content: this.state.content }
    this.props.orderNoteUpdate(this.props.orderId, data)
    this.setState({ content: '' })
    this.refs.note.value = ''
  }

  render() {
    return (
      <div className="panel-body">
        <div className="input-group">
          <input
            type="text"
            ref="note"
            defaultValue={this.state.content}
            onChange={this.handleNoteChange}
            placeholder="Escribe una nota"
            className="form-control"
          />
          <span className="input-group-btn">
            <button
              type="button"
              onClick={this.addNote}
              className="btn btn-primary"
            >
              Guardar
            </button>
          </span>
        </div>
        {this.state.notes.map((note, index, arr) => (
          <div key={index} className="stream-small">
            <span className="text-muted u-margin-r-5">
              {' '}
              {dateFormat(note.created_at)}
            </span>
            <label htmlFor="by"> by </label>
            <strong> {note.by.full_name} : </strong>
            <span> {note.content} </span>
          </div>
        ))}
      </div>
    )
  }
}
