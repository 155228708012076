import { delay } from 'redux-saga'
import { call, put, take, race } from 'redux-saga/effects'
import axios from 'axios'

import {
  TRACKER_SUCCESS,
  TRACKER_FAILURE,
  START_POLLING,
  STOP_POLLING
} from '../../actions/dashboardActions'

const TRACKER_API = process.env.REACT_APP_TRACKER_URL

//ToDO: Modificar la funcionalidad 'while' y optimizar el llamado de 'delay' con 'redux-saga'
function* trackerRequest(coordinates) {
  try {
    const { data } = yield call(() =>
      axios.post(`${TRACKER_API}couriers/search`, {
        latitude: coordinates[0],
        longitude: coordinates[1],
        radius: 1500
      })
    )
    yield put({ type: TRACKER_SUCCESS, data })
  } catch (error) {
    yield put({ type: TRACKER_FAILURE, message: error.message })
  }
}

function* pollTracker(coordinates) {
  while (true) {
    yield call(trackerRequest, coordinates)
    yield delay(5000)
  }
}

function* watchPollTrackerSaga() {
  while (true) {
    const { coordinates } = yield take(START_POLLING)
    yield race([call(pollTracker, coordinates), take(STOP_POLLING)])
  }
}

export function* dashboardSaga() {
  yield call(watchPollTrackerSaga)
}
