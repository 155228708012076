import React from 'react'
import { Link } from 'react-router-dom'

import ActionButton from '../../../01-atoms/ActionButton'
import Status from '../../../01-atoms/Status'

const OrderDetailHeader = ({
  progress,
  onOrderDetailClose,
  createdAt,
  programmedDate,
  formatProgrammedDate,
  status,
  statusLabel,
  currentDestinationLabel,
  totalWaitingTime,
  paymentStatus,
  retryPaymentHandler,
  isFetching,
  invoice,
  chargeFromPurseHandler,
  code,
  parentOrderCode,
  client,
  showClient,
  onOpenTabFive,
  description,
  vehicleType,
  courier,
  memo,
  courierPaymentTypeName,
  totalCost,
  courierIgvToPay,
  courierEarningsAfterIgv
}) => {
  const tab = '\u00A0'

  return (
    <React.Fragment>
      <div className="pull-right progress-in-top">
        <div className="u-flex-center">
          <small className="u-bold u-margin-r-5">
            {`${Math.round(progress * 100)}%`}
          </small>
          <div className="progress progress-striped active m-b-sm progress-bar-width">
            <div
              style={{ width: `${progress * 100}%` }}
              className="progress-bar"
            />
          </div>
        </div>
        <div className="tooltip-demo">
          <button
            type="button"
            className="btn btn-white btn-xs"
            onClick={onOrderDetailClose}
          >
            <i className="fa fa-times" />
          </button>
        </div>
      </div>
      <div className="small text-muted">
        <i className="fa fa-clock-o" /> {createdAt}
        {programmedDate && (
          <div>
            <i className="fa fa-calendar u-margin-r-5" />
            {formatProgrammedDate}
          </div>
        )}
      </div>
      <br />
      <div>
        <Status
          className="u-margin-r-10"
          value={{
            status,
            statusLabel
          }}
        />
        {status === 'in_progress' && (
          <Status className="u-margin-r-10" value={currentDestinationLabel} />
        )}
        <div style={{ display: 'inline-block' }}>{totalWaitingTime}</div>
        {paymentStatus === 'failed' && (
          <div>
            <ActionButton
              label="Reintentar pago"
              type="primary"
              icon="refresh"
              onClick={retryPaymentHandler}
              isDisabled={isFetching}
            />
          </div>
        )}
        {paymentStatus === 'failed' && invoice === null && (
          <div>
            <ActionButton
              label="Pagar con monedero"
              type="primary"
              icon="credit-card-alt"
              onClick={chargeFromPurseHandler}
              isDisabled={isFetching}
            />
          </div>
        )}
      </div>
      <h2>Pedido {code}</h2>
      {parentOrderCode && (
        <h3>
          Orden asociada:{' '}
          <span style={{ fontWeight: '100' }}>{parentOrderCode}</span>
        </h3>
      )}
      <div className="u-flex-center">
        <h3 className="client-detail-container">
          {client && (
            <span className="client-detail-header">{showClient}</span>
          )}
          {client && client.is_company && (
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              <i className="fa fa-user-circle u-margin-r-5" />
              {client.name}
            </span>
          )}

          {client && client.phone && (
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              <i className="fa fa-phone u-margin-r-5" />
              {client.phone}
            </span>
          )}
        </h3>
        <span
          style={{ position: 'initial', width: 'initial', maxWidth: '50%' }}
          className="u-ellipsis pull-right"
        >
          <a href="$" onClick={onOpenTabFive}>
            <i className="fa fa-comment u-margin-r-5" />
            {description}
          </a>
        </span>
      </div>

      <div className="u-flex-center">
        <div>
          <img
            className="u-margin-r-5"
            width="20"
            src={vehicleType ? vehicleType.image : ''}
            alt=""
          />
          {courier ? (
            <Link to={`/couriers/history/${courier.id}`}>
              {courier.name} {courier.last_name}
            </Link>
          ) : (
            '-'
          )}
          {courier && (
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              {' '}
              <br />
              <i className="fa fa-phone u-margin-r-5" />
              {courier.phone}
            </span>
          )}
        </div>
      </div>
      <br />
      <div className="u-flex-center">
        <div>
          {memo && (
            <span
              style={{
                position: 'initial'
              }}
              className="client-detail-element"
            >
              Cod. Referencia o memo:{' '}
              <a href="$" onClick={onOpenTabFive}>
                <i className="fa fa-file-text-o u-margin-r-5" />
                {memo}
              </a>
            </span>
          )}
        </div>
      </div>
      <br />
      <div>
        {courierPaymentTypeName === 'Invoices' && (
          <>
            <br />
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              Tipo de pago mensajero:
            </span>
            {tab}
            <span className="label label-info">FACTURA</span>
            <br />
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              Costo bruto:{' '}
            </span>
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              {totalCost}
            </span>
            {tab}
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              IGV:{' '}
            </span>
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              {courierIgvToPay}
            </span>
            {tab}
            <span
              className="client-detail-header"
              style={{ position: 'initial' }}
            >
              Costo neto:{' '}
            </span>
            <span
              className="client-detail-header"
              style={{ position: 'initial' }}
            >
              {courierEarningsAfterIgv}
            </span>
          </>
        )}
      </div>
      <div>
        {courierPaymentTypeName === 'Fees' && (
          <>
            <br />
            <span
              className="client-detail-element"
              style={{ position: 'initial' }}
            >
              Tipo de pago mensajero:
            </span>
            {tab}
            <span className="label label-info">Recibo Honorarios</span>
            <br />
            <span
              className="client-detail-header"
              style={{ position: 'initial' }}
            >
              Costo neto:{' '}
            </span>
            <span
              className="client-detail-header"
              style={{ position: 'initial' }}
            >
              {courierEarningsAfterIgv}
            </span>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default OrderDetailHeader
