import {
  RESET_COURIER,
  COURIER_CREATE_FAILURE,
  COURIER_CREATE_SUCCESS,
  COURIER_UPDATE_FAILURE,
  COURIER_UPDATE_REQUEST,
  COURIER_UPDATE_SUCCESS,
  COURIER_DETAIL_REQUEST,
  COURIER_DETAIL_SUCCESS,
  COURIER_DETAIL_FAILURE
} from '../../actions/courierActions'
// import { soatDateFormat } from '../../utils/formats'

const parseCourierData = data => {
  const newData = { ...data }
  delete newData.is_active
  /* if (newData.order_types) {
    const arrOrder = []
    newData.order_types.map(el => {
      arrOrder[el.id - 1] = el
      return el
    })
    newData.order_types = arrOrder
  } */

  // Agregar order_types_[id] para poder emparejarlo con el name del componente
  if (newData.order_types) {
    newData.order_types.map(el => {
      newData[`order_types_${el.id}`] = el
      return el
    })
  }
  // soatDateFormat(newData.vehicles, true)

  return newData
}

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: [],
    courierData: false,
    courierIsUpdating: false,
    courierErrors: false,
    courierIsCreating: false,
    courierErrordata: false
  },
  action
) {
  switch (action.type) {
    case COURIER_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case COURIER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: parseCourierData(action.data)
      })

    case COURIER_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case COURIER_UPDATE_REQUEST:
      return Object.assign({}, state, {
        courierIsUpdating: true
      })

    case COURIER_UPDATE_SUCCESS:
      window.location = '/couriers/'
      return Object.assign({}, state, {
        courierIsUpdating: false,
        courierData: action.data
      })

    case COURIER_UPDATE_FAILURE:
      return Object.assign({}, state, {
        courierIsUpdating: false,
        errors: action.errors
      })
    case COURIER_CREATE_SUCCESS:
      window.location = `/couriers/${action.data.id}`
      return Object.assign({}, state, {
        courierIsUpdating: false,
        courierdata: action.data
      })
    case COURIER_CREATE_FAILURE:
      return Object.assign({}, state, {
        courierIsCreating: false,
        courierErrordata: action.errors
      })
    case RESET_COURIER:
      return Object.assign({}, state, {
        data: {}
      })

    default:
      return state
  }
}
