import React from 'react'
import { FormGroup, FormControl } from 'react-bootstrap'

function MultiSelectForm({ options, input, group, ...rest }) {
  return (
    <FormGroup>
      <FormControl componentClass="select" multiple {...rest}>
        {options &&
          options.map(opt =>
            group ? (
              <optgroup key={opt.id} label={opt.name}>
                {opt[group].map(childOpt => (
                  <option key={childOpt.id} value={childOpt.id}>
                    {childOpt.name}
                  </option>
                ))}
              </optgroup>
            ) : (
              <option key={opt.id} value={opt.id}>
                {opt.name}
              </option>
            )
          )}
      </FormControl>
    </FormGroup>
  )
}

export default MultiSelectForm
