import {
  PURSE_LIST_REQUEST,
  PURSE_LIST_SUCCESS,
  PURSE_LIST_FAILURE,
  UPDATE_PURSE_FILTERS,
  CLEAN_PURSE,
  PURSE_CREATE_SUCCESS,
  PURSE_CREATE_REQUEST,
  CHECK_CIPCODE_REQUEST,
  CHECK_CIPCODE_SUCCESS,
  CHECK_CIPCODE_FAILURE
} from '../../actions/purseActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: {},
    filters: {
      page: 1
    },
    needRefresh: false,
    message: '',
    cipErrors: ''
  },
  action
) {
  switch (action.type) {
    case PURSE_CREATE_SUCCESS:
      return Object.assign({}, state, {
        needRefresh: true
      })
    case PURSE_CREATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })
    case PURSE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        message: '',
        cipErrors: ''
      })

    case PURSE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case PURSE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case UPDATE_PURSE_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    case CLEAN_PURSE:
      return Object.assign({}, state, {
        filters: { page: 1 },
        data: {}
      })

    case CHECK_CIPCODE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        message: '',
        cipErrors: ''
      })
    case CHECK_CIPCODE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        message: action.data
      })
    case CHECK_CIPCODE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        cipErrors: action.errors
      })
    default:
      return state
  }
}
