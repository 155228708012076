import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// COLLECTION GENERAL INFORMATION
export const COLLECTION_LIST_REQUEST = 'COLLECTION_LIST_REQUEST'
export const COLLECTION_LIST_SUCCESS = 'COLLECTION_LIST_SUCCESS'
export const COLLECTION_LIST_FAILURE = 'COLLECTION_LIST_FAILURE'
// COLLECTION DETAIL INFORMATION
export const COLLECTION_DETAIL_REQUEST = 'COLLECTION_DETAIL_REQUEST'
export const COLLECTION_DETAIL_SUCCESS = 'COLLECTION_DETAIL_SUCCESS'
export const COLLECTION_DETAIL_FAILURE = 'COLLECTION_DETAIL_FAILURE'
// COLLECTION UPDATE DATA
export const UPDATE_COLLECTION_FILTERS = 'UPDATE_COLLECTION_FILTERS'
export const COLLECTION_UPDATE_REQUEST = 'COLLECTION_UPDATE_REQUEST'
export const COLLECTION_UPDATE_SUCCESS = 'COLLECTION_UPDATE_SUCCESS'
export const COLLECTION_UPDATE_FAILURE = 'COLLECTION_UPDATE_FAILURE'
// COLLECTION DELETE DATA
export const SET_COLLECTION_ACTION_ACTIVE = 'SET_COLLECTION_ACTION_ACTIVE'
export const COLLECTION_DELETE_REQUEST = 'COLLECTION_DELETE_REQUEST'
export const COLLECTION_DELETE_SUCCESS = 'COLLECTION_DELETE_SUCCESS'
export const COLLECTION_DELETE_FAILURE = 'COLLECTION_DELETE_FAILURE'
// CREATE COLLECTION DATA
export const COLLECTION_CREATE_REQUEST = 'COLLECTION_CREATE_REQUEST'
export const COLLECTION_CREATE_SUCCESS = 'COLLECTION_CREATE_SUCCESS'
export const COLLECTION_CREATE_FAILURE = 'COLLECTION_CREATE_FAILURE'
// PAYMENT COLLECTION DATA
export const COLLECTION_PAYMENT_REQUEST = 'COLLECTION_PAYMENT_REQUEST'
export const COLLECTION_PAYMENT_SUCCESS = 'COLLECTION_PAYMENT_SUCCESS'
export const COLLECTION_PAYMENT_FAILURE = 'COLLECTION_PAYMENT_FAILURE'
// COLLECTION PAYMENT REQUEST
// COLLECTIONPAY_LIST_REQUEST, COLLECTIONPAY_LIST_SUCCESS, COLLECTIONPAY_LIST_FAILURE]
export const COLLECTIONPAY_LIST_REQUEST = 'COLLECTIONPAY_LIST_REQUEST'
export const COLLECTIONPAY_LIST_SUCCESS = 'COLLECTIONPAY_LIST_SUCCESS'
export const COLLECTIONPAY_LIST_FAILURE = 'COLLECTIONPAY_LIST_FAILURE'

export const SELECT_COLLECTION = 'SELECT_COLLECTION'
export const SELECT_COURIER_COLLECTION = 'SELECT_COURIER_COLLECTION'

export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const RESET_COLLECTION = 'RESET_COLLECTION'
export const UPDATE_COLLECTIONPAY_FILTERS = 'UPDATE_COLLECTIONPAY_FILTERS'

export const COLLECTIONPAY_DELETE_REQUEST = 'COLLECTIONPAY_DELETE_REQUEST'
export const COLLECTIONPAY_DELETE_FAILURE = 'COLLECTIONPAY_DELETE_FAILURE'
export const COLLECTIONPAY_DELETE_SUCCESS = 'COLLECTIONPAY_DELETE_SUCCESS'

export const COLLECTIONPENDING_DELETE_FAILURE =
  'COLLECTIONPENDING_DELETE_FAILURE'
export const COLLECTIONPENDING_DELETE_SUCCESS =
  'COLLECTIONPENDING_DELETE_SUCCESS'
export const COLLECTIONPENDING_DELETE_REQUEST =
  'COLLECTIONPENDING_DELETE_REQUEST'

export function collectionList(params = {}) {
  let url = 'collections/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        COLLECTION_LIST_REQUEST,
        COLLECTION_LIST_SUCCESS,
        COLLECTION_LIST_FAILURE
      ]
    }
  }
}

export function updateCollectionFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COLLECTION_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function selectCollection(obj) {
  return dispatch => {
    const action = {
      type: SELECT_COLLECTION,
      data: obj
    }
    dispatch(action)
  }
}

export function selectCourierCollection(obj) {
  return dispatch => {
    const action = {
      type: SELECT_COURIER_COLLECTION,
      data: obj
    }
    dispatch(action)
  }
}

export function showMessage() {
  return dispatch => {
    const action = {
      type: SHOW_MESSAGE
    }
    dispatch(action)
  }
}

export function hideMessage() {
  return dispatch => {
    const action = {
      type: HIDE_MESSAGE
    }
    dispatch(action)
  }
}

export function deleteCollection(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `collections/${id}/`,
      authenticated: true,
      localAPI: false,
      types: [
        COLLECTION_DELETE_REQUEST,
        COLLECTION_DELETE_SUCCESS,
        COLLECTION_DELETE_FAILURE
      ]
    }
  }
}

export function resetCollection() {
  return dispatch => {
    const action = {
      type: RESET_COLLECTION
    }
    dispatch(action)
  }
}

export function createCollection(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `collections/${data.id}/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        COLLECTION_PAYMENT_REQUEST,
        COLLECTION_PAYMENT_SUCCESS,
        COLLECTION_PAYMENT_FAILURE
      ]
    }
  }
}

export function deletePendingCollection(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `collections/${data.id}/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        COLLECTIONPENDING_DELETE_REQUEST,
        COLLECTIONPENDING_DELETE_SUCCESS,
        COLLECTIONPENDING_DELETE_FAILURE
      ]
    }
  }
}

export function collectionPaymentList(params = {}) {
  let url = 'collections/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        COLLECTIONPAY_LIST_REQUEST,
        COLLECTIONPAY_LIST_SUCCESS,
        COLLECTIONPAY_LIST_FAILURE
      ]
    }
  }
}

export function updateCollectionPayFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COLLECTIONPAY_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function deleteCollectionPay(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `collections/${data.id}/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        COLLECTIONPAY_DELETE_REQUEST,
        COLLECTIONPAY_DELETE_SUCCESS,
        COLLECTIONPAY_DELETE_FAILURE
      ]
    }
  }
}
