import { DESTINATION_STATUS } from '../../../../utils/variables'

export const selectDestinationStatus = [
  { value: '', label: 'Seleccionar estado' },
  { value: DESTINATION_STATUS.FAILED, label: 'Fallido' },
  { value: DESTINATION_STATUS.COMPLETED, label: 'Completado' }
]

export const navTabsOrderDetail = [
  {
    className: 'active',
    dataToggle: 'tab',
    href: '#tab-1',
    ariaExpanded: 'false',
    label: 'Destinos'
  },
  {
    className: '',
    dataToggle: 'tab',
    href: '#tab-2',
    ariaExpanded: 'true',
    label: 'Actividad'
  },
  {
    className: '',
    dataToggle: 'tab',
    href: '#tab-3',
    ariaExpanded: 'true',
    label: 'Pago'
  },
  {
    className: '',
    dataToggle: 'tab',
    href: '#tab-4',
    ariaExpanded: 'true',
    label: 'Precio / Costo'
  },
  {
    className: '',
    dataToggle: 'tab',
    href: '#tab-5',
    ariaExpanded: 'true',
    label: 'Anotaciones'
  },
  {
    className: '',
    dataToggle: 'tab',
    href: '#tab-6',
    ariaExpanded: 'true',
    label: 'Más información'
  }
]
