import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// CANDIDATE GENERAL INFORMATION
export const COURIER_REPORT_REQUEST = 'COURIER_REPORT_REQUEST'
export const COURIER_REPORT_FAILURE = 'COURIER_REPORT_FAILURE'
export const COURIER_REPORT_SUCCESS = 'COURIER_REPORT_SUCCESS'

// CANDIDATE UPDATE DATA
export const UPDATE_COURIERREPORT_FILTERS = 'UPDATE_COURIERREPORT_FILTERS'

export const RESET_CANDIDATE = 'RESET_CANDIDATE'

export function courierReportList(params = {}) {
  let url = 'reports/courier/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        COURIER_REPORT_REQUEST,
        COURIER_REPORT_SUCCESS,
        COURIER_REPORT_FAILURE
      ]
    }
  }
}

export function updateCourierReportFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COURIERREPORT_FILTERS,
      data
    }
    dispatch(action)
  }
}
