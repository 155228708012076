import couponActions, {
  UPDATE_COUPONREDEMPTION_FILTERS
} from '../../actions/couponActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: [],
    filters: {
      search: '',
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case couponActions.COUPON_REDEMPTION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        data: {}
      })

    case couponActions.COUPON_REDEMPTION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case couponActions.COUPON_REDEMPTION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    case UPDATE_COUPONREDEMPTION_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
