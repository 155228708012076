import {
  UPDATE_CONFIGPRICE_FILTERS,
  CONFIGPRICE_LIST_REQUEST,
  CONFIGPRICE_LIST_SUCCESS,
  SELECT_CONFIGPRICE,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  CONFIGPRICE_DELETE_REQUEST,
  CONFIGPRICE_DELETE_FAILURE,
  CONFIGPRICE_DELETE_SUCCESS,
  CONFIGPRICE_DETAIL_REQUEST,
  CONFIGPRICE_DETAIL_SUCCESS,
  CONFIGPRICE_DETAIL_FAILURE,
  CONFIGPRICE_CREATE_FAILURE,
  CONFIGPRICE_UPDATE_FAILURE,
  CONFIGPRICE_UPDATE_SUCCESS,
  CONFIGPRICE_UPDATE_REQUEST,
  CONFIGPRICE_CREATE_SUCCESS,
  RESET_CONFIGPRICE
} from '../../actions/ConfigPriceActions'

export default function reducer(
  state = {
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
    activeAction: null,
    needRefresh: false,
    selected: false,
    data: undefined,
    errors: [],
    filters: {
      search: '',
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case CONFIGPRICE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case CONFIGPRICE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })
    case UPDATE_CONFIGPRICE_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    case SELECT_CONFIGPRICE:
      return Object.assign({}, state, {
        selected: action.data
      })

    case SHOW_MESSAGE:
      return Object.assign({}, state, {
        message: true
      })

    case HIDE_MESSAGE:
      return Object.assign({}, state, {
        message: false
      })
    case CONFIGPRICE_DELETE_REQUEST:
      return Object.assign({}, state, {
        isDeleting: true
      })

    case CONFIGPRICE_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        activeAction: null,
        needRefresh: true
      })

    case CONFIGPRICE_DELETE_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false
      })

    case CONFIGPRICE_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case CONFIGPRICE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        dataDetail: action.data
      })

    case CONFIGPRICE_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case CONFIGPRICE_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isUpdating: true
      })

    case CONFIGPRICE_UPDATE_SUCCESS:
      window.location = '/configuration/prices/'
      return Object.assign({}, state, {
        isUpdating: false,
        needRefresh: true
      })

    case CONFIGPRICE_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false
      })

    case CONFIGPRICE_CREATE_SUCCESS:
      window.location = `/configuration/prices/${action.data.id}`
      return Object.assign({}, state, {
        isCreating: false,
        formulaData: action.data
      })

    case CONFIGPRICE_CREATE_FAILURE:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.errors
      })

    case RESET_CONFIGPRICE:
      return Object.assign({}, state, {
        dataDetail: {},
        data: undefined
      })

    default:
      return state
  }
}
