import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'
export const UPDATE_NOTIFICATION_FILTERS = 'UPDATE_NOTIFICATION_FILTERS'
export const UPDATE_NOTIFICATIONCLIENT_FILTERS =
  'UPDATE_NOTIFICATIONCLIENT_FILTERS'

const notificationActions = {}

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  const arrType = arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    notificationActions[type] = type
    return type
  })
  return arrType
}

export function notificationList(params = {}, opt) {
  const { method, entity, action } = opt
  let url = 'push/notification/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method,
      endpoint: url,
      authenticated: true,
      types: genTypeActions(entity, action)
    }
  }
}

export function notificationReset() {
  return dispatch => {
    const action = {
      type: RESET_NOTIFICATION
    }
    dispatch(action)
  }
}

export function updatenotificationFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_NOTIFICATION_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function createnotification(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `push/notification/`,
      authenticated: true,
      data,
      types: genTypeActions('notification', 'create')
    }
  }
}

genTypeActions('notification', 'list')
genTypeActions('notification', 'create')
export default notificationActions
