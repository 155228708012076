import {
  SALE_LIST_REQUEST,
  SALE_LIST_SUCCESS,
  SALE_LIST_FAILURE
} from '../../actions/saleActions'

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    data: {},
    errors: {},
    filters: {
      page: 1,
      search: '',
      type: [],
      credit: '',
      collection: '',
      only_billable: false,
      only_positive_balance: false
    },
    needRefresh: false,
    clientData: {},
    selected: {}
  },
  action
) {
  switch (action.type) {
    case SALE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case SALE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case SALE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case 'UPDATE_SALE_FILTERS':
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    default:
      return state
  }
}
