import * as SvgIcons from './SvgIcons'

const Icon = ({ type, size, ...rest }) => {
  if (!SvgIcons[type]) {
    return null
  }

  if (typeof size === 'string') {
    return SvgIcons[type]({ width: size, height: size, ...rest })
  }

  return SvgIcons[type]({ ...size, ...rest })
}

export default Icon
