import {
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAILURE,
  ADMIN_LIST_SIMPLE_REQUEST,
  ADMIN_LIST_SIMPLE_SUCCESS,
  ADMIN_LIST_SIMPLE_FAILURE,
  ADMIN_GET_DATA_REQUEST,
  ADMIN_GET_DATA_SUCCESS,
  ADMIN_GET_DATA_FAILURE,
  ADMIN_GROUPS_REQUEST,
  ADMIN_GROUPS_SUCCESS,
  ADMIN_GROUPS_FAILURE,
  UPDATE_LIST_FILTERS,
  CLEAN_DATA
} from '../../actions/adminActions'

const initialState = {
  isFetching: false,
  needRefresh: false,
  adminsList: {
    results: []
  },
  adminsListSimple: [],
  adminData: {},
  adminGroups: [],
  filters: { page: 1 },
  errors: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_GET_DATA_REQUEST:
    case ADMIN_GROUPS_REQUEST:
    case ADMIN_LIST_REQUEST:
    case ADMIN_LIST_SIMPLE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case ADMIN_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        adminsList: action.data
      })

    case ADMIN_LIST_SIMPLE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        adminsListSimple: action.data
      })

    case ADMIN_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        adminGroups: action.data.results
      }

    case ADMIN_GET_DATA_SUCCESS:
      return {
        ...state,
        adminData: {
          ...action.data,
          cities_ids: action.data.cities.map(city => city.id),
          groups_ids: action.data.groups.map(group => group.id)
        }
      }

    case ADMIN_GET_DATA_FAILURE:
    case ADMIN_GROUPS_FAILURE:
    case ADMIN_LIST_FAILURE:
    case ADMIN_LIST_SIMPLE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data
        },
        needRefresh: true
      }

    case CLEAN_DATA:
      return {
        ...state,
        needRefresh: true,
        adminData: {}
      }

    default:
      return state
  }
}
