import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import InputForm from '../../../01-atoms/InputForm'
import GroupButton from '../../../02-molecules/GroupButton'
import { validate } from './config.validations'
import CONFIG from './config.prices'

const OrderPrices = props => {
  const { prices, submitForm, handleSubmit, pristine, submitting } = props
  return (
    <div className="panel-body">
      <form className="form-horizontal" onSubmit={handleSubmit(submitForm)}>
        {CONFIG.map(el => (
          <fieldset key={el.value}>
            <legend>{el.title}</legend>
            {el.elements.map(item => (
              <div key={item.value} className="form-group">
                <label className="col-sm-3 control-label">{item.label}</label>
                <div className="col-sm-8">
                  {item.isInput ? (
                    <div>
                      <div>
                        <div className="col-md-4">
                          <Field
                            name={item.value}
                            label={item.placeholder}
                            component={InputForm}
                            onlyInput
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="col-sm-2">
                        <p className="form-control-static">
                          {prices[item.value]}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </fieldset>
        ))}
        <div className="hr-line-dashed" />
        <GroupButton
          btnColor="green"
          isdisabled={pristine || submitting}
          btnLabel="Guardar"
        />
      </form>
    </div>
  )
}

const mapStateToProps = state => {
  const isDashboard = !!window.location.pathname.match(/dashboard/)
  const initialValues = isDashboard
    ? state.dashboard.orderActive
    : state.orderDetail.data
  return {
    initialValues,
    enableReinitialize: true
  }
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'prices', validate })(OrderPrices)
)
