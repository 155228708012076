import {
  ADMIN_CITIES_REQUEST,
  ADMIN_CITIES_SUCCESS,
  ADMIN_CITIES_FAILURE,
  CHANGE_ADMIN_CITY_REQUEST,
  CHANGE_ADMIN_CITY_SUCCESS,
  CHANGE_ADMIN_CITY_FAILURE
} from '../../actions/all/adminCitiesActions'

function coordinatesFromString(strCoordinates) {
  const [lat, lng] = strCoordinates.replace(/ /g, '').split(',')
  return [parseFloat(lat), parseFloat(lng)]
}

function getCoordinates(data) {
  const { current_city_id, cities } = data
  const [cuttentCity] = cities.filter(city => city.city_id === current_city_id)
  return cuttentCity.latlon
}

const initialState = {
  isFetching: false,
  isChangingCity: false,
  data: {},
  errors: {}
}

export default function reucer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_CITIES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case ADMIN_CITIES_SUCCESS:
      localStorage.setItem(
        'adminCity',
        JSON.stringify(coordinatesFromString(getCoordinates(action.data)))
      )
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case ADMIN_CITIES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    case CHANGE_ADMIN_CITY_REQUEST:
      return Object.assign({}, state, {
        isChangingCity: true
      })
    case CHANGE_ADMIN_CITY_SUCCESS:
      return Object.assign({}, state, {
        isChangingCity: false
      })
    case CHANGE_ADMIN_CITY_FAILURE:
      return Object.assign({}, state, {
        isChangingCity: false
      })

    default:
      return state
  }
}
