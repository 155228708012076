import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { limitTime } from '../../utils/index'
import {
  dateFormat,
  calendarFormat,
  orderTypesTagFormat,
  paymentTypeTagFormat
} from '../../utils/formats'
import Status from '../01-atoms/Status'

/**
 * Card that includes a photo.
 */
export const PhotoCard = ({
  photo,
  title,
  subtitle,
  courierMapClick,
  element,
  openDetail
}) => (
  <div
    className="feed-element u-flex-center"
    style={{ padding: '12px', margin: '0px' }}
  >
    <div className="pull-left">
      <Link to={`/couriers/history/${element.id}`}>
        <img
          style={{ borderRadius: '5px' }}
          className="chat-avatar"
          src={photo}
          alt={title}
        />
      </Link>
      {element.current_vehicle ? (
        <img
          className="vehicle-type-image"
          src={element.current_vehicle.vehicle_type.image}
          alt=""
        />
      ) : null}
    </div>
    <div className="media-body ">
      <small className="text-muted">{calendarFormat(element.last_seen)}</small>
      <div
        className="u-flex-center"
        style={{ justifyContent: 'space-between' }}
      >
        <small className="u-bold u-margin-r-5">
          {`${Math.round(element.acceptance_rate * 100)}%`}{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://metabase.urbaner.com/question/246?courier_id=${element.id}`}
          >
            {element.notifications && element.notifications.total
              ? `${element.notifications.accepteds}/${element.notifications.total}`
              : 'N/A'}
          </a>
        </small>
        <div
          className="progress progress-striped active m-b-sm progress-bar-width"
          style={{ border: '1px solid gray', width: '20px', height: '10px' }}
        >
          <div
            style={{ width: `${element.acceptance_rate * 100}%` }}
            className="progress-bar"
          />
        </div>
      </div>
      <button
        type="button"
        onClick={() => {
          openDetail()
          courierMapClick(element)
        }}
        className="btn btn-link"
        style={{ margin: 0, padding: 0 }}
      >
        <strong>{title}</strong>
      </button>
      <br />
      <span className="text-muted">{subtitle}</span>
      <div className="tags-container">
        {orderTypesTagFormat(element.order_types)}
      </div>
      <div className="tags-container">
        {paymentTypeTagFormat(element.payment_type)}
      </div>
    </div>
  </div>
)

PhotoCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

/**
 * Card to show data with multiple fields.
 * TODO: more abstract
 */
export const DataCard = ({
  title,
  subtitle1,
  subtitle2,
  subtitle3,
  leftText,
  middleText,
  rightText,
  firstText,
  programmed_date,
  orderId,
  onButtonClick,
  onClick,
  status,
  position,
  isWillbeCancel,
  minutesAgo,
  status_label,
  status_destination,
  orderOptions,
  url,
  orderFilter,
  delayedDetail
}) => (
  <li className="list-group-item">
    <div className="dropdown icon-map">
      {orderOptions && orderOptions.length ? (
        <button
          data-toggle="dropdown"
          className="dropdown-toggle"
          style={{ background: 'transparent', border: 'none' }}
        >
          <span className="clear">
            <i className="fa fa-ellipsis-h" />
          </span>
        </button>
      ) : (
        ''
      )}
      <ul
        className="dropdown-menu m-t-xs"
        style={{ position: 'relative', minWidth: 'initial' }}
      >
        {orderOptions &&
          orderOptions.map((el, i) => (
            <li key={i}>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => el.onClickEl({ id: orderId }, el.options)}
              >
                {el.label}
              </button>
            </li>
          ))}
      </ul>
    </div>
    <div onClick={onClick} className="container-card-order">
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <div style={{ textAlign: 'left', width: '100%' }}>
          <Status className="u-margin-r-5" value={{ status, status_label }} />
          {orderFilter.delayed && (
            <Status
              className="u-margin-r-5"
              value={{ status: 'delay', status_label: 'Demorado' }}
            />
          )}
          {delayedDetail && (
            <Status
              className="u-margin-r-5"
              value={{
                status: 'delay',
                status_label: delayedDetail.task
              }}
            />
          )}
          {delayedDetail && (
            <Status
              className="u-margin-r-5"
              value={{
                status: 'delay',
                status_label: delayedDetail.task_status
              }}
            />
          )}
          {(status === 'in_progress' || status === 'ended') && (
            <Status
              className="u-margin-r-5"
              value={{ status_label: status_destination }}
            />
          )}
          {isWillbeCancel && !programmed_date && (
            <Status
              className="u-margin-r-5"
              value={{
                status: 'willbecancel',
                status_label: `${limitTime - minutesAgo}min`
              }}
            />
          )}
          {position && (
            <button
              type="button"
              className="btn btn-white btn-xs"
              onClick={onButtonClick}
            >
              <i className="fa fa-map-marker" />
            </button>
          )}
          <strong style={{ display: 'block', marginTop: '10px' }}>
            {title}
          </strong>
          <small className="text-muted">
            <a target="_blank" href={url} rel="noopener noreferrer">
              {subtitle1}
            </a>
            {programmed_date && (
              <div>
                <i className="fa fa-calendar u-margin-r-5" />
                {dateFormat(programmed_date)}
              </div>
            )}
          </small>
          <div className="m-b-xs" style={{ fontSize: '12px' }}>
            <div className="e-destination">
              <i
                className="fa fa-circle u-margin-r-5"
                style={{ color: '#548ab4' }}
              />
              <span className="u-ellipsis ellipsis-order">{subtitle2}</span>
            </div>
            <div className="e-destination">
              <i
                className="fa fa-circle u-margin-r-5"
                style={{ color: '#6dcd5e' }}
              />
              <span className="u-ellipsis ellipsis-order">{subtitle3}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="small m-t-xs">
        <div className="m-b-none">
          <div
            className="u-flex-center"
            style={{ justifyContent: 'space-between' }}
          >
            <div>
              {firstText.url && (
                <img
                  width="20"
                  style={{ marginLeft: '5px' }}
                  src={firstText.url}
                  alt={firstText.vehicle}
                />
              )}
            </div>
            <div>
              <i className="fa fa-road" /> {leftText}
            </div>
            <div>
              <i className="fa fa-money" /> {middleText}
            </div>
            <div>
              <i className="fa fa-clock-o" /> {rightText}
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
)

DataCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle1: PropTypes.string.isRequired,
  subtitle2: PropTypes.string.isRequired,
  leftText: PropTypes.string.isRequired,
  middleText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,

  /** Triggered when icon button is clicked . */
  onButtonClick: PropTypes.func.isRequired,

  /** Triggered when card is clicked . */
  onClick: PropTypes.func.isRequired
}
