import {
  PAYMENT_RETRIEVE_REQUEST,
  PAYMENT_RETRIEVE_SUCCESS,
  PAYMENT_RETRIEVE_FAILURE
} from '../../actions/paymentActions'

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    data: false,
    needRefresh: false,
    clientData: {},
    selected: {}
  },
  action
) {
  switch (action.type) {
    case PAYMENT_RETRIEVE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case PAYMENT_RETRIEVE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case PAYMENT_RETRIEVE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    default:
      return state
  }
}
