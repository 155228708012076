import purseCourierActions, {
  UPDATE_PURSECOURIERREPORTS_FILTERS,
  SELECT_ONE_REPORT
} from '../../actions/purseCourierActions'

export default function reducer(
  state = {
    isFetching: false,
    data: false,
    errors: {},
    filters: {
      _page: 1
    },
    selectedOne: false,
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case purseCourierActions.PAYMENTREPORTS_GET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case purseCourierActions.PAYMENTREPORTS_GET_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })
    case SELECT_ONE_REPORT:
      return Object.assign({}, state, {
        selectedOne: action.data
      })

    case UPDATE_PURSECOURIERREPORTS_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    default:
      return state
  }
}
