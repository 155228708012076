import {
  CLIENT_VIRTUALPOINTS_REQUEST,
  CLIENT_VIRTUALPOINTS_SUCCESS,
  CLIENT_VIRTUALPOINTS_FAILURE,
  UPDATE_CLIENTVIRTUALPOINTS_FILTERS
} from '../../actions/clientActions'

export default function reducer(
  state = {
    isFetching: false,
    isUpdating: false,
    data: {},
    errors: [],
    clientvpData: false,
    clientvpErrors: false,
    isSuccess: false,
    needRefresh: false,
    filters: {
      page: 1,
      search: '',
      created_at_0: null,
      created_at_1: null
    }
  },
  action
) {
  switch (action.type) {
    case CLIENT_VIRTUALPOINTS_REQUEST:
      return Object.assign({}, state, {
        needRefresh: false,
        data: {},
        filters: {
          page: 1,
          search: '',
          created_at_0: null,
          created_at_1: null
        }
      })

    case CLIENT_VIRTUALPOINTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isSuccess: false,
        data: action.data
      })

    case CLIENT_VIRTUALPOINTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_CLIENTVIRTUALPOINTS_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true,
        data: Array.isArray(state.data) ? {} : state.data
      })

    default:
      return state
  }
}
