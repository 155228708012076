import {
  PAYMENT_DETAIL_REQUEST,
  PAYMENT_DETAIL_SUCCESS,
  PAYMENT_DETAIL_FAILURE,
  UPDATE_PAYMENT_DETAIL_FILTERS
} from '../../actions/paymentActions'

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    data: {},
    errors: {},
    filters: {
      page: 1,
      search: ''
    },
    needRefresh: false,
    clientData: {},
    selected: {}
  },
  action
) {
  switch (action.type) {
    case PAYMENT_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case PAYMENT_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case PAYMENT_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_PAYMENT_DETAIL_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
