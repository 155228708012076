import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// PRICEPLANS GENERAL INFORMATION
export const PRICEPLANS_LIST_REQUEST = 'PRICEPLANS_LIST_REQUEST'
export const PRICEPLANS_LIST_SUCCESS = 'PRICEPLANS_LIST_SUCCESS'
export const PRICEPLANS_LIST_FAILURE = 'PRICEPLANS_LIST_FAILURE'

export const PRICEPLAN_DETAIL_REQUEST = 'PRICEPLAN_DETAIL_REQUEST'
export const PRICEPLAN_DETAIL_SUCCESS = 'PRICEPLAN_DETAIL_SUCCESS'
export const PRICEPLAN_DETAIL_FAILURE = 'PRICEPLAN_DETAIL_FAILURE'

export const PRICEPLAN_DELETE_REQUEST = 'PRICEPLAN_DELETE_REQUEST'
export const PRICEPLAN_DELETE_SUCCESS = 'PRICEPLAN_DELETE_SUCCESS'
export const PRICEPLAN_DELETE_FAILURE = 'PRICEPLAN_DELETE_FAILURE'

export const RESET_PRICEPLANS = 'RESET_PRICEPLANS'
export const UPDATE_PRICEPLAN_FILTERS = 'UPDATE_PRICEPLAN_FILTERS'

export function pricePlansList(params = {}) {
  let url = 'price_plans/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        PRICEPLANS_LIST_REQUEST,
        PRICEPLANS_LIST_SUCCESS,
        PRICEPLANS_LIST_FAILURE
      ]
    }
  }
}

export function resetPricePlans() {
  return dispatch => {
    const action = {
      type: RESET_PRICEPLANS
    }
    dispatch(action)
  }
}

export function pricePlanDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `price_plans/${id}/`,
      authenticated: true,
      types: [
        PRICEPLAN_DETAIL_REQUEST,
        PRICEPLAN_DETAIL_SUCCESS,
        PRICEPLAN_DETAIL_FAILURE
      ]
    }
  }
}

export function pricePlanDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `price_plans/${id}/`,
      authenticated: true,
      types: [
        PRICEPLAN_DELETE_REQUEST,
        PRICEPLAN_DELETE_SUCCESS,
        PRICEPLAN_DELETE_FAILURE
      ]
    }
  }
}

export function updatePricePlanFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_PRICEPLAN_FILTERS,
      data
    }
    dispatch(action)
  }
}
