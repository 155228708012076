// TODO:
// - Add more documentation

import React, { Component } from 'react'
import PropTypes from 'prop-types'

/** Get the icon class for the column. */
const getSortableIconClass = (field, activeField) => {
  if (field === activeField) return 'fa fa-sort-amount-asc'
  if (`-${field}` === activeField) return 'fa fa-sort-amount-desc'
  return 'fa fa-sort'
}

export const Column = ({
  head,
  field,
  sortable,
  ordering,
  onSort,
  count,
  callback,
  defaultSelectAll,
  width
}) => (
  <th
    width={width}
    className={sortable && 'sorting'}
    onClick={() => sortable && onSort(field)}
  >
    {count && (
      <input
        className="table-checkbox js-selectpage"
        type="checkbox"
        checked={defaultSelectAll()}
        onChange={e => {
          const allSelects = document.querySelectorAll('.js-selectAll')
          allSelects.forEach(el => {
            el.checked = e.target.checked
          })
          callback(e)
        }}
      />
    )}
    {head}
    {sortable && (
      <i
        className={getSortableIconClass(field, ordering)}
        style={{ marginLeft: '5px' }}
      />
    )}
  </th>
)

Column.propTypes = {
  /** Header text of the column. */
  head: PropTypes.string,

  /** Indicate if this column will be sortable */
  sortable: PropTypes.bool,

  /** Field corresponding to this column (used if sortable) */
  field: PropTypes.string,

  /** Current sortable field active. (used if sortable) */
  ordering: PropTypes.string,

  /** Triggered when click a sortable column. (used if sortable) */
  onSort: PropTypes.func
}

export const Row = ({
  columns,
  item,
  i,
  selectable,
  nestable,
  isSelected,
  onSelect,
  openRow,
  isOpen
}) => {
  const validateField = (childProps, index) => {
    const childProperty = childProps.field
    const hasProperty = Boolean(childProperty.match(/\./gi))

    if (childProps.field === 'index') return index + 1
    if (childProps.format) {
      const value = item[childProps.field]
      return childProps.format(value, index, item, childProps.field)
    }
    if (childProps.bindFormat) {
      const value = hasProperty
        ? childProperty.split('.').reduce((acc, el) => acc[el], item)
        : item[childProps.field]
      return childProps.bindFormat.bind({ value, array: item })()
    }

    return childProperty
      .split('.')
      .reduce((acc, el) => (acc ? acc[el] : false), item)
  }

  return (
    <tr key={item.id ? item.id : i}>
      {nestable && (
        <td style={{ verticalAlign: 'middle' }}>
          <button
            type="button"
            onClick={() => openRow(item)}
            style={{
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none',
              width: '100%',
              height: '100%',
              padding: 0,
              margin: 0
            }}
          >
            {isOpen ? (
              <i className="fa fa-angle-down" />
            ) : (
              <i className="fa fa-angle-right" />
            )}
          </button>
        </td>
      )}
      {selectable && (
        <td style={{ verticalAlign: 'middle' }}>
          <input
            className="table-checkbox js-selectAll"
            type="checkbox"
            onChange={e => onSelect(item, e.target.checked)}
            checked={isSelected(item)}
          />
        </td>
      )}
      {columns.map(
        (child, j) =>
          child.props.field && (
            <td
              style={{ verticalAlign: 'middle' }}
              key={child.id ? child.id : j}
            >
              {validateField(child.props, i)}
            </td>
          )
      )}
    </tr>
  )
}

export class Datatable extends Component {
  state = {
    ordering: undefined,
    openRows: []
  }

  componentDidMount() {
    this.defaultOpenRows()
  }

  componentDidUpdate(prevProps) {
    const { currentPage } = this.props
    if (prevProps.currentPage !== currentPage) {
      this.defaultOpenRows()
    }
  }

  handleSort = field => {
    const { ordering } = this.state
    const { onSort } = this.props
    let newField
    if (field === ordering) newField = `-${field}`
    this.setState({
      ordering: newField
    })
    onSort(newField)
  }

  defaultOpenRows = () => {
    const { openRows } = this.state
    const { defaultOpen, data } = this.props
    if (defaultOpen && data) {
      const ids = data.map(el => el.id)
      // Remove duplicate ids
      const rows = Array.from(new Set([...openRows, ...ids]))
      this.setState({ openRows: rows })
    }
  }

  handleOpenRow = item => {
    const { openRows } = this.state
    let rows = [...openRows]
    if (rows.includes(item.id)) {
      rows = rows.filter(el => el !== item.id)
    } else {
      rows = [...rows, item.id]
    }
    this.setState({ openRows: rows })
  }

  rowIsOpen = row => {
    const { openRows } = this.state
    return openRows.includes(row.id)
  }

  render() {
    const {
      data,
      onSelect,
      isSelected,
      selectable,
      className,
      style,
      children
    } = this.props
    const { ordering } = this.state

    const renderChildren = columns =>
      React.Children.map(columns, child => {
        if (child.props.sortable !== undefined)
          return React.cloneElement(child, {
            onSort: this.handleSort,
            ordering
          })
        return child
      })

    const prepareNested = (nested, cData) =>
      React.cloneElement(nested, {
        data: cData[nested.props.fieldRef]
      })

    const newChildren = Array.isArray(children) ? children : [children]
    const columns = newChildren.filter(child => child.type === Column)
    const nested = newChildren.find(child => child.type === Datatable) // just get one elemnent

    return (
      <table className={className} style={style}>
        <thead>
          <tr>{renderChildren(columns)}</tr>
        </thead>
        {nested ? (
          data &&
          data.map((item, i) => (
            <tbody key={item.id}>
              <Row
                {...{
                  columns,
                  item,
                  i,
                  selectable,
                  isSelected,
                  onSelect,
                  nestable: true,
                  openRow: this.handleOpenRow,
                  isOpen: this.rowIsOpen(item)
                }}
              />
              {this.rowIsOpen(item) ? (
                <tr className={`${nested.props.wrapperClassName}`}>
                  <td colSpan={columns.length}>
                    {prepareNested(nested, item)}
                  </td>
                </tr>
              ) : null}
            </tbody>
          ))
        ) : (
          <tbody>
            {data &&
              data.map((item, i) => (
                <Row
                  key={i}
                  {...{ columns, item, i, isSelected, onSelect, selectable }}
                />
              ))}
          </tbody>
        )}
      </table>
    )
  }
}

Datatable.propTypes = {
  /** Data to show in the table. */
  // data: PropTypes.array.isRequired,

  /** Triggered when click a sortable column. */
  onSort: PropTypes.func,

  /** Triggered when select a row. */
  // onSelect: PropTypes.string.func,

  /** If rows can be selected */
  selectable: PropTypes.bool,

  /** Table class name */
  className: PropTypes.string,

  /** Table style */
  style: PropTypes.object

  /** TODO: Document children it can be (Column or Datatable) */
}
