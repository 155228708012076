import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// COVERAGE GENERAL INFORMATION
export const COVERAGE_LIST_REQUEST = 'COVERAGE_LIST_REQUEST'
export const COVERAGE_LIST_SUCCESS = 'COVERAGE_LIST_SUCCESS'
export const COVERAGE_LIST_FAILURE = 'COVERAGE_LIST_FAILURE'

export const COVERAGE_DELETE_REQUEST = 'COVERAGE_DELETE_REQUEST'
export const COVERAGE_DELETE_SUCCESS = 'COVERAGE_DELETE_SUCCESS'
export const COVERAGE_DELETE_FAILURE = 'COVERAGE_DELETE_FAILURE'

export const RESET_COVERAGE = 'RESET_COVERAGE'
export const UPDATE_COVERAGE_FILTERS = 'UPDATE_COVERAGE_FILTERS'

export const DISTRICTSCOVERAGE_LIST_REQUEST = 'DISTRICTSCOVERAGE_LIST_REQUEST'
export const DISTRICTSCOVERAGE_LIST_SUCCESS = 'DISTRICTSCOVERAGE_LIST_SUCCESS'
export const DISTRICTSCOVERAGE_LIST_FAILURE = 'DISTRICTSCOVERAGE_LIST_FAILURE'

export function coverageList(params = {}) {
  let url = 'coverages/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        COVERAGE_LIST_REQUEST,
        COVERAGE_LIST_SUCCESS,
        COVERAGE_LIST_FAILURE
      ]
    }
  }
}

export function coverageDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `coverages/${id}/`,
      authenticated: true,
      types: [
        COVERAGE_DELETE_REQUEST,
        COVERAGE_DELETE_SUCCESS,
        COVERAGE_DELETE_FAILURE
      ]
    }
  }
}

export function resetCoverage() {
  return dispatch => {
    const action = {
      type: RESET_COVERAGE
    }
    dispatch(action)
  }
}

export function updateCoverageFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COVERAGE_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function getDistricts(params = {}) {
  let url = 'coverages/districts_coverage/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        DISTRICTSCOVERAGE_LIST_REQUEST,
        DISTRICTSCOVERAGE_LIST_SUCCESS,
        DISTRICTSCOVERAGE_LIST_FAILURE
      ]
    }
  }
}
