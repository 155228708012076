import React from 'react'
import TimePicker from 'react-time-picker'

const TimeForm = ({ name, value, onChange, placeholder, disabled }) => {
  return (
    <TimePicker
      format="HH:mm:ss"
      name={name}
      disableClock={true}
      disabled={disabled}
      value={value}
      onChange={val => onChange(val)}
      placeholderText={placeholder}
      maxDetail="second"
    />
  )
}

const TimeInputForm = field => (
  <div className="col-lg-12">
    <TimeForm {...field} />
  </div>
)

export default TimeInputForm
