import React, { Component } from 'react'
import Dropzone from 'react-dropzone'

class InputFileImage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accepted: [],
      rejected: []
    }
  }

  render() {
    const { image, inputProps } = this.props
    const imageShowed = typeof image === 'string'

    return (
      <section>
        <div
          className="dropzone"
          style={{ width: '100%', marginBottom: '1em' }}
        >
          <Dropzone
            multiple={false}
            accept=".jpg, .png, .svg"
            style={{
              width: '100% important',
              padding: '2em',
              textAlign: 'center',
              border: '2px solid #c9c9c9',
              color: 'gray'
            }}
            onDrop={(accepted, rejected) => {
              inputProps.onChange(accepted)
              this.setState({ accepted, rejected })
            }}
          >
            <div>
              {imageShowed && <img width="100" src={`${image}`} alt="" />}
            </div>
            <p style={{ maxWidth: '320px', margin: '0 auto' }}>
              Seleccione un archivo desde su computadora o arrástralo aquí.
            </p>
            {this.state.accepted.length ? (
              <div className="u-mt20" style={{ color: 'rgb(95, 162, 240)' }}>
                <span
                  style={{
                    marginTop: '1em'
                  }}
                >
                  Archivos cargados
                </span>
                <ul>
                  {this.state.accepted.map(f => (
                    <li key={f.name}>
                      {f.name}- {f.size}
                      bytes
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              ''
            )}
          </Dropzone>
        </div>
      </section>
    )
  }
}

export default InputFileImage
