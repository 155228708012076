import React from 'react'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'

export const FilterSwitch = ({ label, name, updateCallback, filters }) => (
  <label
    className="js-filter-child"
    style={{ display: 'flex', alignItems: 'center' }}
  >
    <Toggle
      icons={false}
      name={name}
      defaultChecked={filters[name]}
      onChange={e =>
        updateCallback({
          [e.target.name]: e.target.checked,
          page: 1
        })
      }
    />
    <span style={{ marginLeft: '1rem', whiteSpace: 'nowrap' }}>{label}</span>
  </label>
)
