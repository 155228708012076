import couponActions, {
  UPDATE_COUPONCLIENT_FILTERS
} from '../../actions/couponActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: [],
    filters: {
      search: '',
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case couponActions.COUPON_HISTORY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case couponActions.COUPON_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })
    case UPDATE_COUPONCLIENT_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    default:
      return state
  }
}
