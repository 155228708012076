import {
  COURIER_REFERRAL_DETAIL_REQUEST,
  COURIER_REFERRAL_DETAIL_SUCCESS,
  COURIER_REFERRAL_DETAIL_FAILURE
} from '../../actions/courierActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    errors: {},
    filters: {
      page: 1
    },
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case COURIER_REFERRAL_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case COURIER_REFERRAL_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case COURIER_REFERRAL_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    default:
      return state
  }
}
