import {
  RESET_PRICEPLANS,
  PRICEPLANS_LIST_REQUEST,
  PRICEPLANS_LIST_SUCCESS,
  PRICEPLANS_LIST_FAILURE,
  PRICEPLAN_DETAIL_REQUEST,
  PRICEPLAN_DETAIL_SUCCESS,
  PRICEPLAN_DETAIL_FAILURE,
  PRICEPLAN_DELETE_REQUEST,
  PRICEPLAN_DELETE_SUCCESS,
  PRICEPLAN_DELETE_FAILURE,
  UPDATE_PRICEPLAN_FILTERS
} from '../../actions/pricePlansActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    dataDetail: {},
    errors: {},
    needRefresh: false,
    filters: {
      search: '',
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case PRICEPLANS_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        errors: {}
      })

    case PRICEPLANS_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case PRICEPLANS_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case RESET_PRICEPLANS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false,
        data: [],
        dataDetail: {},
        errors: {}
      })

    case PRICEPLAN_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        dataDetail: {}
      })

    case PRICEPLAN_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        dataDetail: action.data
      })

    case PRICEPLAN_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    case PRICEPLAN_DELETE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case PRICEPLAN_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: true
      })

    case PRICEPLAN_DELETE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    case UPDATE_PRICEPLAN_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
