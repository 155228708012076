import {
  RESET_CLIENT_TYPE,
  CLIENT_TYPE_LIST_REQUEST,
  CLIENT_TYPE_LIST_SUCCESS,
  CLIENT_TYPE_LIST_FAILURE
} from '../../actions/clientTypeActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    errors: {},
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case CLIENT_TYPE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case CLIENT_TYPE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case CLIENT_TYPE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case RESET_CLIENT_TYPE:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false,
        data: [],
        errors: {}
      })
    default:
      return state
  }
}
