import {
  vehicle_typeS_REQUEST,
  vehicle_typeS_SUCCESS,
  vehicle_typeS_FAILURE,
  vehicle_type_REQUEST,
  vehicle_type_SUCCESS,
  vehicle_type_FAILURE,
  UPDATE_VEHICLETYPES_LIST_FILTERS,
  vehicle_typeS_RESET
} from '../../actions/vehicleTypeActions'

const initialState = {
  isFetching: false,
  needRefresh: false,
  singleVehicleType: {},
  data: [],
  vehicleTypesList: {
    results: []
  },
  filters: { page: 1 },
  errors: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case vehicle_type_REQUEST:
    case vehicle_typeS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        filters: { page: 1 }
      })

    case vehicle_typeS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data.results,
        vehicleTypesList: action.data
      })

    case vehicle_type_SUCCESS:
      return {
        ...state,
        singleVehicleType: action.data,
        isFetching: false
      }

    case vehicle_typeS_RESET:
      return Object.assign({}, state, {
        isFetching: false,
        data: [],
        singleVehicleType: null,
        needRefresh: false
      })

    case vehicle_type_FAILURE:
    case vehicle_typeS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_VEHICLETYPES_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data
        },
        needRefresh: true
      }

    default:
      return state
  }
}
