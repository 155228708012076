export const searchDashboard = [
  {
    type: 'searchBy',
    label: 'Nombre Comercial',
    placeholder: 'Nombre Comercial',
    name: 'client__tradename',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Pedido',
    placeholder: 'Código de Pedido',
    name: 'code',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Contacto',
    placeholder: 'Nombre de Contacto',
    name: 'search_destination',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Cliente',
    placeholder: 'Nombre de cliente',
    name: 'client_full_name',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Email de Cliente',
    placeholder: 'Email de Cliente',
    name: 'client__user__email',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Transportista',
    placeholder: 'Nombre de Transportista',
    name: 'courier_full_name',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Código de Referencia',
    placeholder: 'Código de Referencia',
    name: 'memo',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Miembro',
    placeholder: 'Cliente miembro asociado',
    name: 'user_full_name',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Email de Miembro',
    placeholder: 'Email de miembro asociado',
    name: 'user__email',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Memo de Ruta',
    placeholder: 'Memo de Ruta',
    name: 'batch_memo',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Guía de Remisión',
    placeholder: 'Código de Guía',
    name: 'shipping_document',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Operación',
    placeholder: 'Nombre de Operación',
    name: 'operation',
    defaultValue: ''
  }
]
