import React from 'react'

export const uploadFile = props => (
  <svg width="1em" height="1em" viewBox="0 0 72 72" {...props}>
    <title>cargar-archivo</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#D1D1D1" cx={36} cy={36} r={36} />
      <g fill="#FFF" fillRule="nonzero">
        <path d="M40.345 30.837c.342 0 .62.282.62.63a.625.625 0 0 1-.62.628H24.828a.625.625 0 0 1-.621-.629c0-.347.278-.629.62-.629h15.518zm-15.517-3.774a.625.625 0 0 1-.621-.629c0-.347.278-.628.62-.628h6.207c.343 0 .621.281.621.628a.625.625 0 0 1-.62.63h-6.207zm16.138 9.435a.625.625 0 0 1-.621.629H24.828a.625.625 0 0 1-.621-.63c0-.346.278-.628.62-.628h15.518c.342 0 .62.282.62.629zM36 41.53a.625.625 0 0 1-.62.629H24.827a.625.625 0 0 1-.621-.63c0-.347.278-.628.62-.628H35.38c.343 0 .621.281.621.629zm-11.172 4.402h8.69c.342 0 .62.282.62.63a.625.625 0 0 1-.62.628h-8.69a.625.625 0 0 1-.621-.629c0-.347.278-.629.62-.629z" />
        <path d="M19.241 18.258h18v8.805h8.69v10.693h1.241V26.174L38.12 17H18v37.738h19.241V53.48h-18V18.258zm19.242.89l6.57 6.658h-6.57v-6.659z" />
        <path d="M39.103 39.643v15.095H54V39.643H39.103zM52.76 53.48h-5.587v-7.916l2.665 2.7.878-.89-4.163-4.218-4.163 4.219.877.889 2.665-2.7v7.916h-5.586V40.9h12.414v12.58z" />
      </g>
    </g>
  </svg>
)
