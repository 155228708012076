export const validate = values => {
  const errors = {}
  if (!values.cost_modification && values.cost_modification !== 0) {
    errors.cost_modification = 'Requerido'
  } else if (isNaN(Number(values.cost_modification))) {
    errors.cost_modification = 'Debe ser un numero'
  }
  if (!values.price_modification && values.price_modification !== 0) {
    errors.price_modification = 'Requerido'
  } else if (isNaN(Number(values.price_modification))) {
    errors.price_modification = 'Debe ser un numero'
  }
  if (
    values.price_modification &&
    values.price_modification !== '0' &&
    !values.price_modification_comment
  ) {
    errors.price_modification_comment = 'Debe ingresar un comentario'
  }
  if (
    typeof values.total_price === 'string' &&
    !values.price_modification_comment
  ) {
    errors.price_modification_comment = 'Debe ingresar un comentario'
  }
  if (
    values.cost_modification &&
    values.cost_modification !== '0' &&
    !values.cost_modification_comment
  ) {
    errors.cost_modification_comment = 'Debe ingresar un comentario'
  }
  if (
    typeof values.total_cost === 'string' &&
    !values.cost_modification_comment
  ) {
    errors.cost_modification_comment = 'Debe ingresar un comentario'
  }
  return errors
}
