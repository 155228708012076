import {
  UPDATE_CANDIDATE_FILTERS,
  CANDIDATE_LIST_REQUEST,
  CANDIDATE_LIST_SUCCESS,
  CANDIDATE_LIST_FAILURE,
  CANDIDATE_CHANGE_STATUS_REQUEST,
  CANDIDATE_CHANGE_STATUS_SUCCESS,
  CANDIDATE_CHANGE_STATUS_FAILURE,
  CANDIDATE_DETAIL_SUCCESS
} from '../../actions/candidateActions'

const parseCandidate = data => {
  const newData = { ...data }
  newData.results = newData.results.reduce((acc, el) => {
    const element = { ...el }
    element.vehicle.message = element.message
    element.vehicle = [element.vehicle]
    element.vehicle.push(element.message)
    acc.push(element)
    return acc
  }, [])
  return newData
}

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    error_change_status: [],
    errors: {},
    filters: {
      page: 1,
      search: '',
      created_at_0: null,
      created_at_1: null
    },
    candidateDetail: false,
    isSuccess: false,
    activeAction: null,
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case CANDIDATE_CHANGE_STATUS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        error_change_status: [],
        isSuccess: false
      })
    case CANDIDATE_CHANGE_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: true,
        isSuccess: true
      })
    case CANDIDATE_CHANGE_STATUS_FAILURE:
      return Object.assign({}, state, {
        error_change_status: action.errors
      })
    case CANDIDATE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case CANDIDATE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: parseCandidate(action.data)
      })

    case CANDIDATE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case UPDATE_CANDIDATE_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    case CANDIDATE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        candidateDetail: action.data
      })
    default:
      return state
  }
}
