import React from 'react'
import PropTypes from 'prop-types'

/**
 * Header for a section.
 */
const Header = ({ title, children, isFailed, isSuccess }) => (
  <div className="ibox-title">
    <div className="row">
      <div className="col-lg-6">
        <h2>{title}</h2>
        {isFailed && (
          <span className="has-error">
            Upss.. algo paso!, Intentalo de nuevo.
          </span>
        )}
        {isSuccess && (
          <span className="has-success">
            Todo bien, te redirigiremos a la factura generada.{' '}
          </span>
        )}
      </div>
      <div className="col-lg-6">{children}</div>
    </div>
  </div>
)

Header.propTypes = {
  title: PropTypes.string.isRequired,

  /** Childrens to include in the right side. */
  children: PropTypes.node
}

export default Header
