import { CALL_API } from '../../middleware/api'

export const AVAILABLE_COUNTRIES_REQUEST = 'AVAILABLE_COUNTRIES_REQUEST'
export const AVAILABLE_COUNTRIES_SUCCESS = 'AVAILABLE_COUNTRIES_SUCCESS'
export const AVAILABLE_COUNTRIES_FAILURE = 'AVAILABLE_COUNTRIES_FAILURE'

export function getAvailableCountries() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `api/client/countries/`,
      isPublic: true,
      types: [
        AVAILABLE_COUNTRIES_REQUEST,
        AVAILABLE_COUNTRIES_SUCCESS,
        AVAILABLE_COUNTRIES_FAILURE
      ]
    }
  }
}
