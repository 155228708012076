import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const RESET_CHANGELOG = 'RESET_CHANGELOG'
export const UPDATE_CHANGELOG_FILTERS = 'UPDATE_CHANGELOG_FILTERS'
export const SELECT_CHANGELOG = 'SELECT_CHANGELOG'

export const CHANGELOG_DETAIL_REQUEST = 'CHANGELOG_DETAIL_REQUEST'
export const CHANGELOG_DETAIL_SUCCESS = 'CHANGELOG_DETAIL_SUCCESS'
export const CHANGELOG_DETAIL_FAILURE = 'CHANGELOG_DETAIL_FAILURE'

export const CHANGELOG_DELETE_REQUEST = 'CHANGELOG_DELETE_REQUEST'
export const CHANGELOG_DELETE_SUCCESS = 'CHANGELOG_DELETE_SUCCESS'
export const CHANGELOG_DELETE_FAILURE = 'CHANGELOG_DELETE_FAILURE'

const changelogActions = {}

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  const arrType = arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    changelogActions[type] = type
    return type
  })
  return arrType
}

export function changelogList(params = {}, opt) {
  const { method, entity, action } = opt
  let url = 'changelog/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method,
      localAPI: false,
      endpoint: url,
      authenticated: true,
      types: genTypeActions(entity, action)
    }
  }
}

export function changelogReset() {
  return dispatch => {
    const action = {
      type: RESET_CHANGELOG
    }
    dispatch(action)
  }
}

export function updateChangelogFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_CHANGELOG_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function selectChangelog(obj) {
  return dispatch => {
    const action = {
      type: SELECT_CHANGELOG,
      data: obj
    }
    dispatch(action)
  }
}

export function changelogDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `changelog/${id}`,
      authenticated: true,
      types: [
        CHANGELOG_DETAIL_REQUEST,
        CHANGELOG_DETAIL_SUCCESS,
        CHANGELOG_DETAIL_FAILURE
      ]
    }
  }
}

export function changelogDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `changelog/${id}/`,
      authenticated: true,
      localAPI: false,
      types: [
        CHANGELOG_DELETE_REQUEST,
        CHANGELOG_DELETE_SUCCESS,
        CHANGELOG_DELETE_FAILURE
      ]
    }
  }
}

genTypeActions('changelog', 'list')
export default changelogActions
