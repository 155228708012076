import { WS_MESSAGE_SHOP } from '../../actions/wsActions'
import { SALE_ORDER_STATUS_SUCCESS } from '../../actions/saleActions'

const initialState = {
  data: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case WS_MESSAGE_SHOP:
      if (action.data.stream === 'order') {
        return Object.assign({}, state, {
          data: action.data.payload.data
        })
      }
      return state

    case SALE_ORDER_STATUS_SUCCESS:
      return Object.assign({}, state, {
        data: action.data
      })

    default:
      return state
  }
}
