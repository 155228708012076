import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE'

export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST'
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS'
export const ORDER_DETAIL_FAILURE = 'ORDER_DETAIL_FAILURE'

export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST'
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS'
export const ORDER_UPDATE_FAILURE = 'ORDER_UPDATE_FAILURE'

export const UPDATE_ORDER_FILTERS = 'UPDATE_ORDER_FILTERS'
export const UPDATE_ORDER_PHOTO = 'UPDATE_ORDER_FILTERS'
export const SET_ORDER_ACTION_ACTIVE = 'SET_ORDER_ACTION_ACTIVE'
export const SELECT_ORDER = 'SELECT_ORDER'

export const ORDER_BATCH_DELETE_REQUEST = 'ORDER_BATCH_DELETE_REQUEST'
export const ORDER_BATCH_DELETE_SUCCESS = 'ORDER_BATCH_DELETE_SUCCESS'
export const ORDER_BATCH_DELETE_FAILURE = 'ORDER_BATCH_DELETE_FAILURE'

export const ORDER_BATCH_UPDATE_REQUEST = 'ORDER_BATCH_UPDATE_REQUEST'
export const ORDER_BATCH_UPDATE_SUCCESS = 'ORDER_BATCH_UPDATE_SUCCESS'
export const ORDER_BATCH_UPDATE_FAILURE = 'ORDER_BATCH_UPDATE_FAILURE'

export const UPDATE_ORDER_BATCH_FILTERS = 'UPDATE_ORDER_BATCH_FILTERS'
export const ORDER_UPDATE_NOTE_REQUEST = 'ORDER_UPDATE_NOTE_REQUEST'
export const ORDER_UPDATE_NOTE_SUCCESS = 'ORDER_UPDATE_NOTE_SUCCESS'
export const ORDER_UPDATE_NOTE_FAILURE = 'ORDER_UPDATE_NOTE_FAILURE'

export const UPDATE_ORDER_DASHBOARD_FILTERS = 'UPDATE_ORDER_DASHBOARD_FILTERS'
export const ORDER_LIST_DASHBOARD_SUCCESS = 'ORDER_LIST_DASHBOARD_SUCCESS'
export const ORDER_LIST_DASHBOARD_FAILURE = 'ORDER_LIST_DASHBOARD_FAILURE'
export const ORDER_LIST_DASHBOARD_REQUEST = 'ORDER_LIST_DASHBOARD_REQUEST'

export const CLEAN_ORDER_FILTER = 'CLEAN_ORDER_FILTER'
export const ORDER_INVOICING_SUCCESS = 'ORDER_INVOICING_SUCCESS'
export const ORDER_INVOICING_FAILURE = 'ORDER_INVOICING_FAILURE'
export const ORDER_INVOICING_REQUEST = 'ORDER_INVOICING_REQUEST'

export const SELECT_ORDER_INVOICING = 'SELECT_ORDER_INVOICING'
export const RESET_ORDER_INVOICING = 'RESET_ORDER_INVOICING'
export const UPDATE_INVOICING_FILTERS = 'UPDATE_INVOICING_FILTERS'
export const RESET_ORDERLIST = 'RESET_ORDERLIST'
export const UPDATE_COURIERHISTORY_FILTERS = 'UPDATE_COURIERHISTORY_FILTERS'
export const RESET_COURIER_HISTORY = 'RESET_COURIER_HISTORY'

export const ORDER_RETRY_PAYMENT_REQUEST = 'ORDER_RETRY_PAYMENT_REQUEST'
export const ORDER_RETRY_PAYMENT_SUCCESS = 'ORDER_RETRY_PAYMENT_SUCCESS'
export const ORDER_RETRY_PAYMENT_FAILURE = 'ORDER_RETRY_PAYMENT_FAILURE'

export const ORDER_CHARGE_PURSE_REQUEST = 'ORDER_CHARGE_PURSE_REQUEST'
export const ORDER_CHARGE_PURSE_SUCCESS = 'ORDER_CHARGE_PURSE_SUCCESS'
export const ORDER_CHARGE_PURSE_FAILURE = 'ORDER_CHARGE_PURSE_FAILURE'

export const GROUP_INDIVIDUAL_ORDERS_REQUEST =
  'GROUP_INDIVIDUAL_ORDERS_REQUEST'
export const GROUP_INDIVIDUAL_ORDERS_SUCCESS =
  'GROUP_INDIVIDUAL_ORDERS_SUCCESS'
export const GROUP_INDIVIDUAL_ORDERS_FAILURE =
  'GROUP_INDIVIDUAL_ORDERS_FAILURE'

export const ORDER_STATUS_REASON_OPTIONS_REQUEST =
  'ORDER_STATUS_REASON_OPTIONS_REQUEST'
export const ORDER_STATUS_REASON_OPTIONS_SUCCESS =
  'ORDER_STATUS_REASON_OPTIONS_SUCCESS'
export const ORDER_STATUS_REASON_OPTIONS_FAILURE =
  'ORDER_STATUS_REASON_OPTIONS_FAILURE'

export const EDIT_SHIPPING_DOCUMENT_REQUEST = 'EDIT_SHIPPING_DOCUMENT_REQUEST'
export const EDIT_SHIPPING_DOCUMENT_SUCCESS = 'EDIT_SHIPPING_DOCUMENT_SUCCESS'
export const EDIT_SHIPPING_DOCUMENT_FAILURE = 'EDIT_SHIPPING_DOCUMENT_FAILURE'

const orderActions = {}

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  const arrType = arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    orderActions[type] = type
    return type
  })
  return arrType
}

export function orderDashboardActions(params = {}, options) {
  const { data, method, entity, action, urlConcat } = options
  let url = 'orders/'
  if (urlConcat) {
    const { id } = params
    url = `${url}${id}${urlConcat}`
    delete params.id
  }
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method,
      endpoint: url,
      authenticated: true,
      data,
      types: genTypeActions(entity, action)
    }
  }
}

export function orderList(params = {}) {
  let url = 'orders/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      msg: 'order',
      authenticated: true,
      extra: params,
      types: [ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS, ORDER_LIST_FAILURE]
    }
  }
}

export function orderListDashboard(params = {}) {
  let url = 'orders/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      msg: 'order',
      extra: params,
      types: [
        ORDER_LIST_DASHBOARD_REQUEST,
        ORDER_LIST_DASHBOARD_SUCCESS,
        ORDER_LIST_DASHBOARD_FAILURE
      ]
    }
  }
}

export function orderDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `orders/${id}/`,
      authenticated: true,
      types: [ORDER_DETAIL_REQUEST, ORDER_DETAIL_SUCCESS, ORDER_DETAIL_FAILURE]
    }
  }
}

export const showToast = (message, type) => ({
  type: SHOW_TOAST,
  payload: { message, type }
})

export const hideToast = () => ({
  type: HIDE_TOAST
})

export function orderUpdate(id, data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `orders/${id}/`,
      authenticated: true,
      data,
      types: [
        ORDER_UPDATE_REQUEST,
        ORDER_UPDATE_SUCCESS,
        ORDER_UPDATE_FAILURE,
        {
          type: ORDER_UPDATE_FAILURE,
          payload: (action, state, res) => {
            return res.json().then(json => ({ json, status: res.status }))
          }
        }
      ]
    }
  }
}

export function orderNoteUpdate(orderId, data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `orders/${orderId}/notes/`,
      authenticated: true,
      data,
      types: [
        ORDER_UPDATE_NOTE_REQUEST,
        ORDER_UPDATE_NOTE_SUCCESS,
        ORDER_UPDATE_NOTE_FAILURE
      ]
    }
  }
}

export function updateOrderFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_ORDER_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function updateOrderDashboardFilters(data, extra) {
  return dispatch => {
    const action = {
      type: UPDATE_ORDER_DASHBOARD_FILTERS,
      data,
      extra
    }
    dispatch(action)
  }
}

export function setOrderActionActive(actionName) {
  return dispatch => {
    const action = {
      type: SET_ORDER_ACTION_ACTIVE,
      data: actionName
    }
    dispatch(action)
  }
}

export function selectOrder(order) {
  return dispatch => {
    const action = {
      type: SELECT_ORDER,
      data: order
    }
    dispatch(action)
  }
}

export function batchOrderDelete(params) {
  let url = 'orders/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        ORDER_BATCH_DELETE_REQUEST,
        ORDER_BATCH_DELETE_SUCCESS,
        ORDER_BATCH_DELETE_FAILURE
      ]
    }
  }
}

export function batchOrderUpdate(params, data) {
  let url = 'orders/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: url,
      authenticated: true,
      data,
      types: [
        ORDER_BATCH_UPDATE_REQUEST,
        ORDER_BATCH_UPDATE_SUCCESS,
        ORDER_BATCH_UPDATE_FAILURE
      ]
    }
  }
}

export function cleanOrderFilters() {
  return dispatch => {
    const action = {
      type: CLEAN_ORDER_FILTER
    }
    dispatch(action)
  }
}

export function orderInvoicingList(params = {}) {
  let url = 'orders/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        ORDER_INVOICING_REQUEST,
        ORDER_INVOICING_SUCCESS,
        ORDER_INVOICING_FAILURE
      ]
    }
  }
}

export function selectOrderInvoicing(order) {
  return dispatch => {
    const action = {
      type: SELECT_ORDER_INVOICING,
      data: order
    }
    dispatch(action)
  }
}

export function resetInvoicing() {
  return dispatch => {
    const action = {
      type: RESET_ORDER_INVOICING
    }
    dispatch(action)
  }
}

export function updateInvoiceFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_INVOICING_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function resetOrder() {
  return dispatch => {
    const action = {
      type: RESET_ORDERLIST
    }
    dispatch(action)
  }
}

export function orderCourierHistoryList(params = {}) {
  let url = 'orders/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: genTypeActions('order', 'courierhistory')
    }
  }
}

export function updateCourierHistoryFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COURIERHISTORY_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function selectOrderToEditPhoto(data) {
  return dispatch => {
    const action = {
      type: UPDATE_ORDER_PHOTO,
      data
    }
    dispatch(action)
  }
}

export function resetCourierHistory() {
  return dispatch => {
    const action = {
      type: RESET_COURIER_HISTORY
    }
    dispatch(action)
  }
}

export function retryPayment(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'payments/',
      authenticated: true,
      data,
      types: [
        ORDER_RETRY_PAYMENT_REQUEST,
        ORDER_RETRY_PAYMENT_SUCCESS,
        ORDER_RETRY_PAYMENT_FAILURE
      ]
    }
  }
}

export function chargeFromPurse(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'payments/',
      authenticated: true,
      data,
      types: [
        ORDER_CHARGE_PURSE_REQUEST,
        ORDER_CHARGE_PURSE_SUCCESS,
        ORDER_CHARGE_PURSE_FAILURE
      ]
    }
  }
}

// DEPRECATED
export function orderUploadPhotos(data, id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `orders/${id}/photos/`,
      authenticated: true,
      data,
      types: genTypeActions('order', 'upload_photo')
    }
  }
}

export function groupIndividualOrders(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `orders/batch/individual/`,
      authenticated: true,
      data,
      types: genTypeActions('group', 'individual_orders')
    }
  }
}

export function getStatusReasonOptions(params = {}) {
  let url = 'status_reason/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        ORDER_STATUS_REASON_OPTIONS_REQUEST,
        ORDER_STATUS_REASON_OPTIONS_SUCCESS,
        ORDER_STATUS_REASON_OPTIONS_FAILURE
      ]
    }
  }
}

export function editShippingDocument(data, id) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `orders/${id}/shipping_document/`,
      authenticated: true,
      data,
      types: [
        EDIT_SHIPPING_DOCUMENT_REQUEST,
        EDIT_SHIPPING_DOCUMENT_SUCCESS,
        EDIT_SHIPPING_DOCUMENT_FAILURE
      ]
    }
  }
}

genTypeActions('order', 'courierhistory')
genTypeActions('order', 'setoptions')
export default orderActions
