import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// PURSE GENERAL INFORMATION
export const PURSECOURIER_LIST_REQUEST = 'PURSECOURIER_LIST_REQUEST'
export const PURSECOURIER_LIST_SUCCESS = 'PURSECOURIER_LIST_SUCCESS'
export const PURSECOURIER_LIST_FAILURE = 'PURSECOURIER_LIST_FAILURE'
// PURSECOURIER UPDATE DATA
export const UPDATE_PURSECOURIER_FILTERS = 'UPDATE_PURSECOURIER_FILTERS'

export const PURSECOURIERTRANSACTION_LIST_REQUEST =
  'PURSECOURIERTRANSACTION_LIST_REQUEST'
export const PURSECOURIERTRANSACTION_LIST_SUCCESS =
  'PURSECOURIERTRANSACTION_LIST_SUCCESS'
export const PURSECOURIERTRANSACTION_LIST_FAILURE =
  'PURSECOURIERTRANSACTION_LIST_FAILURE'

export const UPDATE_PURSECOURIERTRANSACTION_FILTERS =
  'UPDATE_PURSECOURIERTRANSACTION_FILTERS'
export const CLEAN_PURSECOURIER_COURIER = 'CLEAN_PURSECOURIER_COURIER'
export const UPDATE_PURSECOURIERREQUEST_FILTERS =
  'UPDATE_PURSECOURIERREQUEST_FILTERS'
export const UPDATE_PURSECOURIERREPORTS_FILTERS =
  'UPDATE_PURSECOURIERREPORTS_FILTERS'
export const SELECT_ONE_REPORT = 'SELECT_ONE_REPORT'

const purseCourierActions = {}

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  const arrType = arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    purseCourierActions[type] = type
    return type
  })
  return arrType
}

export function courierPurseList(params = {}) {
  let url = 'reports/courier_purses/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        PURSECOURIER_LIST_REQUEST,
        PURSECOURIER_LIST_SUCCESS,
        PURSECOURIER_LIST_FAILURE
      ]
    }
  }
}

export function updateCourierPurseFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_PURSECOURIER_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function cleanCourierPurse() {
  return dispatch => {
    const action = {
      type: CLEAN_PURSECOURIER_COURIER
    }
    dispatch(action)
  }
}

export function courierPurseTransactionList(params = {}) {
  let url = 'courier_purse_transactions/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        PURSECOURIERTRANSACTION_LIST_REQUEST,
        PURSECOURIERTRANSACTION_LIST_SUCCESS,
        PURSECOURIERTRANSACTION_LIST_FAILURE
      ]
    }
  }
}

export function updateCourierPurseTransactionFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_PURSECOURIERTRANSACTION_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function PurseTransactionTypeList(params = {}) {
  let url = 'courier_purse_transactions/types/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: genTypeActions('transactionType', 'get')
    }
  }
}

export function purseRequestPaymentList(params = {}) {
  let url = 'courier_payment_requests/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: genTypeActions('paymentrequest', 'get')
    }
  }
}

export function updateCourierRequestFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_PURSECOURIERREQUEST_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function changeCourierPaymentStatus(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `courier_payment_requests/${data.id}/`,
      authenticated: true,
      data,
      localAPI: false,
      types: genTypeActions('paymentrequest', 'change')
    }
  }
}

export function updateCourierReportsFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_PURSECOURIERREPORTS_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function purseReportsPaymentList(params = {}) {
  let url = 'reports/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: true,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: genTypeActions('paymentreports', 'get')
    }
  }
}

export function isValidChange(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `courier_purse_transactions/${data.id}/`,
      authenticated: true,
      data,
      localAPI: false,
      types: genTypeActions('transactionType', 'payment')
    }
  }
}

export function selectOneReport(report) {
  return dispatch => {
    const action = {
      type: SELECT_ONE_REPORT,
      data: report
    }
    dispatch(action)
  }
}

genTypeActions('transactionType', 'payment')
genTypeActions('transactionType', 'get')
genTypeActions('paymentrequest', 'get')
genTypeActions('paymentrequest', 'change')
genTypeActions('paymentreports', 'get')
export default purseCourierActions
