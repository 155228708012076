import sourceActions, {
  UPDATE_DISCOVERY_SOURCE_FILTERS,
  DISCOVERY_SOURCE_RESET,
  SELECT_DISCOVERY_SOURCE,
  DISCOVERY_SOURCE_DELETE_SUCCESS,
  DISCOVERY_SOURCE_DETAIL_REQUEST,
  DISCOVERY_SOURCE_DETAIL_SUCCESS,
  DISCOVERY_SOURCE_DETAIL_FAILURE
} from '../../actions/discoverySourceActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    isDeleting: false,
    data: [],
    selected: {},
    dataDetail: {},
    sourceData: {},
    errors: [],
    filters: {
      page: 1
    }
  },
  action
) {
  switch (action.type) {
    case sourceActions.DISCOVERYSOURCE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case sourceActions.DISCOVERYSOURCE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case sourceActions.DISCOVERYSOURCE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case DISCOVERY_SOURCE_RESET:
      return Object.assign({}, state, { data: {}, dataDetail: {} })

    case DISCOVERY_SOURCE_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case DISCOVERY_SOURCE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        dataDetail: action.data
      })

    case DISCOVERY_SOURCE_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case DISCOVERY_SOURCE_DELETE_SUCCESS:
      window.location = '/candidates/sources'
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case SELECT_DISCOVERY_SOURCE:
      return Object.assign({}, state, {
        selected: action.data
      })

    case UPDATE_DISCOVERY_SOURCE_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
