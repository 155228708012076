import React from 'react'

import { Datatable, Column } from '../../../03-components/Datatable'
import {
  addressFormat,
  commentFormat,
  photosExtra,
  inRouteTakenPhotos,
  receivedBy
} from '../../../../utils/formats'

const OrderDestinations = ({ destinations, editDestinationFormat }) => {
  return (
    <div className="panel-body">
      <Datatable className="table table-hover" data={destinations}>
        <Column head="Tarea" field="task_label" />
        <Column head="Estado" field="status_label" />
        <Column head="Contacto" field="contact_person" />
        <Column head="Teléfono" field="phone" />
        <Column
          head="Dirección"
          field="name_destination"
          format={addressFormat}
        />
        <Column head="Descripción" field="description" />
        <Column head="Comentarios" field="status" format={commentFormat} />
        <Column head="Cód. de Ref." field="memo" />
        <Column
          head="Recibido por"
          field="log"
          format={value => receivedBy(value)}
        />
        <Column
          head="Firma y Foto"
          field="photos"
          format={value =>
            value ? inRouteTakenPhotos(value.captured_in_progress) : undefined
          }
        />
        <Column
          head="Fotos extra"
          field="photos"
          format={value =>
            value ? photosExtra(value.captured_extra) : undefined
          }
        />
        <Column
          head="Acción"
          field="number"
          format={value => editDestinationFormat(value)}
        />
      </Datatable>
    </div>
  )
}

export default OrderDestinations
