import React from 'react'

export const OrderPayment = ({
  orderPayment,
  paymentType,
  paymentStatus,
  paymentStatusLabel,
  paymentRequest
}) => {
  
  return (
    <div className="panel-body">
      <div className="col-lg-6">
        {paymentType === 'card' && (
          <>
            {paymentType && paymentStatus && (
              <dl className="dl-horizontal">
                <dt>Tipo:</dt> <dd>{paymentType}</dd>
                <dt>Estado:</dt>{' '}
                <dd>
                  {paymentStatus && paymentStatusLabel
                    ? paymentStatusLabel
                    : 'Pendiente'}
                </dd>
              </dl>
            )}
            {orderPayment && (
              <>
                <dl className="dl-horizontal">
                  <dt>Monto debitado:</dt>{' '}
                  <dd>{orderPayment.amount_debited}</dd>
                  <dt>Monto reembolsado:</dt>{' '}
                  <dd>{orderPayment.amount_refunded}</dd>
                  <dt>Tarjeta:</dt>{' '}
                  <dd>
                    {orderPayment && orderPayment.bankcard
                      ? `${orderPayment.bankcard.card_type} - ${orderPayment.bankcard.number}`
                      : 'No registra'}
                  </dd>
                </dl>
                <div className="activity-stream">
                  {orderPayment.transactions &&
                    orderPayment.transactions.map(order => (
                      <div key={order.created_at} className="stream">
                        <div className="stream-badge">
                          <i className="fa fa-credit-card bg-primary" />
                        </div>
                        <div className="stream-panel">
                          <div className="stream-info">
                            <span>{order.created_at}</span>
                          </div>
                          <p>
                            Estado <strong>{order.gateway_status}</strong> por
                            S/ <strong>{order.amount}</strong>.{' '}
                            {order.response_message}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
            {paymentRequest &&
            paymentRequest.error &&
            paymentStatus === 'failed' ? (
              <dl className="dl-horizontal">
                <dt>Error en la solicitud:</dt>{' '}
                <dd
                  style={{
                    color: '#231A1A',
                    backgroundColor: '#F4B0AF',
                    fontSize: '13px',
                    marginBottom: '20px',
                    border: '1px solid transparent',
                    borderRadius: '4px',
                    boxSizing: 'border-box',
                    textAlign: 'center'
                  }}
                >
                  <i className="fa fa-times-circle" />{' '}
                  {paymentRequest && paymentRequest.error
                    ? paymentRequest.error
                    : 'Fallido'}
                </dd>
              </dl>
            ) : (
              ''
            )}
          </>
        )}
        {paymentType !== 'card' && (
          <dl className="dl-horizontal">
            <dt>Tipo:</dt> <dd>{paymentType}</dd>
            <dt>Estado:</dt>{' '}
            <dd>
              {paymentStatus && paymentStatusLabel
                ? paymentStatusLabel
                : 'Pendiente'}
            </dd>
          </dl>
        )}
      </div>
    </div>
  )
}
