import _ from 'lodash'

import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAILURE,
  UPDATE_ORDER_FILTERS,
  SET_ORDER_ACTION_ACTIVE,
  SELECT_ORDER,
  ORDER_BATCH_DELETE_REQUEST,
  ORDER_BATCH_DELETE_SUCCESS,
  ORDER_BATCH_DELETE_FAILURE,
  ORDER_BATCH_UPDATE_REQUEST,
  ORDER_BATCH_UPDATE_SUCCESS,
  ORDER_BATCH_UPDATE_FAILURE,
  RESET_ORDERLIST,
  GROUP_INDIVIDUAL_ORDERS_REQUEST,
  GROUP_INDIVIDUAL_ORDERS_SUCCESS,
  GROUP_INDIVIDUAL_ORDERS_FAILURE,
  ORDER_STATUS_REASON_OPTIONS_REQUEST,
  ORDER_STATUS_REASON_OPTIONS_SUCCESS,
  ORDER_STATUS_REASON_OPTIONS_FAILURE,
  EDIT_SHIPPING_DOCUMENT_REQUEST,
  EDIT_SHIPPING_DOCUMENT_SUCCESS,
  EDIT_SHIPPING_DOCUMENT_FAILURE
} from '../../actions/orderActions'

import { COURIER_LIST_SUCCESS } from '../../actions/courierActions'

const parseData = data => {
  const newData = { ...data }
  newData.results.forEach(item => {
    if (item.description) {
      item.destinations[1].description = item.description
    }
  })

  return newData
}

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    isUpdating: false,
    isGrouping: false,
    data: {},
    errors: {},
    courierData: {},
    filters: {
      page: 1,
      type: [],
      status: [],
      created_at_0: null,
      created_at_1: null,
      ordering: ''
    },
    activeAction: null,
    selected: [],
    batchConfirmation: '',
    batchErrors: [],
    needRefresh: false,
    statusReasonOptions: {}
  },
  action
) {
  let newSelection
  if (action.type === SELECT_ORDER) {
    newSelection = _.xorBy(state.selected, [action.data], 'id')
  }
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case ORDER_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: parseData(action.data)
      })

    case ORDER_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case COURIER_LIST_SUCCESS:
      return Object.assign({}, state, {
        courierData: action.data
      })

    case UPDATE_ORDER_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    case SET_ORDER_ACTION_ACTIVE:
      let newState = action.data
        ? {
            activeAction: action.data
          }
        : {
            batchConfirmation: '',
            batchErrors: [],
            errors: {},
            activeAction: null
          }
      return Object.assign({}, state, newState)

    case SELECT_ORDER:
      return Object.assign({}, state, {
        selected: newSelection,
        activeAction: newSelection.length > 0 ? state.activeAction : null
      })

    case ORDER_BATCH_DELETE_REQUEST:
      return Object.assign({}, state, {
        isDeleting: true
      })

    case ORDER_BATCH_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        selected: [],
        activeAction: null,
        needRefresh: true
      })

    case ORDER_BATCH_DELETE_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false
      })

    case ORDER_BATCH_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isUpdating: true
      })

    case ORDER_BATCH_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        selected: [],
        activeAction: null,
        needRefresh: true
      })

    case ORDER_BATCH_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false,
        batchErrors: action.errors
      })

    case RESET_ORDERLIST:
      return Object.assign({}, state, {
        data: {},
        filters: {
          page: 1,
          type: [1, 2, 3],
          status: [
            'searching',
            'accepted',
            'in_progress',
            'ended',
            'cancelled',
            'no_courier'
          ],
          created_at_0: null,
          created_at_1: null,
          ordering: '',
          has_failed: null,
          batchConfirmation: '',
          batchErrors: []
        }
      })

    case GROUP_INDIVIDUAL_ORDERS_REQUEST:
      return Object.assign({}, state, {
        isGrouping: true
      })
    case GROUP_INDIVIDUAL_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        isGrouping: false,
        batchConfirmation: action.data,
        batchErrors: []
      })
    case GROUP_INDIVIDUAL_ORDERS_FAILURE:
      return Object.assign({}, state, {
        isGrouping: false,
        batchConfirmation: '',
        batchErrors: action.errors.non_field_errors || action.errors.detail
      })

    case ORDER_STATUS_REASON_OPTIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        batchErrors: [],
        needRefresh: false
      })

    case ORDER_STATUS_REASON_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        statusReasonOptions: action.data
      })

    case ORDER_STATUS_REASON_OPTIONS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    case EDIT_SHIPPING_DOCUMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case EDIT_SHIPPING_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: true
      })

    case EDIT_SHIPPING_DOCUMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false
      })

    default:
      return state
  }
}
