import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const MERCHANT_LIST_REQUEST = 'MERCHANT_LIST_REQUEST'
export const MERCHANT_LIST_SUCCESS = 'MERCHANT_LIST_SUCCESS'
export const MERCHANT_LIST_FAILURE = 'MERCHANT_LIST_FAILURE'
export const MERCHANT_UPDATE_REQUEST = 'MERCHANT_UPDATE_REQUEST'
export const MERCHANT_UPDATE_SUCCESS = 'MERCHANT_UPDATE_SUCCESS'
export const MERCHANT_UPDATE_FAILURE = 'MERCHANT_UPDATE_FAILURE'

export const MERCHANTTYPE_LIST_REQUEST = 'MERCHANTTYPE_LIST_REQUEST'
export const MERCHANTTYPE_LIST_SUCCESS = 'MERCHANTTYPE_LIST_SUCCESS'
export const MERCHANTTYPE_LIST_FAILURE = 'MERCHANTTYPE_LIST_FAILURE'

export function merchantList(params = {}) {
  let url = 'merchant/list/'

  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      storeAPI: true,
      types: [
        MERCHANT_LIST_REQUEST,
        MERCHANT_LIST_SUCCESS,
        MERCHANT_LIST_FAILURE
      ]
    }
  }
}

export function editMerchant(id, data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `merchant/update/${id}/`,
      authenticated: true,
      storeAPI: true,
      data,
      types: [
        MERCHANT_UPDATE_REQUEST,
        MERCHANT_UPDATE_SUCCESS,
        MERCHANT_UPDATE_FAILURE
      ]
    }
  }
}

export function listMerchantType() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `merchant-type/`,
      authenticated: false,
      storeAPI: true,
      types: [
        MERCHANTTYPE_LIST_REQUEST,
        MERCHANTTYPE_LIST_SUCCESS,
        MERCHANTTYPE_LIST_FAILURE
      ]
    }
  }
}
