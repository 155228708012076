export const permissionLevels = {
  CREATE: 'add',
  READ: 'view',
  UPDATE: 'change',
  DELETE: 'delete'
}

export const defaultMapCenter = [-12.090877, -77.022773]

export const ORDER_STATUS = {
  CREATED: 'created',
  SEARCHING: 'searching',
  ACCEPTED: 'accepted',
  IN_PROGRESS: 'in_progress',
  ENDED: 'ended',

  /* Logic status */
  NO_COURIER: 'no_courier',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  COMPLETED: 'completed',
  PARTIAL_COMPLETED: 'partial_completed'
}

export const DESTINATION_STATUS = {
  CREATED: 'created',
  STARTED: 'started',
  ARRIVED: 'arrived',
  FAILED: 'failed',
  COMPLETED: 'completed'
}

export const GROUP_STATUS = {
  CREATED: 'created',
  ACCEPTED: 'accepted',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed'
}
