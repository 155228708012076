import { CALL_API } from '../middleware/api'

export const ORGANIZATION_CONTRACT_REQUEST = 'ORGANIZATION_CONTRACT_REQUEST'
export const ORGANIZATION_CONTRACT_SUCCESS = 'ORGANIZATION_CONTRACT_SUCCESS'
export const ORGANIZATION_CONTRACT_FAILURE = 'ORGANIZATION_CONTRACT_FAILURE'

export const EDIT_ORGANIZATION_CONTRACT_REQUEST =
  'EDIT_ORGANIZATION_CONTRACT_REQUEST'
export const EDIT_ORGANIZATION_CONTRACT_SUCCESS =
  'EDIT_ORGANIZATION_CONTRACT_SUCCESS'
export const EDIT_ORGANIZATION_CONTRACT_FAILURE =
  'EDIT_ORGANIZATION_CONTRACT_FAILURE'

export function getOrganizationContracts() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'organization_contract/',
      authenticated: true,
      types: [
        ORGANIZATION_CONTRACT_REQUEST,
        ORGANIZATION_CONTRACT_SUCCESS,
        ORGANIZATION_CONTRACT_FAILURE
      ]
    }
  }
}

export function getOrganizationContractsById(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `organization_contract/${id}/`,
      authenticated: true,
      types: [
        EDIT_ORGANIZATION_CONTRACT_REQUEST,
        EDIT_ORGANIZATION_CONTRACT_SUCCESS,
        EDIT_ORGANIZATION_CONTRACT_FAILURE
      ]
    }
  }
}
