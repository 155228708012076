import React from 'react'
import Dropzone from 'react-dropzone'
import IconCard from '../02-molecules/IconCard'

const InputJSONFileForm = ({ inputProps }) => {
  const [accepted, setAccepted] = React.useState(null)
  const [readText, setReadText] = React.useState('')
  const [, setRejected] = React.useState(null)

  // TODO: try/catch para validacion de tipo de documento ('json') -> onDrop
  return (
    <section>
      <div className="dropzone" style={{ width: '100%', marginBottom: '1em' }}>
        <Dropzone
          type="file"
          accept=".geojson"
          style={{
            width: '100% important',
            padding: '4em',
            textAlign: 'center',
            border: '2px dashed #c9c9c9',
            color: 'gray'
          }}
          onDrop={async ([file]) => {
            let reader = new FileReader()
            try {
              reader.onload = function(e) {
                let contents = JSON.parse(e.target.result)
                inputProps.onChange(contents)
                setAccepted(contents)
              }
              reader.readAsText(file)
              setReadText(file)
            } catch (error) {
              console.log(
                'There are errors in the data. Please drop a correct file.'
              )
              console.log(error.message)
              setRejected(error)
            }
          }}
        >
          <div>
            <IconCard size="4em" color="gray" type="uploadFile" />
          </div>
          <p style={{ maxWidth: '320px', margin: '0 auto' }}>
            Seleccione un archivo desde su computadora o arrástrelo aquí.
          </p>
          {accepted ? (
            <div className="u-mt20" style={{ color: 'rgb(95, 162, 240)' }}>
              <span
                style={{
                  marginTop: '1em'
                }}
              >
                Archivo cargado
              </span>
              <br />
              {readText.name}
            </div>
          ) : null}
        </Dropzone>
      </div>
      <aside>
        <span style={{ fontSize: ' 1em', fontStyle: 'oblique' }}>
          El archivo debe ser cargado con la extensión: *.geojson
        </span>
      </aside>
    </section>
  )
}

export default InputJSONFileForm
