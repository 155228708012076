import React from 'react'
import Dropzone from 'react-dropzone'

import IconCard from './IconCard'

const FileInputForm = ({ inputProps }) => {
  const [accepted, setAccepted] = React.useState([])
  const [, setRejected] = React.useState([])

  return (
    <section>
      <div className="dropzone" style={{ width: '100%', marginBottom: '1em' }}>
        <Dropzone
          multiple={false}
          accept=".csv, .xlsx"
          style={{
            width: '100% important',
            padding: '4em',
            textAlign: 'center',
            border: '2px dashed #c9c9c9',
            color: 'gray'
          }}
          onDrop={(acc, rej) => {
            inputProps.onChange(acc)
            setAccepted(acc)
            setRejected(rej)
          }}
        >
          <div>
            <IconCard size="4em" color="gray" type="uploadFile" />
          </div>
          <p style={{ maxWidth: '320px', margin: '0 auto' }}>
            Seleccione un archivo desde su computadora o arrástrelo aquí.
          </p>
          {accepted.length ? (
            <div className="u-mt20" style={{ color: 'rgb(95, 162, 240)' }}>
              <span
                style={{
                  marginTop: '1em'
                }}
              >
                Archivos cargados
              </span>
              <br />
              {accepted.map(f => (
                <span key={f.name}>
                  {f.name} - {parseInt(f.size / 1000, 10)} kB
                </span>
              ))}
            </div>
          ) : null}
        </Dropzone>
      </div>
      <aside>
        <span style={{ fontSize: ' 1em', fontStyle: 'oblique' }}>
          El archivo debe ser cargado con las siguientes extensiones: *.csv y
          *.xlsx.
        </span>
      </aside>
    </section>
  )
}

export default FileInputForm
