import React from 'react'

const IconOption = ({ data }) => {
  if (data) {
    return <i className="fa fa-check" />
  }
  return <i className="fa fa-times" />
}

export default IconOption
