import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const SALE_LIST_REQUEST = 'SALE_LIST_REQUEST'
export const SALE_LIST_SUCCESS = 'SALE_LIST_SUCCESS'
export const SALE_LIST_FAILURE = 'SALE_LIST_FAILURE'

export const SALE_ORDER_STATUS_REQUEST = 'SALE_ORDER_STATUS_REQUEST'
export const SALE_ORDER_STATUS_SUCCESS = 'SALE_ORDER_STATUS_SUCCESS'
export const SALE_ORDER_STATUS_FAILURE = 'SALE_ORDER_STATUS_FAILURE'

export const UPDATE_SALE_FILTERS = 'UPDATE_SALE_FILTERS'

export function saleList(params = {}) {
  let url = 'admin/order/'

  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      storeAPI: true,
      types: [SALE_LIST_REQUEST, SALE_LIST_SUCCESS, SALE_LIST_FAILURE]
    }
  }
}

export function saleOrderStatus() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'admin/order/status/',
      authenticated: true,
      storeAPI: true,
      types: [
        SALE_ORDER_STATUS_REQUEST,
        SALE_ORDER_STATUS_SUCCESS,
        SALE_ORDER_STATUS_FAILURE
      ]
    }
  }
}

export function updateFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_SALE_FILTERS,
      data
    }
    dispatch(action)
  }
}
