import {
  COLLECTION_CREATE_REQUEST,
  COLLECTION_CREATE_FAILURE,
  COLLECTION_CREATE_SUCCESS,
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
  COLLECTION_LIST_FAILURE,
  UPDATE_COLLECTION_FILTERS,
  SELECT_COURIER_COLLECTION,
  COLLECTION_PAYMENT_SUCCESS,
  COLLECTIONPENDING_DELETE_REQUEST,
  COLLECTIONPENDING_DELETE_FAILURE,
  COLLECTIONPENDING_DELETE_SUCCESS
} from '../../actions/collectionActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: [],
    selected: {},
    ordering: '',
    filters: {
      courier: '',
      status: 'pending'
    },
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case COLLECTIONPENDING_DELETE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case COLLECTIONPENDING_DELETE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data || action.errors
      })
    case COLLECTIONPENDING_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        needRefresh: true
      })

    case COLLECTION_CREATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case COLLECTION_CREATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data || action.errors
      })
    case COLLECTION_CREATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        needRefresh: true
      })
    case COLLECTION_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case COLLECTION_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case COLLECTION_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case UPDATE_COLLECTION_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    case SELECT_COURIER_COLLECTION:
      return Object.assign({}, state, {
        selected: action.data,
        needRefresh: true
      })
    case COLLECTION_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        needRefresh: true
      })

    default:
      return state
  }
}
