import $ from 'jquery'
import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import { correctHeight, detectBody } from '../components/00-helpers/Helpers'
import Progress from '../components/00-helpers/Progress'
import Navigation from '../components/03-components/Navigation'
import Footer from '../components/02-molecules/Footer'
import TopHeader from '../components/03-components/TopHeader'

import DashboardContainer from './DashboardContainer'

import { permissionLevels } from '../utils/variables'
import { hasPermission } from '../utils'

const AsyncOrderContainer = React.lazy(() => import('./OrderContainer'))
const AsyncAdminsContainer = React.lazy(() => import('./AdminsContainer'))
const AsyncMyNetworkContainer = React.lazy(() =>
  import('./MyNetworkContainer')
)
const AsyncClientContainer = React.lazy(() => import('./ClientContainer'))
const AsyncCourierContainer = React.lazy(() => import('./CourierContainer'))
const AsyncInvoiceContainer = React.lazy(() => import('./InvoiceContainer'))
const AsyncCandidateContainer = React.lazy(() =>
  import('./CandidateContainer')
)
const AsyncPurseContainer = React.lazy(() => import('./PurseContainer'))
const AsyncNotificationContainer = React.lazy(() =>
  import('./NotificationContainer')
)
const AsyncMerchantContainer = React.lazy(() => import('./MerchantContainer'))
const AsyncStorageContainer = React.lazy(() => import('./StorageContainer'))
const AsyncOrderRoutingContainer = React.lazy(() =>
  import('./OrderRoutingContainer')
)
const AsyncOrderTypesContainer = React.lazy(() =>
  import('./OrderTypesContainer')
)
const AsyncConfigurationContainer = React.lazy(() =>
  import('./ConfigurationContainer')
)
const AsyncVehicleTypesContainer = React.lazy(() =>
  import('./VehicleTypesContainer')
)
const AsyncCoverageContainer = React.lazy(() => import('./CoverageContainer'))

class MainLayout extends React.Component {
  componentDidMount() {
    // Run correctHeight function on load and resize window event
    $(window).bind('load resize', () => {
      correctHeight()
      detectBody()
    })

    // Correct height of wrapper after metisMenu animation.
    $('.metismenu a').click(() => {
      setTimeout(() => {
        correctHeight()
      }, 300)
    })
  }

  componentWillReceiveProps(nextProps) {
    const { history } = this.props
    if (!nextProps.isAuthenticated) history.push('/login')
  }

  generateContainer = (callback, action, props) =>
    callback({ actionToPage: action }, props)

  render() {
    const { location } = this.props
    const wrapperClass = `gray-bg ${location.pathname}`
    return (
      <div id="wrapper">
        <Progress />
        <Navigation location={location} />
        <div id="page-wrapper" className={wrapperClass}>
          <TopHeader />
          <Switch>
            {hasPermission('dashboard', permissionLevels.READ) && (
              <Route path="/dashboard" component={DashboardContainer} />
            )}
            <Route path="/admins" component={AsyncAdminsContainer} />
            <Route path="/my-network" component={AsyncMyNetworkContainer} />
            <Route path="/groups" component={AsyncOrderRoutingContainer} />
            <Route path="/orders" component={AsyncOrderContainer} />
            <Route path="/clients" component={AsyncClientContainer} />
            <Route
              path="/vehicle-types"
              component={AsyncVehicleTypesContainer}
            />
            <Route path="/order-types" component={AsyncOrderTypesContainer} />
            <Route path="/couriers" component={AsyncCourierContainer} />
            <Route path="/merchants" component={AsyncMerchantContainer} />
            <Route path="/inventory" component={AsyncStorageContainer} />
            <Route path="/invoices" component={AsyncInvoiceContainer} />
            <Route path="/candidates" component={AsyncCandidateContainer} />
            <Route
              path="/notifications"
              component={AsyncNotificationContainer}
            />
            <Route path="/purses" component={AsyncPurseContainer} />
            <Route
              path="/configuration"
              component={AsyncConfigurationContainer}
            />
            <Route path="/coverages" component={AsyncCoverageContainer} />
            <Redirect from="/" to="/orders" />
          </Switch>
          <Footer />
        </div>
      </div>
    )
  }
}

export default connect(state => state.login)(MainLayout)
