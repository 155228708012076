import {
  CLIENT_VEHICLE_LIST_REQUEST,
  CLIENT_VEHICLE_LIST_SUCCESS,
  CLIENT_VEHICLE_LIST_FAILURE
} from '../../actions/clientVehicleActions'

export default function reducer(
  state = {
    isFetching: false,
    data: {},
    errors: {},
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case CLIENT_VEHICLE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        data: {}
      })

    case CLIENT_VEHICLE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case CLIENT_VEHICLE_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    default:
      return state
  }
}
