import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// CLIENT_TYPE GENERAL INFORMATION
export const CLIENT_TYPE_LIST_REQUEST = 'CLIENT_TYPE_LIST_REQUEST'
export const CLIENT_TYPE_LIST_SUCCESS = 'CLIENT_TYPE_LIST_SUCCESS'
export const CLIENT_TYPE_LIST_FAILURE = 'CLIENT_TYPE_LIST_FAILURE'

export const RESET_CLIENT_TYPE = 'RESET_CLIENT_TYPE'

export function clientTypeList(params = {}) {
  let url = 'api/client_type/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      isPublic: true,
      extra: params,
      types: [
        CLIENT_TYPE_LIST_REQUEST,
        CLIENT_TYPE_LIST_SUCCESS,
        CLIENT_TYPE_LIST_FAILURE
      ]
    }
  }
}

export function resetClientType() {
  return dispatch => {
    const action = {
      type: RESET_CLIENT_TYPE
    }
    dispatch(action)
  }
}
