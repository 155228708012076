import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// INVOICE GENERAL INFORMATION
export const INVOICE_LIST_REQUEST = 'INVOICE_LIST_REQUEST'
export const INVOICE_LIST_SUCCESS = 'INVOICE_LIST_SUCCESS'
export const INVOICE_LIST_FAILURE = 'INVOICE_LIST_FAILURE'

export const CREDITNOTE_CREATE_REQUEST = 'CREDITNOTE_CREATE_REQUEST'
export const CREDITNOTE_CREATE_SUCCESS = 'CREDITNOTE_CREATE_SUCCESS'
export const CREDITNOTE_CREATE_FAILURE = 'CREDITNOTE_CREATE_FAILURE'

// INVOICE DETAIL INFORMATION
export const INVOICE_DETAIL_REQUEST = 'INVOICE_DETAIL_REQUEST'
export const INVOICE_DETAIL_SUCCESS = 'INVOICE_DETAIL_SUCCESS'
export const INVOICE_DETAIL_FAILURE = 'INVOICE_DETAIL_FAILURE'

// INVOICE EDIT NUMBER
export const INVOICE_EDIT_REQUEST = 'INVOICE_EDIT_REQUEST'
export const INVOICE_EDIT_SUCCESS = 'INVOICE_EDIT_SUCCESS'
export const INVOICE_EDIT_FAILURE = 'INVOICE_EDIT_FAILURE'

// INVOICE UPDATE DATA
export const UPDATE_INVOICE_FILTERS = 'UPDATE_INVOICE_FILTERS'
export const INVOICE_UPDATE_REQUEST = 'INVOICE_UPDATE_REQUEST'
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS'
export const INVOICE_UPDATE_FAILURE = 'INVOICE_UPDATE_FAILURE'

// INVOICE DELETE DATA
export const SET_INVOICE_ACTION_ACTIVE = 'SET_INVOICE_ACTION_ACTIVE'
export const INVOICE_DELETE_REQUEST = 'INVOICE_DELETE_REQUEST'
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS'
export const INVOICE_DELETE_FAILURE = 'INVOICE_DELETE_FAILURE'

// CREATE INVOICE DATA
export const INVOICE_CREATE_REQUEST = 'INVOICE_CREATE_REQUEST'
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS'
export const INVOICE_CREATE_FAILURE = 'INVOICE_CREATE_FAILURE'

export const SELECT_INVOICE = 'SELECT_INVOICE'
export const SELECT_ONE_INVOICE = 'SELECT_ONE_INVOICE'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const RESET_INVOICE = 'RESET_INVOICE'
export const UPDATE_INVOICEDETAIL_FILTERS = 'UPDATE_INVOICEDETAIL_FILTERS'

export const INVOICE_CONFIRM_REQUEST = 'INVOICE_CONFIRM_REQUEST'
export const INVOICE_CONFIRM_SUCCESS = 'INVOICE_CONFIRM_SUCCESS'
export const INVOICE_CONFIRM_FAILURE = 'INVOICE_CONFIRM_FAILURE'

export const INVOICE_CONFIRMAGAIN_REQUEST = 'INVOICE_CONFIRMAGAIN_REQUEST'
export const INVOICE_CONFIRMAGAIN_SUCCESS = 'INVOICE_CONFIRMAGAIN_SUCCESS'
export const INVOICE_CONFIRMAGAIN_FAILURE = 'INVOICE_CONFIRMAGAIN_FAILURE'

// Re-Invoicing
export const INVOICE_AGAIN_REQUEST = 'INVOICE_AGAIN_REQUEST'
export const INVOICE_AGAIN_SUCCESS = 'INVOICE_AGAIN_SUCCESS'
export const INVOICE_AGAIN_FAILURE = 'INVOICE_AGAIN_FAILURE'

export const UPDATE_CREDIT_NOTE_STATUS_REQUEST =
  'UPDATE_CREDIT_NOTE_STATUS_REQUEST'
export const UPDATE_CREDIT_NOTE_STATUS_SUCCESS =
  'UPDATE_CREDIT_NOTE_STATUS_SUCCESS'
export const UPDATE_CREDIT_NOTE_STATUS_FAILURE =
  'UPDATE_CREDIT_NOTE_STATUS_FAILURE'

export function invoiceList(params = {}) {
  let url = 'invoices/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [INVOICE_LIST_REQUEST, INVOICE_LIST_SUCCESS, INVOICE_LIST_FAILURE]
    }
  }
}

export function fnInvoiceDetail(params = {}, id) {
  let url = `invoices/${id}/`
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        INVOICE_DETAIL_REQUEST,
        INVOICE_DETAIL_SUCCESS,
        INVOICE_DETAIL_FAILURE
      ]
    }
  }
}

export function updateInvoiceFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_INVOICE_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function updateInvoiceDetailFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_INVOICEDETAIL_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function selectInvoice(obj) {
  return dispatch => {
    const action = {
      type: SELECT_INVOICE,
      data: obj
    }
    dispatch(action)
  }
}
export function selectOneInvoice(invoice) {
  return dispatch => {
    const action = {
      type: SELECT_ONE_INVOICE,
      data: invoice
    }
    dispatch(action)
  }
}

export function showMessage() {
  return dispatch => {
    const action = {
      type: SHOW_MESSAGE
    }
    dispatch(action)
  }
}

export function hideMessage() {
  return dispatch => {
    const action = {
      type: HIDE_MESSAGE
    }
    dispatch(action)
  }
}

export function invoiceEditNumber(id, data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `invoices/${id}/summary/`,
      authenticated: true,
      localAPI: false,
      data,
      types: [INVOICE_EDIT_REQUEST, INVOICE_EDIT_SUCCESS, INVOICE_EDIT_FAILURE]
    }
  }
}

export function invoiceDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `formulas/${id}/`,
      authenticated: true,
      localAPI: false,
      types: [
        INVOICE_DELETE_REQUEST,
        INVOICE_DELETE_SUCCESS,
        INVOICE_DELETE_FAILURE
      ]
    }
  }
}

export function resetInvoice() {
  return dispatch => {
    const action = {
      type: RESET_INVOICE
    }
    dispatch(action)
  }
}

export function createInvoice(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `invoices/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        INVOICE_CREATE_REQUEST,
        INVOICE_CREATE_SUCCESS,
        INVOICE_CREATE_FAILURE
      ]
    }
  }
}

export function confirmPaymentInvoice(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `invoices/${data.id}/`,
      authenticated: true,
      data,
      types: [
        INVOICE_CONFIRM_REQUEST,
        INVOICE_CONFIRM_SUCCESS,
        INVOICE_CONFIRM_FAILURE
      ]
    }
  }
}

export function confirmAgainInvoice(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `invoices/${data.id}/generate/`,
      authenticated: true,
      data,
      types: [
        INVOICE_CONFIRMAGAIN_REQUEST,
        INVOICE_CONFIRMAGAIN_SUCCESS,
        INVOICE_CONFIRMAGAIN_FAILURE
      ]
    }
  }
}

export function updateCreditNoteStatus(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `invoice_notes/${data.id}/cdr_status/`,
      authenticated: true,
      data,
      types: [
        UPDATE_CREDIT_NOTE_STATUS_REQUEST,
        UPDATE_CREDIT_NOTE_STATUS_SUCCESS,
        UPDATE_CREDIT_NOTE_STATUS_FAILURE
      ]
    }
  }
}

export function generateCreditNote(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `credit_notes/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        CREDITNOTE_CREATE_REQUEST,
        CREDITNOTE_CREATE_SUCCESS,
        CREDITNOTE_CREATE_FAILURE
      ]
    }
  }
}

export function invoiceAgain(data) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `invoices/${data.id}/reinvoice/`,
      authenticated: true,
      data,
      types: [
        INVOICE_AGAIN_REQUEST,
        INVOICE_AGAIN_SUCCESS,
        INVOICE_AGAIN_FAILURE
      ]
    }
  }
}
