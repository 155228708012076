import couponActions, {
  UPDATE_COUPON_FILTERS,
  RESET_COUPON
} from '../../actions/couponActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    data: [],
    dataDetail: {},
    couponData: {},
    errors: [],
    filters: {
      search: '',
      page: 1,
      is_active: true
    }
  },
  action
) {
  switch (action.type) {
    case couponActions.COUPON_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case couponActions.COUPON_LIST_FAILED:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })
    case couponActions.COUPON_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })
    case couponActions.COUPON_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: true
      })

    case RESET_COUPON:
      return Object.assign({}, state, {
        data: {},
        dataDetail: {}
      })

    case couponActions.COUPON_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case couponActions.COUPON_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        dataDetail: action.data
      })

    case couponActions.COUPON_CREATE_SUCCESS:
      window.location = `/configuration/coupons/${action.data.id}`
      return Object.assign({}, state, {
        isFetching: false,
        couponData: action.data
      })
    case couponActions.COUPON_UPDATE_SUCCESS:
      window.location = `/configuration/coupons`
      return Object.assign({}, state, {
        isFetching: false,
        couponData: {}
      })
    case couponActions.COUPON_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_COUPON_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
