import { CALL_API } from '../middleware/api'

export const GET_GLOBAL_CONFIG_REQUEST = 'GET_GLOBAL_CONFIG_REQUEST'
export const GET_GLOBAL_CONFIG_SUCCESS = 'GET_GLOBAL_CONFIG_SUCCESS'
export const GET_GLOBAL_CONFIG_FAILURE = 'GET_GLOBAL_CONFIG_FAILURE'

export const SAVE_GLOBAL_CONFIG_REQUEST = 'SAVE_GLOBAL_CONFIG_REQUEST'
export const SAVE_GLOBAL_CONFIG_SUCCESS = 'SAVE_GLOBAL_CONFIG_SUCCESS'
export const SAVE_GLOBAL_CONFIG_FAILURE = 'SAVE_GLOBAL_CONFIG_FAILURE'

export function getGlobalConfiguration() {
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: `configuration/`,
      authenticated: true,
      types: [
        GET_GLOBAL_CONFIG_REQUEST,
        GET_GLOBAL_CONFIG_SUCCESS,
        GET_GLOBAL_CONFIG_FAILURE
      ]
    }
  }
}

export function saveGlobalConfiguration(data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      localAPI: false,
      endpoint: 'configuration/',
      authenticated: true,
      data,
      types: [
        SAVE_GLOBAL_CONFIG_REQUEST,
        SAVE_GLOBAL_CONFIG_SUCCESS,
        SAVE_GLOBAL_CONFIG_FAILURE
      ]
    }
  }
}
