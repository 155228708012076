import React from 'react'
import { FormGroup, FormControl } from 'react-bootstrap'

function DaySelector({ options, input, ...rest }) {
  const handleSelectChange = event => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      option => option.value
    )
    input.onChange(selectedOptions)
  }

  const getTranslatedDay = option => {
    switch (option) {
      case 'monday':
        return 'Lunes'
      case 'tuesday':
        return 'Martes'
      case 'wednesday':
        return 'Miércoles'
      case 'thursday':
        return 'Jueves'
      case 'friday':
        return 'Viernes'
      case 'saturday':
        return 'Sábado'
      case 'sunday':
        return 'Domingo'
      default:
        return option
    }
  }

  return (
    <div>
      <FormGroup>
        <FormControl
          componentClass="select"
          multiple
          onChange={handleSelectChange}
          {...rest}
        >
          {options.map((option, index) => (
            <option key={index} value={option}>
              {getTranslatedDay(option)}
            </option>
          ))}
        </FormControl>
      </FormGroup>
      <span style={{ fontSize: '11px' }}>
        Mantenga presionado "Control" o "Command" en un Mac, para seleccionar
        más de una opción.
      </span>
    </div>
  )
}

export default DaySelector
