import {
  COURIER_REPORT_REQUEST,
  COURIER_REPORT_SUCCESS,
  COURIER_REPORT_FAILURE,
  UPDATE_COURIERREPORT_FILTERS
} from '../../actions/reportsActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    errors: {},
    filters: {
      page: 1
    },
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case COURIER_REPORT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case COURIER_REPORT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case COURIER_REPORT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case UPDATE_COURIERREPORT_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    default:
      return state
  }
}
