import React from 'react'

const OrderInformation = ({
  paymentType,
  duration,
  kmDistance,
  description,
  couponCode,
  extraData,
  cost,
  url,
  memo,
  batchMemo,
  shippingDocument
}) => {
  const trackingUrl = url => (
    <a
      className="u-ellipsis"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {url}
    </a>
  )

  return (
    <div className="panel-body">
      <div className="col-lg-12" style={{ columnCount: 2 }}>
        <dl className="dl-horizontal">
          <dt>Medio de pago:</dt> <dd>{paymentType}</dd>
          <dt>Duración:</dt> <dd>{duration}</dd>
          <dt>Kilómetros:</dt> <dd>{kmDistance}</dd>
          {description ? (
            <React.Fragment>
              <dt>Descripción:</dt>
              <dd> {description}</dd>
            </React.Fragment>
          ) : null}
          {couponCode ? (
            <React.Fragment>
              <dt>Cupón:</dt>
              <dd> {couponCode}</dd>
            </React.Fragment>
          ) : null}
          {extraData && Object.keys(extraData).length ? (
            <React.Fragment>
              <dt style={{ marginTop: '10px' }}>Campos Extra</dt>
              <dd />
              {Object.keys(extraData).map((activity, index) => (
                <React.Fragment key={index}>
                  <dt>{activity}:</dt>
                  <dd>{extraData[activity]}</dd>
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : null}
          <dt>Costo:</dt> <dd>{cost}</dd>
          <dt>Código de Referencia:</dt> <dd>{memo}</dd>
          <dt>Tracking público:</dt> <dd>{trackingUrl(url)}</dd>
          <dt>Memo de Ruta:</dt> <dd>{batchMemo}</dd>
          <dt>Guía de Remisión:</dt> <dd>{shippingDocument}</dd>
        </dl>
      </div>
    </div>
  )
}

export default OrderInformation
