import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import * as moment from 'moment'

import Spinner from '../01-atoms/SpinnerLoader/Spinner'
import { clientName, kmDistanceFormat, priceFormat } from '../../utils/formats'

export const ConfirmModal = ({ body, onConfirm, onCancel, showModal }) => {
  return (
    <Modal show={showModal} onHide={onCancel}>
      <Modal.Body>
        <div className="form-group row">
          <div className="text-center">
            <h4>{body}</h4>
            <div className="col-sm-12">
              <button
                type="button"
                onClick={onCancel}
                className="btn btn-white"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={onConfirm}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const EditDestinationForm = ({
  statusReasonOptions,
  selectDestinationStatus,
  handleSubmit,
  selectedStatus,
  handleChangeStatus,
  onCancelSelection,
  failedReason,
  handleChangeFailedReason,
  otherReason,
  handleChangeOtherReason
}) => {
  return (
    <div className="col-lg-6 col-lg-offset-3">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <select
            className="form-control mb"
            name="selectedStatus"
            value={selectedStatus}
            onChange={handleChangeStatus}
          >
            {selectDestinationStatus.map((el, index) => (
              <option key={index} value={el.value}>
                {el.label}
              </option>
            ))}
          </select>
          {selectedStatus === 'failed' ? (
            <React.Fragment>
              <div>
                <select
                  className="form-control mb"
                  required
                  onChange={handleChangeFailedReason}
                >
                  <option value="">Seleccione una razón</option>
                  {statusReasonOptions &&
                  statusReasonOptions.status_reason &&
                  statusReasonOptions.status_reason.length ? (
                    <React.Fragment>
                      {statusReasonOptions?.status_reason?.map(el => (
                        <option key={el.id} value={el.description}>
                          {el.description}
                        </option>
                      ))}
                      {statusReasonOptions?.allow_optional_fail_reason && (
                        <option value="Otros">Otros</option>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <option value="Problema con la dirección">
                        Problema con la dirección
                      </option>
                      <option value="El cliente no está">
                        El cliente no está
                      </option>
                      <option value="No quiere el producto">
                        No quiere el producto
                      </option>
                      <option value="Otros">Otros</option>
                    </React.Fragment>
                  )}
                </select>
                {failedReason === 'Otros' && (
                  <input
                    className="form-control mb"
                    type="text"
                    placeholder="Indicar motivo"
                    value={otherReason}
                    onChange={handleChangeOtherReason}
                    required
                  />
                )}
              </div>
            </React.Fragment>
          ) : null}
          <div className="input-group-btn">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!selectedStatus}
            >
              Cambiar
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={onCancelSelection}
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export const ConsultPriceModal = ({
  priceDetails,
  showReturnModal,
  isConformable,
  isLoading,
  handleCloseRenderPriceModal,
  handleCheckboxChange,
  submitOrderAddReturn
}) => {
  if (!priceDetails) return null

  return (
    <Modal show={showReturnModal} onHide={handleCloseRenderPriceModal}>
      {isLoading ? (
        ''
      ) : (
        <Modal.Header closeButton>
          <Modal.Title>Cálculo de Precio para Retorno</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px'
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div>
            {priceDetails && (
              <div>
                <p>
                  <strong>Vehículo: </strong>{' '}
                  {priceDetails.prices[0].vehicle_type_name}
                </p>
                <p>
                  <strong>Distancia:</strong>{' '}
                  {kmDistanceFormat(priceDetails.distance)}
                </p>
                <b />
                <p>
                  <strong>Precio retorno: </strong>{' '}
                  {priceFormat(priceDetails.prices[0].return_price)}
                </p>
                <p>
                  <strong>Precio total: </strong>{' '}
                  {priceFormat(priceDetails.prices[0].price)}
                </p>
              </div>
            )}
            <div>
              <input
                type="checkbox"
                style={{ marginRight: '5px' }}
                checked={isConformable}
                onChange={handleCheckboxChange}
              />
              <label>¿El cliente se encuentra conforme con este cambio?</label>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-white"
          onClick={handleCloseRenderPriceModal}
        >
          Cerrar
        </button>
        <button
          disabled={!isConformable || isLoading}
          onClick={submitOrderAddReturn}
          className="btn btn-primary"
        >
          Agregar Retorno
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export const RefOrderModal = ({
  onSearchOrder,
  onConfirm,
  onCancel,
  showModal,
  orderDetails,
  error,
  orderCode,
  setOrderCode,
  processStatus
}) => {
  const [selectedOrderCode, setSelectedOrderCode] = useState(null)
  const [showProcessMessage, setShowProcessMessage] = useState(false)

  useEffect(() => {
    if (processStatus === 'processing') {
      setShowProcessMessage(true)
    } else if (processStatus === 'success' || processStatus === 'error') {
      setShowProcessMessage(true)
      const timer = setTimeout(() => setShowProcessMessage(false), 4000)
      return () => clearTimeout(timer)
    }
  }, [processStatus])

  const handleCheckboxClick = code => {
    setSelectedOrderCode(code)
  }

  const handleInputChange = e => {
    setOrderCode(e.target.value)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onSearchOrder(orderCode)
    }
  }

  const handleOnConfirm = async () => {
    if (selectedOrderCode) {
      onConfirm(selectedOrderCode)
    }
  }

  return (
    <Modal show={showModal} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Referenciar Orden</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showProcessMessage && processStatus === 'processing' && (
          <div
            className="text-center"
            style={{ position: 'relative', textAlign: 'center' }}
          >
            <i
              className="fa fa-spinner fa-spin"
              style={{ fontSize: '12em', color: '#50e09e', marginTop: '20px' }}
            />
            <p
              style={{ marginTop: '10px' }}
            >{`Asociando la orden ${orderDetails.code}.`}</p>
          </div>
        )}
        {showProcessMessage && processStatus === 'success' && (
          <div
            className="text-center text-success"
            style={{ position: 'relative', textAlign: 'center' }}
          >
            <i
              className="fa fa-check-circle"
              style={{ fontSize: '12em', color: '#50e09e' }}
            />
            <p style={{ marginTop: '10px' }}>Realizado con éxito.</p>
          </div>
        )}
        {showProcessMessage && processStatus === 'error' && (
          <div
            className="text-center text-danger"
            style={{ position: 'relative', textAlign: 'center' }}
          >
            <i
              className="fa fa-exclamation-circle"
              style={{ fontSize: '12em', color: '#f8ac59' }}
            />
            <p style={{ marginTop: '10px' }}>No se realizó correctamente.</p>
          </div>
        )}
        {!showProcessMessage && !processStatus && (
          <div className="form-group row">
            <div className="text-center">
              <h3>Ingresar y buscar el código de la orden.</h3>
              <div className="col-sm-12">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Ejemplo: EXP-Z4c5wjaA"
                  value={orderCode}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <i className="fa fa-search" />
              </div>
              {error && (
                <span className="text-danger text-center">{error}</span>
              )}
              {orderDetails && !error && (
                <table
                  className="table table-striped"
                  style={{ marginTop: '20px' }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle'
                        }}
                      >
                        Seleccionar
                      </th>
                      <th
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle'
                        }}
                      >
                        Código
                      </th>
                      <th
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle'
                        }}
                      >
                        Fecha creación
                      </th>
                      <th
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle'
                        }}
                      >
                        Cliente
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle'
                        }}
                      >
                        <input
                          type="checkbox"
                          onClick={() =>
                            handleCheckboxClick(orderDetails.code)
                          }
                          checked={selectedOrderCode === orderDetails.code}
                          readOnly
                        />
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle'
                        }}
                      >
                        {orderDetails.code}
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle'
                        }}
                      >
                        {moment(orderDetails.created_at).format('ll')}
                      </td>
                      <td
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle'
                        }}
                      >
                        {clientName(orderDetails.client)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              <div className="col-sm-12">
                <button
                  type="button"
                  onClick={onCancel}
                  className="btn btn-white"
                >
                  Cerrar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleOnConfirm}
                  disabled={!selectedOrderCode || processStatus}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}
