import _ from 'lodash'

import {
  ORDER_INVOICING_REQUEST,
  ORDER_INVOICING_SUCCESS,
  ORDER_INVOICING_FAILURE,
  SELECT_ORDER_INVOICING,
  RESET_ORDER_INVOICING,
  UPDATE_INVOICING_FILTERS
} from '../../actions/orderActions'

import {
  INVOICE_CREATE_REQUEST,
  INVOICE_CREATE_FAILURE,
  INVOICE_CREATE_SUCCESS
} from '../../actions/InvoiceActions'

export default function reducer(
  state = {
    isFetching: false,
    isCreated: false,
    data: {},
    errors: [],
    selected: [],
    ordering: '',
    filters: {
      client: '',
      only_billable: true,
      page: 1
    },

    needRefresh: false
  },
  action
) {
  let newSelection
  if (action.type === SELECT_ORDER_INVOICING) {
    newSelection = _.xorBy(state.selected, [action.data], 'id')
  }
  switch (action.type) {
    case INVOICE_CREATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isCreated: false
      })
    case INVOICE_CREATE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,

        errors: action.data || action.errors
      })
    case INVOICE_CREATE_SUCCESS:
      window.location = '/invoices/'
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        isCreated: true,
        needRefresh: true
      })
    case ORDER_INVOICING_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case ORDER_INVOICING_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case ORDER_INVOICING_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case SELECT_ORDER_INVOICING:
      return Object.assign({}, state, {
        selected: newSelection,
        activeAction: newSelection.length > 0 ? state.activeAction : null
      })

    case RESET_ORDER_INVOICING:
      return Object.assign({}, state, {
        errors: [],
        data: {}
      })
    case UPDATE_INVOICING_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    default:
      return state
  }
}
