import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// CONFIGPRICE GENERAL INFORMATION
export const CONFIGPRICE_LIST_REQUEST = 'CONFIGPRICE_LIST_REQUEST'
export const CONFIGPRICE_LIST_SUCCESS = 'CONFIGPRICE_LIST_SUCCESS'
export const CONFIGPRICE_LIST_FAILURE = 'CONFIGPRICE_LIST_FAILURE'
// CONFIGPRICE DETAIL INFORMATION
export const CONFIGPRICE_DETAIL_REQUEST = 'CONFIGPRICE_DETAIL_REQUEST'
export const CONFIGPRICE_DETAIL_SUCCESS = 'CONFIGPRICE_DETAIL_SUCCESS'
export const CONFIGPRICE_DETAIL_FAILURE = 'CONFIGPRICE_DETAIL_FAILURE'
// CONFIGPRICE UPDATE DATA
export const UPDATE_CONFIGPRICE_FILTERS = 'UPDATE_CONFIGPRICE_FILTERS'
export const CONFIGPRICE_UPDATE_REQUEST = 'CONFIGPRICE_UPDATE_REQUEST'
export const CONFIGPRICE_UPDATE_SUCCESS = 'CONFIGPRICE_UPDATE_SUCCESS'
export const CONFIGPRICE_UPDATE_FAILURE = 'CONFIGPRICE_UPDATE_FAILURE'
// CONFIGPRICE DELETE DATA
export const SET_CONFIGPRICE_ACTION_ACTIVE = 'SET_CONFIGPRICE_ACTION_ACTIVE'
export const CONFIGPRICE_DELETE_REQUEST = 'CONFIGPRICE_DELETE_REQUEST'
export const CONFIGPRICE_DELETE_SUCCESS = 'CONFIGPRICE_DELETE_SUCCESS'
export const CONFIGPRICE_DELETE_FAILURE = 'CONFIGPRICE_DELETE_FAILURE'
// CREATE CONFIGPRICE DATA
export const CONFIGPRICE_CREATE_REQUEST = 'CONFIGPRICE_CREATE_REQUEST'
export const CONFIGPRICE_CREATE_SUCCESS = 'CONFIGPRICE_CREATE_SUCCESS'
export const CONFIGPRICE_CREATE_FAILURE = 'CONFIGPRICE_CREATE_FAILURE'

export const SELECT_CONFIGPRICE = 'SELECT_CONFIGPRICE'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const RESET_CONFIGPRICE = 'RESET_CONFIGPRICE'

export function getConfigPriceList(params = {}) {
  let url = 'formulas/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        CONFIGPRICE_LIST_REQUEST,
        CONFIGPRICE_LIST_SUCCESS,
        CONFIGPRICE_LIST_FAILURE
      ]
    }
  }
}

export function updateConfigPriceFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_CONFIGPRICE_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function selectConfigPrice(obj) {
  return dispatch => {
    const action = {
      type: SELECT_CONFIGPRICE,
      data: obj
    }
    dispatch(action)
  }
}

export function showMessage() {
  return dispatch => {
    const action = {
      type: SHOW_MESSAGE
    }
    dispatch(action)
  }
}

export function hideMessage() {
  return dispatch => {
    const action = {
      type: HIDE_MESSAGE
    }
    dispatch(action)
  }
}

export function configPriceDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `formulas/${id}/`,
      authenticated: true,
      localAPI: false,
      types: [
        CONFIGPRICE_DELETE_REQUEST,
        CONFIGPRICE_DELETE_SUCCESS,
        CONFIGPRICE_DELETE_FAILURE
      ]
    }
  }
}

export function configPriceDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `formulas/${id}/`,
      authenticated: true,
      types: [
        CONFIGPRICE_DETAIL_REQUEST,
        CONFIGPRICE_DETAIL_SUCCESS,
        CONFIGPRICE_DETAIL_FAILURE
      ]
    }
  }
}

export function editConfigPrice(data, id) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `formulas/${id}/`,
      authenticated: true,
      data,
      types: [
        CONFIGPRICE_UPDATE_REQUEST,
        CONFIGPRICE_UPDATE_SUCCESS,
        CONFIGPRICE_UPDATE_FAILURE
      ]
    }
  }
}

export function createConfigPrice(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `formulas/`,
      authenticated: true,
      data,
      types: [
        CONFIGPRICE_CREATE_REQUEST,
        CONFIGPRICE_CREATE_SUCCESS,
        CONFIGPRICE_CREATE_FAILURE
      ]
    }
  }
}

export function resetConfigPrice() {
  return dispatch => {
    const action = {
      type: RESET_CONFIGPRICE
    }
    dispatch(action)
  }
}
