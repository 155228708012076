import axios from 'axios'

const getToken = () => {
  const { token } = localStorage
  return localStorage ? `JWT ${token}` : false
}

const getHeaders = () => {
  let headers
  const token = getToken()
  if (token) {
    headers = {
      'Accept-Language': 'es',
      Authorization: token,
      'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }
  return headers
}

const generateFetch = config => {
  const conf = {
    ...config,
    headers: getHeaders()
  }
  return axios(conf)
}

export default generateFetch
