import _ from 'lodash'
import {
  UPDATE_INVOICE_FILTERS,
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
  SELECT_INVOICE,
  SELECT_ONE_INVOICE,
  RESET_INVOICE,
  INVOICE_CONFIRM_REQUEST,
  INVOICE_CONFIRM_SUCCESS,
  INVOICE_CONFIRMAGAIN_REQUEST,
  INVOICE_CONFIRMAGAIN_SUCCESS,
  INVOICE_EDIT_REQUEST,
  INVOICE_EDIT_SUCCESS,
  INVOICE_EDIT_FAILURE
} from '../../actions/InvoiceActions'

export default function reducer(
  state = {
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
    activeAction: null,
    needRefresh: false,
    selectedOne: {},
    data: {},
    errors: {},
    selected: [],
    filters: {
      search: '',
      page: 1,
      only_expired: false,
      created_at_0: null,
      created_at_1: null
    }
  },
  action
) {
  let newSelection
  if (action.type === SELECT_INVOICE) {
    newSelection = _.xorBy(state.selected, [action.data], 'id')
  }
  switch (action.type) {
    case INVOICE_CONFIRM_REQUEST:
    case INVOICE_CONFIRMAGAIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case INVOICE_CONFIRM_SUCCESS:
    case INVOICE_CONFIRMAGAIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        needRefresh: true
      })

    case INVOICE_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case INVOICE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })
    case UPDATE_INVOICE_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    case RESET_INVOICE:
      return Object.assign({}, state, {
        dataDetail: {}
      })
    case SELECT_INVOICE:
      return Object.assign({}, state, {
        selected: newSelection
      })
    case SELECT_ONE_INVOICE:
      return Object.assign({}, state, {
        selectedOne: action.data
      })
    case INVOICE_EDIT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })
    case INVOICE_EDIT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: true
      })
    case INVOICE_EDIT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false
      })
    default:
      return state
  }
}
