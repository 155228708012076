import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const UPDATE_PAYMENT_DETAIL_FILTERS = 'UPDATE_PAYMENT_DETAIL_FILTERS'
export const UPDATE_PAYMENT_LIST_FILTERS = 'UPDATE_PAYMENT_LIST_FILTERS'

export const PAYMENT_LIST_REQUEST = 'PAYMENT_LIST_REQUEST'
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS'
export const PAYMENT_LIST_FAILURE = 'PAYMENT_LIST_FAILURE'

export const PAYMENT_CREATE_REQUEST = 'PAYMENT_CREATE_REQUEST'
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS'
export const PAYMENT_CREATE_FAILURE = 'PAYMENT_CREATE_FAILURE'

export const PAYMENT_DETAIL_REQUEST = 'PAYMENT_DETAIL_REQUEST'
export const PAYMENT_DETAIL_SUCCESS = 'PAYMENT_DETAIL_SUCCESS'
export const PAYMENT_DETAIL_FAILURE = 'PAYMENT_DETAIL_FAILURE'

export const PAYMENT_UPDATE_REQUEST = 'PAYMENT_UPDATE_REQUEST'
export const PAYMENT_UPDATE_SUCCESS = 'PAYMENT_UPDATE_SUCCESS'
export const PAYMENT_UPDATE_FAILURE = 'PAYMENT_UPDATE_FAILURE'

export const PAYMENT_RETRIEVE_REQUEST = 'PAYMENT_RETRIEVE_REQUEST'
export const PAYMENT_RETRIEVE_SUCCESS = 'PAYMENT_RETRIEVE_SUCCESS'
export const PAYMENT_RETRIEVE_FAILURE = 'PAYMENT_RETRIEVE_FAILURE'

export function paymentList(params = {}) {
  let url = 'payment/list/'

  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      storeAPI: true,
      types: [PAYMENT_LIST_REQUEST, PAYMENT_LIST_SUCCESS, PAYMENT_LIST_FAILURE]
    }
  }
}

export function updateFilters(data, list) {
  return dispatch => {
    const action = {
      type: list ? UPDATE_PAYMENT_LIST_FILTERS : UPDATE_PAYMENT_DETAIL_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function paymentCreate(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `payment/create/`,
      authenticated: true,
      storeAPI: true,
      data,
      types: [
        PAYMENT_CREATE_REQUEST,
        PAYMENT_CREATE_SUCCESS,
        PAYMENT_CREATE_FAILURE
      ]
    }
  }
}

export function paymentDeail(params = {}, id) {
  let url = `payment/orders/${id}/`

  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      storeAPI: true,
      types: [
        PAYMENT_DETAIL_REQUEST,
        PAYMENT_DETAIL_SUCCESS,
        PAYMENT_DETAIL_FAILURE
      ]
    }
  }
}

export function paymentRetrieve(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `payment/${id}/`,
      authenticated: true,
      storeAPI: true,
      types: [
        PAYMENT_RETRIEVE_REQUEST,
        PAYMENT_RETRIEVE_SUCCESS,
        PAYMENT_RETRIEVE_FAILURE
      ]
    }
  }
}

export function paymentUpdate(id, data) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `payment/${id}/update/`,
      authenticated: true,
      storeAPI: true,
      data,
      types: [
        PAYMENT_UPDATE_REQUEST,
        PAYMENT_UPDATE_SUCCESS,
        PAYMENT_UPDATE_FAILURE
      ]
    }
  }
}
