import {
  ORGANIZATION_CONTRACT_REQUEST,
  ORGANIZATION_CONTRACT_SUCCESS,
  ORGANIZATION_CONTRACT_FAILURE,
  EDIT_ORGANIZATION_CONTRACT_REQUEST,
  EDIT_ORGANIZATION_CONTRACT_SUCCESS,
  EDIT_ORGANIZATION_CONTRACT_FAILURE
} from '../../actions/myNetworkActions'

const initialState = {
  isFetching: false,
  needRefresh: false,
  contractList: undefined,
  singleContract: undefined
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case ORGANIZATION_CONTRACT_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case ORGANIZATION_CONTRACT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        contractList: actions.data
      }

    case ORGANIZATION_CONTRACT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: actions.data
      }

    case EDIT_ORGANIZATION_CONTRACT_REQUEST:
      return {
        ...state,
        isFetching: true
      }

    case EDIT_ORGANIZATION_CONTRACT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        singleContract: actions.data
      }

    case EDIT_ORGANIZATION_CONTRACT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: actions.data
      }

    default:
      return state
  }
}
