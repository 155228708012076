import $ from 'jquery'
import { CALL_API } from '../middleware/api'
// COURIER GENERAL INFORMATION
export const COURIER_LIST_REQUEST = 'COURIER_LIST_REQUEST'
export const COURIER_LIST_SUCCESS = 'COURIER_LIST_SUCCESS'
export const COURIER_LIST_FAILURE = 'COURIER_LIST_FAILURE'
// COURIER DETAIL INFORMATION
export const COURIER_DETAIL_REQUEST = 'COURIER_DETAIL_REQUEST'
export const COURIER_DETAIL_SUCCESS = 'COURIER_DETAIL_SUCCESS'
export const COURIER_DETAIL_FAILURE = 'COURIER_DETAIL_FAILURE'
// COURIER HISTORY DETAIL INFORMATION
export const COURIER_HISTORYDETAIL_REQUEST = 'COURIER_HISTORYDETAIL_REQUEST'
export const COURIER_HISTORYDETAIL_SUCCESS = 'COURIER_HISTORYDETAIL_SUCCESS'
export const COURIER_HISTORYDETAIL_FAILURE = 'COURIER_HISTORYDETAIL_FAILURE'
// COURIER UPDATE DATA
export const UPDATE_COURIER_FILTERS = 'UPDATE_COURIER_FILTERS'
export const COURIER_UPDATE_REQUEST = 'COURIER_UPDATE_REQUEST'
export const COURIER_UPDATE_SUCCESS = 'COURIER_UPDATE_SUCCESS'
export const COURIER_UPDATE_FAILURE = 'COURIER_UPDATE_FAILURE'
// COURIER DELETE DATA
export const SET_COURIER_ACTION_ACTIVE = 'SET_COURIER_ACTION_ACTIVE'
export const COURIER_DELETE_REQUEST = 'COURIER_DELETE_REQUEST'
export const COURIER_DELETE_SUCCESS = 'COURIER_DELETE_SUCCESS'
export const COURIER_DELETE_FAILURE = 'COURIER_DELETE_FAILURE'
// CREATE COURIER DATA
export const COURIER_CREATE_REQUEST = 'COURIER_CREATE_REQUEST'
export const COURIER_CREATE_SUCCESS = 'COURIER_CREATE_SUCCESS'
export const COURIER_CREATE_FAILURE = 'COURIER_CREATE_FAILURE'
// DEACTIVATE COURIER DATA
export const COURIER_DEACTIVATE_REQUEST = 'COURIER_DEACTIVATE_REQUEST'
export const COURIER_DEACTIVATE_SUCCESS = 'COURIER_DEACTIVATE_SUCCESS'
export const COURIER_DEACTIVATE_FAILURE = 'COURIER_DEACTIVATE_FAILURE'

export const COURIER_CHANGE_ACTIVE_STATE_REQUEST =
  'COURIER_CHANGE_ACTIVE_STATE_REQUEST'
export const COURIER_CHANGE_ACTIVE_STATE_SUCCESS =
  'COURIER_CHANGE_ACTIVE_STATE_SUCCESS'
export const COURIER_CHANGE_ACTIVE_STATE_FAILURE =
  'COURIER_CHANGE_ACTIVE_STATE_FAILURE'

export const SELECT_COURIER = 'SELECT_COURIER'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const RESET_COURIER = 'RESET_COURIER'

export const COURIER_IMPORT_REQUEST = 'COURIER_IMPORT_REQUEST'
export const COURIER_IMPORT_SUCCESS = 'COURIER_IMPORT_SUCCESS'
export const COURIER_IMPORT_FAILURE = 'COURIER_IMPORT_FAILURE'

export const COURIER_GET_VEHICLES_REQUEST = 'COURIER_GET_VEHICLES_REQUEST'
export const COURIER_GET_VEHICLES_SUCCESS = 'COURIER_GET_VEHICLES_SUCCESS'
export const COURIER_GET_VEHICLES_FAILURE = 'COURIER_GET_VEHICLES_FAILURE'

export const COURIER_RELEASE_DEVICE_REQUEST = 'COURIER_RELEASE_DEVICE_REQUEST'
export const COURIER_RELEASE_DEVICE_SUCCESS = 'COURIER_RELEASE_DEVICE_SUCCESS'
export const COURIER_RELEASE_DEVICE_FAILURE = 'COURIER_RELEASE_DEVICE_FAILURE'
// COURIER REFERRAL DETAIL INFORMATION
export const COURIER_REFERRAL_DETAIL_REQUEST =
  'COURIER_REFERRAL_DETAIL_REQUEST'
export const COURIER_REFERRAL_DETAIL_SUCCESS =
  'COURIER_REFERRAL_DETAIL_SUCCESS'
export const COURIER_REFERRAL_DETAIL_FAILURE =
  'COURIER_REFERRAL_DETAIL_FAILURE'

export function courierList(params = {}) {
  let url = 'couriers/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      msg: 'courier',
      extra: params,
      types: [COURIER_LIST_REQUEST, COURIER_LIST_SUCCESS, COURIER_LIST_FAILURE]
    }
  }
}

export function courierDeactivate(params = {}, data) {
  let url = 'couriers/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: url,
      authenticated: true,
      msg: 'courier',
      data,
      extra: params,
      types: [
        COURIER_DEACTIVATE_REQUEST,
        COURIER_DEACTIVATE_SUCCESS,
        COURIER_DEACTIVATE_FAILURE
      ]
    }
  }
}

export function courierChangeActiveState(params = {}, data) {
  // TODO: dehardcode the id[0] when backend accepts bulk courier update
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `couriers/${params.id[0]}/active/`,
      authenticated: true,
      data,
      extra: params,
      types: [
        COURIER_CHANGE_ACTIVE_STATE_REQUEST,
        COURIER_CHANGE_ACTIVE_STATE_SUCCESS,
        COURIER_CHANGE_ACTIVE_STATE_FAILURE
      ]
    }
  }
}

export function courierDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `couriers/${id}/`,
      authenticated: true,
      types: [
        COURIER_DETAIL_REQUEST,
        COURIER_DETAIL_SUCCESS,
        COURIER_DETAIL_FAILURE
      ]
    }
  }
}

export function updateCourierFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_COURIER_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function editCourier(data, id) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `couriers/${id}/`,
      authenticated: true,
      data,
      returnPromise: true,
      types: [
        COURIER_UPDATE_REQUEST,
        COURIER_UPDATE_SUCCESS,
        COURIER_UPDATE_FAILURE
      ]
    }
  }
}

export function deleteCourier(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `couriers/${id}/`,
      authenticated: true,
      types: [
        COURIER_DELETE_REQUEST,
        COURIER_DELETE_SUCCESS,
        COURIER_DELETE_FAILURE
      ]
    }
  }
}

export function createCourier(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `couriers/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        COURIER_CREATE_REQUEST,
        COURIER_CREATE_SUCCESS,
        COURIER_CREATE_FAILURE
      ]
    }
  }
}

export function selectCourier(obj) {
  return dispatch => {
    const action = {
      type: SELECT_COURIER,
      data: obj
    }
    dispatch(action)
  }
}

export function showMessage() {
  return dispatch => {
    const action = {
      type: SHOW_MESSAGE
    }
    dispatch(action)
  }
}

export function hideMessage() {
  return dispatch => {
    const action = {
      type: HIDE_MESSAGE
    }
    dispatch(action)
  }
}

export function resetCourier() {
  return dispatch => {
    const action = {
      type: RESET_COURIER
    }
    dispatch(action)
  }
}

export function courierHistoryDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `couriers/${id}/`,
      authenticated: true,
      types: [
        COURIER_HISTORYDETAIL_REQUEST,
        COURIER_HISTORYDETAIL_SUCCESS,
        COURIER_HISTORYDETAIL_FAILURE
      ]
    }
  }
}

export function importCourier(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `import/courier/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        COURIER_IMPORT_REQUEST,
        COURIER_IMPORT_SUCCESS,
        COURIER_IMPORT_FAILURE
      ]
    }
  }
}

export function courierGetVehicles(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `couriers/vehicles/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        COURIER_GET_VEHICLES_REQUEST,
        COURIER_GET_VEHICLES_SUCCESS,
        COURIER_GET_VEHICLES_FAILURE
      ]
    }
  }
}

export function courierReleaseDevice(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `couriers/${id}/release_device/`,
      authenticated: true,
      localAPI: false,
      types: [
        COURIER_RELEASE_DEVICE_REQUEST,
        COURIER_RELEASE_DEVICE_SUCCESS,
        COURIER_RELEASE_DEVICE_FAILURE
      ]
    }
  }
}

export function courierReferralDetail(id, params = {}) {
  let url = `couriers/${id}/referred/`
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      types: [
        COURIER_REFERRAL_DETAIL_REQUEST,
        COURIER_REFERRAL_DETAIL_SUCCESS,
        COURIER_REFERRAL_DETAIL_FAILURE
      ]
    }
  }
}
