import React from 'react'
import DatePicker from 'react-datepicker'

import { parseStateToValue, formatValueToState } from '../../utils/events'

const DateForm = ({
  minDate,
  name,
  withTimePicker,
  value,
  onChange,
  placeholder
}) => {
  const objMinDate = {}
  if (minDate) {
    objMinDate.minDate = minDate
  }

  return (
    <DatePicker
      name={name}
      className="form-control"
      dateFormat={withTimePicker ? 'LLL' : 'LL'}
      showTimeSelect={withTimePicker}
      selected={parseStateToValue(value)}
      onChange={val => onChange(formatValueToState(val))}
      placeholderText={placeholder}
      {...objMinDate}
    />
  )
}

const DateInputForm = field => {
  return (
    <div className="col-lg-12">
      <DateForm {...field} />
    </div>
  )
}

export default DateInputForm
