import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'

import ActionButton from '../../../01-atoms/ActionButton'
import Toast from '../../../01-atoms/Toast'
import {
  ConfirmModal,
  ConsultPriceModal,
  EditDestinationForm,
  RefOrderModal
} from '../../../02-molecules/ActionsOrderDetail'
import Header from '../../../04-layouts/Header'
import Section from '../../../04-layouts/Section'
import OrderNote from './OrderNote'
import OrderPrices from './OrderPrices'
import { OrderActivity } from './OrderActivity'
import OrderDestinations from './OrderDestinations'
import OrderDetailHeader from './OrderDetailHeader'
import OrderInformation from './OrderInformation'
import { OrderPayment } from './OrderPayment'
import {
  navTabsOrderDetail,
  selectDestinationStatus
} from './config.destination'
import * as orderActions from '../../../../actions/orderActions'
import generateFetch from '../../../../utils/fetch'
import {
  dateFormat,
  kilometerFormat,
  minutesTohoursFormat,
  editDestinationFormat,
  stringDurationFormat
} from '../../../../utils/formats'
import { hasPermission, showClient } from '../../../../utils/index'
import {
  DESTINATION_STATUS,
  ORDER_STATUS,
  permissionLevels
} from '../../../../utils/variables'

const openTabFive = e => {
  e.preventDefault()
  const selectorTab = '.js-tabs-orderDetail .nav-tabs'
  const selectorActiveDetailTab = `${selectorTab} .active`
  const selectorAllTabs = `${selectorTab} li`
  const selectorContentTab = '.js-content-detail'
  const AllTabs = document.querySelectorAll(selectorAllTabs)
  const AllContentTabs = document.querySelectorAll(
    `${selectorContentTab} .tab-pane`
  )

  document.querySelector(selectorActiveDetailTab).className = ''
  document.querySelector(`${selectorContentTab} .active`).className =
    'tab-pane'

  AllTabs[AllTabs.length - 1].className = 'active'
  AllContentTabs[AllContentTabs.length - 1].className = 'tab-pane active'
}

const staticMedia = `${process.env.REACT_APP_S3_URL}media/`
const basepath = `${process.env.REACT_APP_BASE_DOMAIN}api/admin/orders/`

class OrderView extends Component {
  state = {
    editingDestination: null,
    selectedStatus: '',
    failedReason: '',
    otherReason: '',
    toastMessage: '',
    toastType: '',
    showModal: false,
    showReturnModal: false,
    priceDetails: null,
    modalType: '',
    lookupId: null,
    isConformable: false,
    isLoading: false,
    showRefModal: false,
    orderDetails: null,
    searchError: null,
    orderCode: '',
    processStatus: null
  }

  submitPriceCostModification = value => {
    const { id, orderUpdate } = this.props

    const values = (({
      cost_modification,
      cost_modification_comment,
      total_cost,
      price_modification,
      price_modification_comment,
      total_price
    }) => ({
      cost_modification,
      cost_modification_comment,
      total_cost,
      price_modification,
      price_modification_comment,
      total_price
    }))(value)

    Object.keys(values).forEach(
      k => (!values[k] || typeof values[k] === 'number') && delete values[k]
    )

    orderUpdate(id, values)
  }

  componentDidUpdate(prevProps) {
    if (this.props.toast.visible && !prevProps.toast.visible) {
      setTimeout(() => {
        this.props.dispatch({ type: orderActions.HIDE_TOAST })
      }, 8000)
    }
  }

  chargeFromPurseHandler = () => {
    const { chargeFromPurse, id } = this.props
    const request = chargeFromPurse({
      payment_request: { backend: 'purse' },
      order_id: id
    })
    request.then(() => {
      window.location = '/orders/'
    })
  }

  retryPaymentHandler = () => {
    const { retryPayment, id } = this.props
    const request = retryPayment({ order_id: id })
    request.then(() => {
      window.location = '/orders/'
    })
  }

  handleCheckboxChange = e => {
    this.setState({ isConformable: e.target.checked })
  }

  handleDestinationChange = dest => {
    this.setState({
      editingDestination: dest,
      showModal: true,
      modalType: 'confirmDestinationChange'
    })
  }

  handleCloseModal = () => {
    this.setState({ showModal: false, priceDetails: null })
  }

  handleCloseRenderPriceModal = () => {
    this.setState({ showReturnModal: false, modalType: 'renderPrice' })
  }

  closeToast = () => {
    this.setState({ toastMessage: '', toastType: '' })
  }

  setEditingDestination = dest_num => {
    this.setState({ editingDestination: dest_num })
  }

  handleChangeStatus = e => {
    const { getStatusReasonOptions, client } = this.props
    getStatusReasonOptions({
      status: e.target.value,
      client: client.id
    })
    this.setState({ selectedStatus: e.target.value })
  }

  handleChangeFailedReason = e => {
    this.setState({ failedReason: e.target.value })
  }

  handleChangeOtherReason = e => {
    this.setState({ otherReason: e.target.value })
  }

  onCancelSelection = () =>
    this.setState({
      editingDestination: null,
      selectedStatus: '',
      failedReason: '',
      otherReason: ''
    })

  submitDestinationEdit = async destination_number => {
    const { id, orderDetail } = this.props
    const { selectedStatus, failedReason, otherReason } = this.state

    if (!selectedStatus) {
      this.setState({
        toastMessage: 'Debe seleccionar un estado',
        toastType: 'error'
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)
      return
    }

    const data = { status: selectedStatus }
    if (selectedStatus === DESTINATION_STATUS.FAILED && failedReason) {
      data.failed_reason =
        failedReason === 'Otros' ? otherReason : failedReason
    }

    const config = {
      method: 'PATCH',
      data: data,
      url: `${basepath}${id}/${destination_number}/`
    }
    try {
      const response = await generateFetch(config)
      this.setState({
        toastMessage: 'Estado del destino actualizado exitosamente',
        toastType: 'success',
        editingDestination: null
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      await orderDetail(id)
    } catch (error) {
      const apiErrorResponse = error.response?.data
      let apiErrorMessage = 'Error al actualizar el estado del destino'

      if (Array.isArray(apiErrorResponse)) {
        apiErrorMessage = apiErrorResponse.join(' ')
      } else if (apiErrorResponse?.non_field_errors) {
        apiErrorMessage = apiErrorResponse.non_field_errors.join(' ')
      } else if (typeof apiErrorResponse === 'string') {
        apiErrorMessage = apiErrorResponse
      }

      this.setState({
        toastMessage: apiErrorMessage,
        toastType: 'error',
        editingDestination: null
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      console.error('Error al editar destino:', error)
    }
  }

  returnPriceCalculation = async () => {
    const { id } = this.props
    const requestOpt = {
      method: 'POST',
      url: `${basepath}${id}/return/calculate_price/`
    }
    try {
      const res = await generateFetch(requestOpt)
      const { lookup_id } = res
      this.setState({
        priceDetails: res.data,
        showReturnModal: true,
        isConformable: false,
        lookupId: lookup_id
      })
    } catch (error) {
      this.setState({
        toastMessage: 'Error al calcular el precio del retorno',
        toastType: 'error'
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      console.log('Error en la solicitud de cálculo de precio:', error)
    }
  }

  submitOrderAddReturn = async () => {
    const { id, orderDetail } = this.props
    const { lookupId } = this.state

    const requestOptions = {
      method: 'POST',
      url: `${basepath}${id}/return/add/`,
      body: JSON.stringify({ lookup_id: lookupId })
    }
    this.setState({ isLoading: true })
    try {
      const response = await generateFetch(requestOptions)

      this.setState({
        showReturnModal: false,
        toastMessage: 'El retorno ha sido agregado correctamente',
        toastType: 'success',
        isLoading: false
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      await orderDetail(id)
    } catch (error) {
      const apiErrorResponse = error.response?.data
      this.setState({
        showReturnModal: false,
        toastMessage: apiErrorResponse || 'Error al agregar el retorno',
        toastType: 'error',
        isLoading: false
      })
      setTimeout(() => {
        this.closeToast()
      }, 5000)

      console.log('Error al agregar retorno a la orden:', error)
    }
  }

  renderEditDestinationForm = () => {
    const { statusReasonOptions } = this.props
    const {
      editingDestination,
      selectedStatus,
      failedReason,
      otherReason
    } = this.state

    if (!editingDestination) return null

    return (
      <div
        className="row jumbotron inline-action"
        style={{ marginBottom: '24px' }}
      >
        <EditDestinationForm
          statusReasonOptions={statusReasonOptions}
          selectDestinationStatus={selectDestinationStatus}
          handleSubmit={e => {
            e.preventDefault()
            this.handleDestinationChange(editingDestination)
          }}
          selectedStatus={selectedStatus}
          handleChangeStatus={this.handleChangeStatus}
          onCancelSelection={this.onCancelSelection}
          failedReason={failedReason}
          handleChangeFailedReason={this.handleChangeFailedReason}
          otherReason={otherReason}
          handleChangeOtherReason={this.handleChangeOtherReason}
        />
      </div>
    )
  }

  confirmDestinationChange = async () => {
    const { editingDestination } = this.state

    await this.submitDestinationEdit(editingDestination)
    this.setState({
      showModal: false,
      selectedStatus: '',
      editingDestination: null
    })
  }

  cancelDestinationChange = () => {
    this.setState({
      showModal: false,
      selectedStatus: '',
      editingDestination: null,
      modalType: ''
    })
  }

  showReferenceOrderModal = () => {
    this.setState({
      showRefModal: true
    })
  }

  cancelReferenceOrderModal = () => {
    this.setState({
      showRefModal: false,
      orderDetails: null,
      orderCode: ''
    })
  }

  searchOrder = async code => {
    const url = `${basepath}?code=${code}`
    const request = {
      method: 'GET',
      url: url
    }
    this.setState({ searchError: null })

    try {
      const response = await generateFetch(request)
      const { data } = response

      if (data.count > 0) {
        this.setState({
          orderDetails: data.results[0]
        })
      } else {
        this.setState({
          searchError: 'No se encontró el código ingresado'
        })
      }
    } catch (error) {
      this.setState({
        searchError: 'Error en la búsqueda.'
      })
      console.error('Error al buscar la orden:', error)
    }
  }

  associateOrder = async referenceCode => {
    const { id, orderDetail } = this.props

    const apiUrl = `${basepath}${id}/associate/`
    const getToken = () => {
      const { token } = localStorage
      return localStorage ? `JWT ${token}` : false
    }

    const getHeaders = () => {
      let headers
      const token = getToken()
      if (token) {
        headers = {
          'Accept-Language': 'es',
          Authorization: token,
          'Content-Type': 'application/json',
          'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
      return headers
    }
    const bodyData = {
      code: referenceCode
    }

    const request = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(bodyData)
    }
    this.setState({ processStatus: 'processing', searchError: null })
    try {
      const res = await fetch(apiUrl, request)
      if (res.ok) {
        this.setState({
          orderCode: '',
          orderDetails: null,
          searchError: null,
          showRefModal: false,
          processStatus: 'success'
        })

        await orderDetail(id)
      } else {
        this.setState({
          processStatus: 'error',
          searchError: 'Error al asociar la orden.'
        })
      }
    } catch (error) {
      this.setState({
        processStatus: 'error',
        searchError: 'Error en la conexión.'
      })
      console.error('Error al asociar la orden:', error)
    }
  }

  render() {
    const {
      toastMessage,
      toastType,
      modalType,
      showModal,
      orderDetails,
      showRefModal,
      searchError,
      orderCode,
      processStatus
    } = this.state
    const {
      progress,
      onOrderDetailClose,
      created_at,
      programmed_date,
      status,
      status_label,
      current_destination_label,
      total_waiting_time,
      payment_status,
      isFetching,
      invoice,
      code,
      client,
      description,
      extra_data,
      vehicle_type,
      courier,
      memo,
      id,
      orderNoteUpdate,
      destinations,
      activity,
      notes,
      newNotes,
      payment_type,
      duration,
      distance,
      cost,
      url,
      batch_memo,
      shipping_document,
      coupon_code,
      parent_order_code,
      courier_payment_type_name,
      total_cost,
      courier_earnings_after_igv,
      courier_igv_to_pay,
      payment,
      payment_status_label,
      payment_request,
      toast,
      is_return,
      type
    } = this.props

    return (
      <Section>
        <div className="element-detail-box">
          <Header title="Detalle del pedido">
            {!is_return &&
              status === ORDER_STATUS.ENDED &&
              hasPermission('order', permissionLevels.UPDATE) && (
                <ActionButton
                  label="Retorno"
                  type="success"
                  icon="exchange"
                  onClick={this.returnPriceCalculation}
                />
              )}
            {!parent_order_code &&
              type === 1 &&
              hasPermission('destination', permissionLevels.UPDATE) && (
                <ActionButton
                  label="Referenciar orden"
                  type="success"
                  icon="cart-arrow-down"
                  onClick={this.showReferenceOrderModal}
                />
              )}
          </Header>
          <RefOrderModal
            showModal={showRefModal}
            onCancel={this.cancelReferenceOrderModal}
            onSearchOrder={this.searchOrder}
            orderDetails={orderDetails}
            error={searchError}
            orderCode={orderCode}
            setOrderCode={code => this.setState({ orderCode: code })}
            onConfirm={this.associateOrder}
            processStatus={processStatus}
          />
          <OrderDetailHeader
            progress={progress}
            onOrderDetailClose={() => onOrderDetailClose()}
            createdAt={moment(created_at).format('LLLL')}
            programmedDate={programmed_date}
            formatProgrammedDate={dateFormat(programmed_date)}
            status={status}
            statusLabel={status_label}
            currentDestinationLabel={{
              status_label: current_destination_label
            }}
            totalWaitingTime={minutesTohoursFormat(total_waiting_time)}
            paymentStatus={payment_status}
            retryPaymentHandler={this.retryPaymentHandler}
            isFetching={isFetching}
            invoice={invoice}
            chargeFromPurseHandler={this.chargeFromPurseHandler}
            code={code}
            parentOrderCode={parent_order_code}
            client={client}
            showClient={showClient(client || '')}
            onOpenTabFive={e => openTabFive(e)}
            description={description}
            vehicleType={vehicle_type}
            courier={courier}
            memo={memo}
            courierPaymentTypeName={courier_payment_type_name}
            totalCost={total_cost}
            courierIgvToPay={courier_igv_to_pay}
            courierEarningsAfterIgv={courier_earnings_after_igv}
          />
          <br />
          {this.renderEditDestinationForm()}
          <ConsultPriceModal
            priceDetails={this.state.priceDetails}
            showReturnModal={this.state.showReturnModal}
            isConformable={this.state.isConformable}
            isLoading={this.state.isLoading}
            handleCloseRenderPriceModal={this.handleCloseRenderPriceModal}
            handleCheckboxChange={this.handleCheckboxChange}
            submitOrderAddReturn={this.submitOrderAddReturn}
          />
          {modalType === 'confirmDestinationChange' && (
            <ConfirmModal
              body="¿Seguro que deseas cambiar el estado del destino?"
              onConfirm={this.confirmDestinationChange}
              showModal={showModal}
              onCancel={this.cancelDestinationChange}
            />
          )}
          {toastMessage && (
            <div style={{ marginBottom: '24px' }}>
              <Toast
                message={toastMessage}
                type={toastType}
                onClose={this.closeToast}
              />
            </div>
          )}
          {toast.visible && (
            <div style={{ marginBottom: '24px' }}>
              <Toast
                message={toast.message}
                type={toast.type}
                onClose={() =>
                  this.props.dispatch({ type: orderActions.HIDE_TOAST })
                }
              />
            </div>
          )}
        </div>
        <div className="ibox-content">
          <div className="tabs-container js-tabs-orderDetail">
            <ul className="nav nav-tabs">
              {navTabsOrderDetail.map((navTab, index) => (
                <li key={index} className={navTab.className}>
                  <a
                    data-toggle={navTab.dataToggle}
                    href={navTab.href}
                    aria-expanded={navTab.ariaExpanded}
                  >
                    {navTab.label}
                  </a>
                </li>
              ))}
            </ul>
            <div className="tab-content js-content-detail">
              <div id="tab-1" className="tab-pane active ">
                <OrderDestinations
                  destinations={destinations}
                  editDestinationFormat={dest_num =>
                    editDestinationFormat(dest_num, this.setEditingDestination)
                  }
                />
              </div>
              <div id="tab-2" className="tab-pane">
                <OrderActivity orderActivity={activity} />
              </div>
              <div id="tab-3" className="tab-pane">
                <OrderPayment
                  orderPayment={payment}
                  paymentType={payment_type}
                  paymentStatus={payment_status}
                  paymentStatusLabel={payment_status_label}
                  paymentRequest={payment_request}
                />
              </div>
              <div id="tab-4" className="tab-pane">
                <OrderPrices
                  submitForm={this.submitPriceCostModification}
                  prices={this.props}
                />
              </div>
              <div id="tab-5" className="tab-pane">
                <OrderNote
                  orderId={id}
                  orderNoteUpdate={orderNoteUpdate}
                  orderNotes={notes}
                  newNotes={newNotes}
                />
              </div>
              <div id="tab-6" className="tab-pane">
                <OrderInformation
                  paymentType={payment_type || 'Cupón'}
                  duration={stringDurationFormat(duration)}
                  kmDistance={kilometerFormat(distance)}
                  description={description}
                  couponCode={coupon_code}
                  extraData={extra_data}
                  cost={cost}
                  url={url}
                  memo={memo}
                  batchMemo={batch_memo}
                  shippingDocument={shipping_document}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    )
  }
}

const mapStateToProps = state => {
  let initialValues = {}
  const { orderDetail } = state
  if (
    Object.keys(orderDetail.data).length &&
    orderDetail.selectedOrder !== null &&
    orderDetail.selectedOrder !== undefined
  ) {
    const [selectedOrder] = orderDetail.data.destinations.filter(
      el => el.number === orderDetail.selectedOrder
    )

    if (selectedOrder) {
      const [orderData] = selectedOrder.log.filter(
        log => log.status === ORDER_STATUS.COMPLETED
      )
      if (orderData) {
        initialValues = {
          photo: orderData.extra.photo
            ? staticMedia + orderData.extra.photo
            : '',
          photo2: orderData.extra.photo2
            ? staticMedia + orderData.extra.photo2
            : '',
          signature: orderData.extra.signature
            ? staticMedia + orderData.extra.signature
            : ''
        }
      }
    }
  }

  return {
    isFetching: state.orderDetail.isFetching,
    selectedOrder: state.orderDetail.selectedOrder,
    toast: state.orderDetail.toast,
    dataDetail: state.orderDetail.data,
    statusReasonOptions: state.orderList.statusReasonOptions,
    initialValues
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      retryPayment: orderActions.retryPayment,
      chargeFromPurse: orderActions.chargeFromPurse,
      selectOrderToEditPhoto: orderActions.selectOrderToEditPhoto,
      getStatusReasonOptions: orderActions.getStatusReasonOptions,
      orderUploadPhotos: orderActions.orderUploadPhotos,
      orderDetail: orderActions.orderDetail
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'addPhoto', enableReinitialize: true })(OrderView))
