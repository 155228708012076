import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const UPDATE_BONUS_FILTERS = 'UPDATE_BONUS_FILTERS'
export const RESET_BONUS = 'RESET_BONUS'

const bonusActions = {}

const genTypeActions = (entity, action) => {
  const arr = ['REQUEST', 'SUCCESS', 'FAILURE']
  return arr.map(el => {
    const type = `${entity.toUpperCase()}_${action.toUpperCase()}_${el}`
    bonusActions[type] = type
    return type
  })
}

export function bonusList(params = {}, opt) {
  const { method, entity, action } = opt
  let url = 'bonuses/'
  const qs = $.param(params, true)
  if (qs) {
    url += `?${qs}`
  }
  return {
    [CALL_API]: {
      method,
      localAPI: false,
      endpoint: url,
      authenticated: true,
      types: genTypeActions(entity, action)
    }
  }
}

export function createBonus(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `bonuses/`,
      authenticated: true,
      data,
      types: genTypeActions('bonus', 'create')
    }
  }
}

export function editBonus(data, id) {
  return {
    [CALL_API]: {
      method: 'PATCH',
      endpoint: `bonuses/${id}/`,
      authenticated: true,
      data,
      types: genTypeActions('bonus', 'update')
    }
  }
}

export function bonusDetail(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `bonuses/${id}/`,
      authenticated: true,
      types: genTypeActions('bonus', 'detail')
    }
  }
}

export function updateBonusFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_BONUS_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function bonusReset() {
  return dispatch => {
    const action = {
      type: RESET_BONUS
    }
    dispatch(action)
  }
}

genTypeActions('bonus', 'list')
genTypeActions('bonus', 'create')
genTypeActions('bonus', 'detail')
genTypeActions('bonus', 'update')
export default bonusActions
