import { CALL_API } from '../middleware/api'

export const ORDER_MAP_CLICK = 'ORDER_MAP_CLICK'
export const ORDER_DETAIL_CLOSE = 'ORDER_DETAIL_CLOSE'
export const COURIER_MAP_CLICK = 'COURIER_MAP_CLICK'
export const DELETE_COURIER_ACTIVE = 'DELETE_COURIER_ACTIVE'

export const ORDER_CLICK_REQUEST = 'ORDER_CLICK_REQUEST'
export const ORDER_CLICK_SUCCESS = 'ORDER_CLICK_SUCCESS'
export const ORDER_CLICK_FAILURE = 'ORDER_CLICK_FAILURE'

export const USE_TRACKER_REQUEST = 'USE_TRACKER_REQUEST'
export const USE_TRACKER_SUCCESS = 'USE_TRACKER_SUCCESS'
export const USE_TRACKER_FAILURE = 'USE_TRACKER_FAILURE'

export const TRACKER_REQUEST = 'TRACKER_REQUEST'
export const TRACKER_SUCCESS = 'TRACKER_SUCCESS'
export const TRACKER_FAILURE = 'TRACKER_FAILURE'

export const START_POLLING = 'START_POLLING'
export const STOP_POLLING = 'STOP_POLLING'

export function shouldUseTracker() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `api/configuration/`,
      isPublic: true,
      authenticated: true,
      types: [USE_TRACKER_REQUEST, USE_TRACKER_SUCCESS, USE_TRACKER_FAILURE]
    }
  }
}

export function startPollingTracker(coordinates) {
  return dispatch => {
    const action = {
      type: START_POLLING,
      coordinates
    }
    dispatch(action)
  }
}

export function stopPollingTracker() {
  return dispatch => {
    const action = {
      type: STOP_POLLING
    }
    dispatch(action)
  }
}

export function trackerRequest() {
  return dispatch => {
    const action = {
      type: TRACKER_REQUEST
    }
    dispatch(action)
  }
}

export function trackerSuccess(data) {
  return dispatch => {
    const action = {
      type: TRACKER_SUCCESS,
      data
    }
    dispatch(action)
  }
}

export function TrackerFailure(error) {
  return dispatch => {
    const action = {
      type: TRACKER_FAILURE,
      error
    }
    dispatch(action)
  }
}

export function orderClick(order) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `orders/${order.id}/`,
      authenticated: true,
      types: [ORDER_CLICK_REQUEST, ORDER_CLICK_SUCCESS, ORDER_CLICK_FAILURE]
    }
  }
}

export function orderMapClick(order) {
  return dispatch => {
    const action = {
      type: ORDER_MAP_CLICK,
      order
    }
    dispatch(action)
  }
}

export function orderDetailClose() {
  return dispatch => {
    const action = {
      type: ORDER_DETAIL_CLOSE
    }
    dispatch(action)
  }
}

export function courierMapClick(courier) {
  return dispatch => {
    const action = {
      type: COURIER_MAP_CLICK,
      courier
    }
    dispatch(action)
  }
}

export function deleteCourierActive() {
  return dispatch => {
    const action = {
      type: DELETE_COURIER_ACTIVE
    }
    dispatch(action)
  }
}
