import * as moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const addonStyle = {
  backgroundColor: '#fff',
  border: '1px solid #E5E6E7',
  borderRadius: '1px',
  color: 'inherit',
  fontSize: '14px',
  fontWeight: '400',
  padding: '6px 12px',
  textAlign: 'center'
}

export const FilterDatapicker = ({
  filters,
  updateCallback,
  propertyName,
  isRange,
  label
}) => {
  let initialDate = `${propertyName}_0`
  let finalDate = `${propertyName}_1`
  let rangeProps = {}
  let startProps = {}
  let endProps = {}
  let startDate = !filters[initialDate] ? null : moment(filters[initialDate])
  let endDate = filters[finalDate] ? moment(filters[finalDate]) : null
  let rangeDates

  if (isRange && endDate && startDate) {
    rangeDates = endDate.diff(startDate, 'days')
    if (rangeDates > 30 || rangeDates < 0) {
      startDate = moment().startOf('day')
      endDate = moment().endOf('day')
    }
    startProps = {
      maxDate: endDate
    }
    endProps = {
      minDate: startDate
    }
    rangeProps = {
      startDate,
      endDate
    }
  }

  return (
    <div className="js-filter-child ">
      {label ? <label>{label}</label> : null}
      <div
        className="input-daterange input-group"
        style={{ display: 'inline-flex' }}
      >
        <DatePicker
          popperModifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false
            }
          }}
          selected={filters[initialDate] && moment(filters[initialDate])}
          selectsStart
          onSelect={function(date) {
            let rangeDates = this.endDate
              ? this.endDate.diff(date, 'days')
              : null
            let updateDate
            let updatefinalDate
            if (rangeDates > 30) {
              updateDate = date.startOf('day').toISOString()
              updatefinalDate = date
                .startOf('day')
                .add(30, 'days')
                .toISOString()
            } else {
              if (rangeDates < 0) {
                updateDate = date.startOf('day').toISOString()
                updatefinalDate = date.startOf('day').toISOString()
              } else {
                updateDate = date.startOf('day').toISOString()
                updatefinalDate = this.endDate
                  ? this.endDate.toISOString()
                  : null
              }
            }
            updateCallback({
              [initialDate]: updateDate,
              [finalDate]: updatefinalDate,
              page: 1
            })
          }.bind({ rangeDates, endDate })}
          onChange={date =>
            updateCallback({
              [initialDate]: date ? date.startOf('day').toISOString() : null,
              page: 1
            })
          }
          className="form-control"
          placeholderText="Inicio"
          isClearable={true}
          {...startProps}
          {...rangeProps}
        />
        <span style={addonStyle}>Hasta</span>
        <DatePicker
          popperModifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false
            }
          }}
          selected={filters[finalDate] && moment(filters[finalDate])}
          selectsEnd
          onSelect={function(date) {
            let rangeDates = this.startDate
              ? date.diff(this.startDate, 'days')
              : null
            let updateDate
            let updateInitialDate
            if (rangeDates && rangeDates > 30) {
              updateDate = date.startOf('day').toISOString()
              updateInitialDate = date
                .startOf('day')
                .subtract(30, 'days')
                .toISOString()
            } else {
              if (rangeDates < 0) {
                updateDate = date.startOf('day').toISOString()
                updateInitialDate = date.startOf('day').toISOString()
              } else {
                updateDate = date.endOf('day').toISOString()
                updateInitialDate = this.startDate
                  ? this.startDate.toISOString()
                  : null
              }
            }
            updateCallback({
              [finalDate]: updateDate,
              [initialDate]: updateInitialDate,
              page: 1
            })
          }.bind({ rangeDates, startDate })}
          onChange={date =>
            updateCallback({
              [finalDate]: date ? date.endOf('day').toISOString() : null,
              page: 1
            })
          }
          className="form-control"
          placeholderText="Fin"
          isClearable={true}
          {...endProps}
          {...rangeProps}
        />
      </div>
    </div>
  )
}
