/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'

import Filter from '../02-molecules/Filter'
import { FilterSearchByDropDown } from '../02-molecules/FilterSearchByDropDown'
import { FilterList } from '../03-components/FilterList'
import { searchDashboard } from '../05-pages/order/list/config.search'

class CardListInfinite extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      active: this.props.filters[0].value
    }
    this.filterClickHandler = this.filterClickHandler.bind(this)
    this.delayedFilterHandler = this.delayedFilterHandler.bind(this)
    this.deleteCourierHandler = this.deleteCourierHandler.bind(this)
  }

  componentDidMount() {
    const { orderFilters } = this.props.dashboardProps
    this.setState({ active: orderFilters.group })
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dashboardProps.orderFilters !==
      this.props.dashboardProps.orderFilters
    ) {
      this.setState({ active: nextProps.dashboardProps.orderFilters.group })
    }
  }

  filterClickHandler(e, value, group) {
    e.preventDefault()
    this.setState({ active: value })
    this.props.closeDetail()
    this.props.updateFilters({ group: value, page: 1 })
  }

  delayedFilterHandler(e) {
    e.preventDefault()
    this.props.updateFilters({ delayed: false, group: 'pending' })
  }

  deleteCourierHandler(e) {
    e.preventDefault()
    this.props.deleteCourierActive()
  }

  render() {
    return (
      <div className="full-height-scroll">
        {this.props.searchable && (
          <FilterSearchByDropDown
            updateCallback={this.props.updateFilters}
            filters={this.props.filters}
            elements={searchDashboard}
          />
        )}

        {this.props.autosuggest && (
          <div>
            <label>Cliente</label>
            <Typeahead
              onChange={this.props.autosuggest.callBack}
              onInputChange={this.props.autosuggest.inputChangeHandler}
              options={this.props.autosuggest.searchArray || []}
              labelKey={option =>
                `${option.name} ${option.last_name} ${option.dni}`
              }
              placeholder="Buscar por nombre"
              minLength={2}
              clearButton
              editable={false}
            />
          </div>
        )}
        <FilterList
          expand
          value={this.props.value}
          spaced={this.props.spaced}
          config={this.props.config}
          filters={this.props.filters}
          updateCallback={this.props.updateFilters}
          style={this.props.style}
        />
        <ul className="nav nav-tabs nav-justified dashboard-tabs">
          {!this.props.autosuggest &&
            this.props.filters.map(item => (
              <Filter
                isGroup
                count={this.props.count && this.props.count[item.value]}
                key={item.value}
                active={this.state.active}
                onClick={this.filterClickHandler}
                {...item}
              />
            ))}
        </ul>
        {this.props.courierActive && (
          <div className="container-filter-courier">
            {this.props.courierActive.name}{' '}
            {this.props.courierActive.last_name}
            <span
              onClick={this.deleteCourierHandler}
              style={{ cursor: 'pointer', color: '#337ab7' }}
            >
              <i className="fa fa-times" />
            </span>
          </div>
        )}

        {this.props.dashboardProps.orderFilters &&
          this.props.dashboardProps.orderFilters.delayed && (
            <div className="container-filter-courier">
              Pedidos Demorados
              <span
                onClick={this.delayedFilterHandler}
                style={{ cursor: 'pointer', color: '#337ab7' }}
              >
                <i className="fa fa-times" />
              </span>
            </div>
          )}

        <ul className="list-group elements-list">{this.props.children}</ul>
      </div>
    )
  }
}

export default CardListInfinite
