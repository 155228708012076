import $ from 'jquery'

import { CALL_API } from '../middleware/api'

export const ADMIN_LIST_REQUEST = 'ADMIN_LIST_REQUEST'
export const ADMIN_LIST_SUCCESS = 'ADMIN_LIST_SUCCESS'
export const ADMIN_LIST_FAILURE = 'ADMIN_LIST_FAILURE'

export const ADMIN_LIST_SIMPLE_REQUEST = 'ADMIN_LIST_SIMPLE_REQUEST'
export const ADMIN_LIST_SIMPLE_SUCCESS = 'ADMIN_LIST_SIMPLE_SUCCESS'
export const ADMIN_LIST_SIMPLE_FAILURE = 'ADMIN_LIST_SIMPLE_FAILURE'

export const ADMIN_GET_DATA_REQUEST = 'ADMIN_GET_DATA_REQUEST'
export const ADMIN_GET_DATA_SUCCESS = 'ADMIN_GET_DATA_SUCCESS'
export const ADMIN_GET_DATA_FAILURE = 'ADMIN_GET_DATA_FAILURE'

export const ADMIN_GROUPS_REQUEST = 'ADMIN_GROUPS_REQUEST'
export const ADMIN_GROUPS_SUCCESS = 'ADMIN_GROUPS_SUCCESS'
export const ADMIN_GROUPS_FAILURE = 'ADMIN_GROUPS_FAILURE'

export const UPDATE_LIST_FILTERS = 'UPDATE_LIST_FILTERS'
export const CLEAN_DATA = 'CLEAN_DATA'

function parseParams(url, params) {
  const qs = $.param(params, true)
  if (qs) return `${url}?${qs}`
  return url
}

export function adminList(params = {}) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: parseParams('admins/', params),
      authenticated: true,
      types: [ADMIN_LIST_REQUEST, ADMIN_LIST_SUCCESS, ADMIN_LIST_FAILURE]
    }
  }
}

export function adminListSimple(params = {}) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'admins/simple/',
      authenticated: true,
      extra: params,
      types: [
        ADMIN_LIST_SIMPLE_REQUEST,
        ADMIN_LIST_SIMPLE_SUCCESS,
        ADMIN_LIST_SIMPLE_FAILURE
      ]
    }
  }
}

export function getAdminData(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `admins/${id}/`,
      authenticated: true,
      types: [
        ADMIN_GET_DATA_REQUEST,
        ADMIN_GET_DATA_SUCCESS,
        ADMIN_GET_DATA_FAILURE
      ]
    }
  }
}

export function getAdminsGroups() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'groups/',
      authenticated: true,
      types: [ADMIN_GROUPS_REQUEST, ADMIN_GROUPS_SUCCESS, ADMIN_GROUPS_FAILURE]
    }
  }
}

export function updateListFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_LIST_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function cleanData(data) {
  return dispatch => {
    const action = {
      type: CLEAN_DATA,
      data
    }
    dispatch(action)
  }
}
