import React, { Component } from 'react'

import Filter from '../02-molecules/Filter'
import { FilterList } from '../03-components/FilterList'

class CardList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: this.props.filters[0].value,
      search: ''
    }
    this.groups = this.groups.bind(this)
  }

  groups() {
    const results = []
    const children = this.props.children || []

    children.forEach(child => {
      for (let i = 0; i < this.props.searchFields.length; i++) {
        if (
          child.props[this.props.searchFields[i]]
            .toLowerCase()
            .includes(this.state.search)
        ) {
          results.push(child)
          break
        }
      }
    })

    return this.props.filters.reduce((acc, element) => {
      acc[element.value] = results.filter(
        child => child.props.filter === element.busy_level
      )
      return acc
    }, {})
  }

  render() {
    const groups = this.groups()

    return (
      <div className="full-height-scroll">
        {this.props.searchable && (
          <React.Fragment>
            <input
              type="text"
              placeholder="Buscar por nombre de transportista"
              className="form-control js-filter-child"
              style={{ maxWidth: 320 }}
              value={this.state.search}
              onChange={e => this.setState({ search: e.target.value })}
            />
          </React.Fragment>
        )}

        <FilterList
          expand
          value={this.props.value}
          spaced={this.props.spaced}
          config={this.props.config}
          filters={this.props.filters}
          updateCallback={this.props.updateFilters}
          style={{ display: 'block' }}
        />
        <ul className="nav nav-tabs nav-justified dashboard-tabs">
          {this.props.filters.map(item => (
            <Filter
              key={item.value}
              active={this.state.active}
              count={groups[item.value].length}
              onClick={(e, value) => {
                e.preventDefault()
                this.setState({ active: value })
              }}
              {...item}
            />
          ))}
        </ul>
        <ul
          className="list-group elements-list"
          style={{ overflow: 'hidden auto', height: 640 }}
        >
          {groups[this.state.active]}
        </ul>
      </div>
    )
  }
}

export default CardList
