import {
  CLIENT_CARD_ACTIVITY_REQUEST,
  CLIENT_CARD_ACTIVITY_SUCCESS,
  CLIENT_CARD_ACTIVITY_FAILURE
} from '../../actions/clientActions'

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    data: [],
    errors: {},
    needRefresh: false,
    cardData: false,
    selected: {}
  },
  action
) {
  switch (action.type) {
    case CLIENT_CARD_ACTIVITY_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case CLIENT_CARD_ACTIVITY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        cardData: action.data
      })

    case CLIENT_CARD_ACTIVITY_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    default:
      return state
  }
}
