import {
  PACKAGE_TYPES_REQUEST,
  PACKAGE_TYPES_SUCCESS,
  PACKAGE_TYPES_FAILURE,
  ONE_PACKAGE_TYPE_REQUEST,
  ONE_PACKAGE_TYPE_SUCCESS,
  ONE_PACKAGE_TYPE_FAILURE,
  RESET_PACKAGE_TYPES
} from '../../actions/packageTypeActions'

export default function reducer(
  state = {
    isFetching: false,
    data: [],
    dataDetail: {},
    errors: {},
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case PACKAGE_TYPES_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        data: []
      })

    case PACKAGE_TYPES_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data.results,
        packageTypesList: action.data
      })

    case PACKAGE_TYPES_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    case ONE_PACKAGE_TYPE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false,
        dataDetail: {}
      })

    case ONE_PACKAGE_TYPE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        dataDetail: action.data
      })

    case ONE_PACKAGE_TYPE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.errors
      })

    case RESET_PACKAGE_TYPES:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false,
        data: [],
        errors: {}
      })

    default:
      return state
  }
}
