import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const KARDEX_LIST_REQUEST = 'KARDEX_LIST_REQUEST'
export const KARDEX_LIST_SUCCESS = 'KARDEX_LIST_SUCCESS'
export const KARDEX_LIST_FAILURE = 'KARDEX_LIST_FAILURE'

export const UPDATE_KARDEX_FILTERS = 'UPDATE_KARDEX_FILTERS'

export function storageKardexList(params = {}) {
  let url = 'inventory_transactions/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [KARDEX_LIST_REQUEST, KARDEX_LIST_SUCCESS, KARDEX_LIST_FAILURE]
    }
  }
}

export function updateKardexFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_KARDEX_FILTERS,
      data
    }
    dispatch(action)
  }
}
