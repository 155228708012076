import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const ROUTING_LIST_REQUEST = 'ROUTING_LIST_REQUEST'
export const ROUTING_LIST_SUCCESS = 'ROUTING_LIST_SUCCESS'
export const ROUTING_LIST_FAILURE = 'ROUTING_LIST_FAILURE'

export const SET_ROUTE_ACTION_ACTIVE = 'SET_ROUTE_ACTION_ACTIVE'
export const UPDATE_LIST_FILTERS = 'UPDATE_LISTFILTERS'
export const SELECT_ROUTE = 'SELECT_ROUTE'
export const FOCUS_ORDER = 'FOCUS_ORDER'

export const RETRIEVE_ROUTES_REQUEST = 'RETRIEVE_ROUTES_REQUEST'
export const RETRIEVE_ROUTES_SUCCESS = 'RETRIEVE_ROUTES_SUCCESS'
export const RETRIEVE_ROUTES_FAILURE = 'RETRIEVE_ROUTES_FAILURE'

export const ROUTE_ASSIGNATION_REQUEST = 'ROUTE_ASSIGNATION_REQUEST'
export const ROUTE_ASSIGNATION_SUCCESS = 'ROUTE_ASSIGNATION_SUCCESS'
export const ROUTE_ASSIGNATION_FAILURE = 'ROUTE_ASSIGNATION_FAILURE'

export const CONFIRM_ROUTE_REQUEST = 'CONFIRM_ROUTE_REQUEST'
export const CONFIRM_ROUTE_SUCCESS = 'CONFIRM_ROUTE_SUCCESS'
export const CONFIRM_ROUTE_FAILURE = 'CONFIRM_ROUTE_FAILURE'

export const ROUTE_BATCH_UPDATE_REQUEST = 'ROUTE_BATCH_UPDATE_REQUEST'
export const ROUTE_BATCH_UPDATE_SUCCESS = 'ROUTE_BATCH_UPDATE_SUCCESS'
export const ROUTE_BATCH_UPDATE_FAILURE = 'ROUTE_BATCH_UPDATE_FAILURE'

export const BATCH_UPDATE_VERTICES_REQUEST = 'BATCH_UPDATE_VERTICES_REQUEST'
export const BATCH_UPDATE_VERTICES_SUCCESS = 'BATCH_UPDATE_VERTICES_SUCCESS'
export const BATCH_UPDATE_VERTICES_FAILURE = 'BATCH_UPDATE_VERTICES_FAILURE'

export const UPDATE_GROUP_POINTS = 'UPDATE_GROUP_POINTS'

export const GROUP_ORDERS_REQUEST = 'GROUP_ORDERS_REQUEST'
export const GROUP_ORDERS_SUCCESS = 'GROUP_ORDERS_SUCCESS'
export const GROUP_ORDERS_FAILURE = 'GROUP_ORDERS_FAILURE'

export const CREATE_ORDER_BATCH_REQUEST = 'CREATE_ORDER_BATCH_REQUEST'
export const CREATE_ORDER_BATCH_SUCCESS = 'CREATE_ORDER_BATCH_SUCCESS'
export const CREATE_ORDER_BATCH_FAILURE = 'CREATE_ORDER_BATCH_FAILURE'

export const ORDER_LIST_ROUTING_REQUEST = 'ORDER_LIST_ROUTING_REQUEST'
export const ORDER_LIST_ROUTING_SUCCESS = 'ORDER_LIST_ROUTING_SUCCESS'
export const ORDER_LIST_ROUTING_FAILURE = 'ORDER_LIST_ROUTING_FAILURE'

export const UPDATE_ORDER_ROUTING_FILTERS = 'UPDATE_ORDER_ROUTING_FILTERS'
export const CLEAN_ORDER_ROUTING_FILTER = 'CLEAN_ORDER_ROUTING_FILTER'
export const CLEAN_ROUTING_LIST = 'CLEAN_ROUTING_LIST'
export const MARK_SELECTED_ORDER = 'MARK_SELECTED_ORDER'

export const GROUP_ORDERS_RELEASE_REQUEST = 'GROUP_ORDERS_RELEASE_REQUEST'
export const GROUP_ORDERS_RELEASE_SUCCESS = 'GROUP_ORDERS_RELEASE_SUCCESS'
export const GROUP_ORDERS_RELEASE_FAILURE = 'GROUP_ORDERS_RELEASE_FAILURE'

export const SET_ROUTING_COURIERS = 'SET_ROUTING_COURIERS'

export const ROUTEGROUP_DELETE_REQUEST = 'ROUTEGROUP_DELETE_REQUEST'
export const ROUTEGROUP_DELETE_SUCCESS = 'ROUTEGROUP_DELETE_SUCCESS'
export const ROUTEGROUP_DELETE_FAILURE = 'ROUTEGROUP_DELETE_FAILURE'

export const CLEAN_ORDER_ROUTING_DATA = 'CLEAN_ORDER_ROUTING_DATA'

export const SELECT_ONE_ROUTE = 'SELECT_ONE_ROUTE'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const RESET_ROUTEGROUP = ' RESET_ROUTEGROUP'

export const EDIT_ROUTE_REQUEST = 'EDIT_ROUTE_REQUEST'
export const EDIT_ROUTE_SUCCESS = 'EDIT_ROUTE_SUCCESS'
export const EDIT_ROUTE_FAILURE = 'EDIT_ROUTE_FAILURE'

function generateQueryParams(url, params) {
  const qs = $.param(params, true)
  if (qs) return `${url}?${qs}`
  return url
}

export function orderRoutingList(params = {}) {
  const url = 'orders/batch/list/'
  const urlQuery = generateQueryParams(url, params)
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: urlQuery,
      authenticated: true,
      types: [ROUTING_LIST_REQUEST, ROUTING_LIST_SUCCESS, ROUTING_LIST_FAILURE]
    }
  }
}

export function assignCourier(data) {
  const url = `orders/batch/assignation/`
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: url,
      authenticated: true,
      data,
      types: [
        ROUTE_ASSIGNATION_REQUEST,
        ROUTE_ASSIGNATION_SUCCESS,
        ROUTE_ASSIGNATION_FAILURE
      ]
    }
  }
}

export function confirmRoute(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `orders/batch/accepted/`,
      authenticated: true,
      data,
      types: [
        CONFIRM_ROUTE_REQUEST,
        CONFIRM_ROUTE_SUCCESS,
        CONFIRM_ROUTE_FAILURE
      ]
    }
  }
}

export function updateListFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_LIST_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function focusOrder(latlon) {
  return dispatch => {
    const action = {
      type: FOCUS_ORDER,
      data: latlon
    }
    dispatch(action)
  }
}

export function retrieveRoutes(params) {
  const url = `orders/batch/retrieve/`
  const urlQuery = generateQueryParams(url, params)
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: urlQuery,
      authenticated: true,
      types: [
        RETRIEVE_ROUTES_REQUEST,
        RETRIEVE_ROUTES_SUCCESS,
        RETRIEVE_ROUTES_FAILURE
      ]
    }
  }
}

export function updateRoutes(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'orders/batch/update/',
      authenticated: true,
      data,
      types: [
        ROUTE_BATCH_UPDATE_REQUEST,
        ROUTE_BATCH_UPDATE_SUCCESS,
        ROUTE_BATCH_UPDATE_FAILURE
      ]
    }
  }
}

export function getUpdatedVertices(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'orders/batch/vertices/',
      authenticated: true,
      data,
      types: [
        BATCH_UPDATE_VERTICES_REQUEST,
        BATCH_UPDATE_VERTICES_SUCCESS,
        BATCH_UPDATE_VERTICES_FAILURE
      ]
    }
  }
}

export function updateGroupPoints(data) {
  return dispatch => {
    const action = {
      type: UPDATE_GROUP_POINTS,
      data
    }
    dispatch(action)
  }
}

export function setRouteActionActive(actionName) {
  return dispatch => {
    const action = {
      type: SET_ROUTE_ACTION_ACTIVE,
      data: actionName
    }
    dispatch(action)
  }
}

export function groupOrders(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'group/orders/',
      authenticated: true,
      data,
      types: [GROUP_ORDERS_REQUEST, GROUP_ORDERS_SUCCESS, GROUP_ORDERS_FAILURE]
    }
  }
}

export function createOrderBatch(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `orders/batch/individual/`,
      authenticated: true,
      data,
      types: [
        CREATE_ORDER_BATCH_REQUEST,
        CREATE_ORDER_BATCH_SUCCESS,
        CREATE_ORDER_BATCH_FAILURE
      ]
    }
  }
}

export function releaseOrderBatch(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `orders/batch/release/`,
      authenticated: true,
      data,
      types: [
        GROUP_ORDERS_RELEASE_REQUEST,
        GROUP_ORDERS_RELEASE_SUCCESS,
        GROUP_ORDERS_RELEASE_FAILURE
      ]
    }
  }
}

export function cleanOrderRoutingFilters() {
  return dispatch => {
    const action = {
      type: CLEAN_ORDER_ROUTING_FILTER
    }
    dispatch(action)
  }
}

export function cleanOrderRoutingData() {
  return dispatch => {
    const action = {
      type: CLEAN_ORDER_ROUTING_DATA
    }
    dispatch(action)
  }
}

export function updateOrderRoutingFilters(data, extra) {
  return dispatch => {
    const action = {
      type: UPDATE_ORDER_ROUTING_FILTERS,
      data,
      extra
    }
    dispatch(action)
  }
}

export function orderListRouting(params = {}) {
  let url = 'orders_groups/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: url,
      authenticated: true,
      msg: 'order',
      extra: params,
      params,
      types: [
        ORDER_LIST_ROUTING_REQUEST,
        ORDER_LIST_ROUTING_SUCCESS,
        ORDER_LIST_ROUTING_FAILURE
      ]
    }
  }
}

export function cleanRoutingList() {
  return dispatch => {
    const action = {
      type: CLEAN_ROUTING_LIST
    }
    dispatch(action)
  }
}

export function markSelectedOrder(data) {
  return dispatch => {
    const action = {
      type: MARK_SELECTED_ORDER,
      data
    }
    dispatch(action)
  }
}

export function selectOneRoute(route) {
  return dispatch => {
    const action = {
      type: SELECT_ONE_ROUTE,
      data: route
    }
    dispatch(action)
  }
}

export function selectRoute(obj) {
  return dispatch => {
    const action = {
      type: SELECT_ROUTE,
      data: obj
    }
    dispatch(action)
  }
}

export function showMessage() {
  return dispatch => {
    const action = {
      type: SHOW_MESSAGE
    }
    dispatch(action)
  }
}

export function hideMessage() {
  return dispatch => {
    const action = {
      type: HIDE_MESSAGE
    }
    dispatch(action)
  }
}

export function routeGroupDelete(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `orders/batch/delete/?id_0=${id}`,
      authenticated: true,
      types: [
        ROUTEGROUP_DELETE_REQUEST,
        ROUTEGROUP_DELETE_SUCCESS,
        ROUTEGROUP_DELETE_FAILURE
      ]
    }
  }
}

export function editRouteMemo(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `orders/batch/route_memo/`,
      authenticated: true,
      data,
      types: [EDIT_ROUTE_REQUEST, EDIT_ROUTE_SUCCESS, EDIT_ROUTE_FAILURE]
    }
  }
}

export function resetRouteGroup() {
  return dispatch => {
    const action = {
      type: RESET_ROUTEGROUP
    }
    dispatch(action)
  }
}
