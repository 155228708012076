import orderActions, {
  ORDER_UPDATE_NOTE_FAILURE,
  ORDER_UPDATE_NOTE_SUCCESS,
  ORDER_UPDATE_NOTE_REQUEST,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAILURE,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_FAILURE,
  ORDER_RETRY_PAYMENT_REQUEST,
  ORDER_RETRY_PAYMENT_SUCCESS,
  ORDER_RETRY_PAYMENT_FAILURE,
  ORDER_CHARGE_PURSE_REQUEST,
  ORDER_CHARGE_PURSE_SUCCESS,
  ORDER_CHARGE_PURSE_FAILURE,
  UPDATE_ORDER_PHOTO,
  SHOW_TOAST,
  HIDE_TOAST
} from '../../actions/orderActions'

const extractErrMessage = errors => {
  let messages = []
  Object.values(errors).forEach(errorArray => {
    if (Array.isArray(errorArray)) {
      messages = messages.concat(errorArray)
    }
  })
  return messages.join(' ')
}

export default function reducer(
  state = {
    isFetching: false,
    isUploading: false,
    data: {},
    orderDataUpdate: {},
    notes: {},
    errors: [],
    selectedOrder: null,
    toast: {
      message: '',
      type: '',
      visible: false
    }
  },
  action
) {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })
    case ORDER_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        orderDataUpdate: state.data
      })
    case ORDER_UPDATE_FAILURE:
      const errorMessage = extractErrMessage(action.errors)
      return {
        ...state,
        isFetching: false,
        errors: action.errors,
        toast: {
          message: 'Error al actualizar precio/costo: ' + errorMessage,
          type: 'error',
          visible: true
        }
      }
    case SHOW_TOAST:
      return {
        ...state,
        toast: {
          message: action.payload.message,
          type: action.payload.type,
          visible: true
        }
      }
    case HIDE_TOAST:
      return {
        ...state,
        toast: {
          message: '',
          type: '',
          visible: false
        }
      }
    case ORDER_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case ORDER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case ORDER_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })
    case ORDER_UPDATE_NOTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case ORDER_UPDATE_NOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        notes: action.data
      })

    case ORDER_UPDATE_NOTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case ORDER_RETRY_PAYMENT_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case ORDER_RETRY_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false
      })

    case ORDER_RETRY_PAYMENT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })

    case ORDER_CHARGE_PURSE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case ORDER_CHARGE_PURSE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false
      })

    case ORDER_CHARGE_PURSE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false
      })

    case UPDATE_ORDER_PHOTO:
      return Object.assign({}, state, {
        selectedOrder: action.data
      })

    case orderActions.ORDER_UPLOAD_PHOTO_REQUEST:
      return Object.assign({}, state, {
        isUploading: true
      })
    case orderActions.ORDER_UPLOAD_PHOTO_SUCCESS:
      return Object.assign({}, state, {
        isUploading: false
      })
    case orderActions.ORDER_UPLOAD_PHOTO_FAILURE:
      return Object.assign({}, state, {
        isUploading: false
      })

    default:
      return state
  }
}
