import moment from 'moment'

import * as orderRoutingActions from '../../actions/orderRoutingActions'
import { COURIER_LIST_SUCCESS } from '../../actions/courierActions'
import { ADMIN_CITIES_SUCCESS } from '../../actions/all/adminCitiesActions'
import { defaultMapCenter } from '../../utils/variables'
import { getCoordinates, getPosition } from '../../utils'

const mapCenter = JSON.parse(localStorage.getItem('adminCity'))

const initialState = {
  isFetching: false,
  isGrouping: false,
  isSaving: false,
  isUpdating: false,
  isDeleting: false,
  isConfirming: false,
  isRetrieve: false,
  activeAction: null,
  orderData: [],
  totalOrders: 0,
  routedOrders: [],
  routingCouriers: [],
  mapCenter,
  zoom: 13,
  groups: [],
  data: {},
  routingFilters: {
    client: '',
    type: 3,
    start_date: null,
    end_date: null
  },
  courierData: {},
  needRefresh: false,
  filters: {
    page: 1
  },
  selectedOne: {},
  selected: {},
  error: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case orderRoutingActions.ROUTING_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case orderRoutingActions.ROUTING_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case orderRoutingActions.ROUTING_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: {}
      })

    case orderRoutingActions.ROUTE_ASSIGNATION_REQUEST:
      return Object.assign({}, state, {
        isUpdating: true
      })

    case orderRoutingActions.ROUTE_ASSIGNATION_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activeAction: null,
        needRefresh: true
      })

    case orderRoutingActions.ROUTE_ASSIGNATION_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.data
      })

    case orderRoutingActions.CONFIRM_ROUTE_REQUEST:
      return Object.assign({}, state, {
        isConfirming: true
      })
    case orderRoutingActions.CONFIRM_ROUTE_SUCCESS:
      return Object.assign({}, state, {
        isConfirming: true,
        needRefresh: true
      })
    case orderRoutingActions.CONFIRM_ROUTE_FAILURE:
      return Object.assign({}, state, {
        isConfirming: false,
        error: action.data
      })

    case orderRoutingActions.SET_ROUTE_ACTION_ACTIVE:
      return Object.assign({}, state, {
        activeAction: action.data
      })

    case orderRoutingActions.UPDATE_LIST_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    case orderRoutingActions.FOCUS_ORDER:
      return Object.assign({}, state, {
        mapCenter: action.data
      })

    case orderRoutingActions.CLEAN_ORDER_ROUTING_DATA:
      return Object.assign({}, state, {
        orderData: []
      })

    case orderRoutingActions.RETRIEVE_ROUTES_REQUEST:
      return Object.assign({}, state, {
        groups: []
      })

    case orderRoutingActions.RETRIEVE_ROUTES_SUCCESS:
      return Object.assign({}, state, {
        groups: action.data,
        isRetrieve: true,
        routedOrders: action.data
      })

    case orderRoutingActions.RETRIEVE_ROUTES_FAILURE:
      return Object.assign({}, state, {
        groups: [],
        error: action.data,
        isRetrieve: false
      })

    case orderRoutingActions.ROUTE_BATCH_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isSaving: true
      })

    case orderRoutingActions.ROUTE_BATCH_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false
      })

    case orderRoutingActions.ROUTE_BATCH_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        error: action.data
      })

    case orderRoutingActions.BATCH_UPDATE_VERTICES_REQUEST:
      return Object.assign({}, state, {
        isUpdating: true
      })

    case orderRoutingActions.BATCH_UPDATE_VERTICES_SUCCESS:
      const updatedVertices = state.groups.map((route, i) => {
        route.vertices = [...action.data[i]]
        return route
      })
      return Object.assign({}, state, {
        isUpdating: false,
        groups: updatedVertices
      })

    case orderRoutingActions.BATCH_UPDATE_VERTICES_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.data
      })

    case orderRoutingActions.UPDATE_GROUP_POINTS:
      return Object.assign({}, state, {
        routedOrders: action.data
      })

    case orderRoutingActions.ORDER_LIST_ROUTING_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case orderRoutingActions.ORDER_LIST_ROUTING_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        orderData: action.data.orders,
        totalOrders: action.data.total_orders,
        needRefresh: false
      })

    case orderRoutingActions.ORDER_LIST_ROUTING_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.data,
        needRefresh: false
      })

    case orderRoutingActions.CLEAN_ORDER_ROUTING_FILTER:
      return Object.assign({}, state, {
        groups: [],
        routedOrders: [],
        orderData: [],
        isRetrieve: false,
        mapCenter,
        routingFilters: {
          client: '',
          type: 3
        }
      })

    case orderRoutingActions.GROUP_ORDERS_REQUEST:
      return Object.assign({}, state, {
        isGrouping: true
      })

    case orderRoutingActions.GROUP_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        isGrouping: false,
        groups: action.data
      })

    case orderRoutingActions.GROUP_ORDERS_FAILURE:
      return Object.assign({}, state, {
        isGrouping: false,
        error: action.data
      })

    case orderRoutingActions.UPDATE_ORDER_ROUTING_FILTERS:
      return Object.assign({}, state, {
        routingFilters: Object.assign(
          {},
          {
            ...state.routingFilters,
            start_date: moment()
              .subtract(1, 'days')
              .startOf('day')
              .format('DD-MM-YYYY')
              .toString(),
            end_date: moment()
              .add(1, 'days')
              .endOf('day')
              .format('DD-MM-YYYY')
              .toString()
          },
          action.data
        ),
        needRefresh: true
      })

    case orderRoutingActions.ROUTEGROUP_DELETE_REQUEST:
      return Object.assign({}, state, {
        isDeleting: true
      })

    case orderRoutingActions.ROUTEGROUP_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        activeAction: null,
        needRefresh: true
      })

    case orderRoutingActions.ROUTEGROUP_DELETE_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false
      })

    case orderRoutingActions.GROUP_ORDERS_RELEASE_REQUEST:
      return Object.assign({}, state, {
        isUpdating: true
      })

    case orderRoutingActions.GROUP_ORDERS_RELEASE_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        selected: [],
        activeAction: null,
        needRefresh: true
      })

    case orderRoutingActions.CREATE_ORDER_BATCH_REQUEST:
      return Object.assign({}, state, {
        isSaving: true
      })

    case orderRoutingActions.CREATE_ORDER_BATCH_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false
      })

    case orderRoutingActions.CREATE_ORDER_BATCH_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        error: action.data
      })

    case COURIER_LIST_SUCCESS:
      return Object.assign({}, state, {
        courierData: action.data
      })

    case orderRoutingActions.CLEAN_ROUTING_LIST:
      return Object.assign({}, state, {
        filters: { page: 1 },
        data: [],
        courierData: {},
        activeAction: null
      })

    case orderRoutingActions.SET_ROUTING_COURIERS:
      return Object.assign({}, state, {
        routingCouriers: action.data
      })

    case ADMIN_CITIES_SUCCESS:
      return Object.assign({}, state, {
        mapCenter: getPosition(getCoordinates(action.data)) || defaultMapCenter
      })

    case orderRoutingActions.SELECT_ONE_ROUTE:
      return Object.assign({}, state, {
        selectedOne: action.data
      })

    case orderRoutingActions.SELECT_ROUTE:
      return Object.assign({}, state, {
        selected: action.data
      })

    case orderRoutingActions.SHOW_MESSAGE:
      return Object.assign({}, state, {
        message: true
      })

    case orderRoutingActions.HIDE_MESSAGE:
      return Object.assign({}, state, {
        message: false
      })

    case orderRoutingActions.EDIT_ROUTE_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })
    case orderRoutingActions.EDIT_ROUTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: true
      })
    case orderRoutingActions.EDIT_ROUTE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false
      })

    case orderRoutingActions.RESET_ROUTEGROUP:
      return Object.assign({}, state, {
        dataDetail: {},
        data: undefined
      })

    default:
      return state
  }
}
