import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const Filter = ({
  value,
  group,
  icon,
  label,
  active,
  count,
  style,
  onClick,
  title
}) => (
  <li className={`${active === value && 'active'} `}>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={value}>{label}</Tooltip>}
    >
      <a
        href="$"
        onClick={e => onClick(e, value, group)}
        style={{ whiteSpace: 'nowrap' }}
      >
        {title ? (
          <>
            <span>{title}</span>
          </>
        ) : (
          ''
        )}
        <i style={style} className={`fa fa-${icon}`} />
        {!!count ? (
          <>
            <span>{` (${count})`}</span>{' '}
          </>
        ) : (
          ''
        )}
      </a>
    </OverlayTrigger>
  </li>
)

Filter.propTypes = {
  /** Value that represents the tab section. */
  value: PropTypes.string.isRequired,

  /** Information text. */
  label: PropTypes.string.isRequired,

  icon: PropTypes.string.isRequired,

  /** Active value used filter. */
  active: PropTypes.string.isRequired,

  /** Triggeres when click the tab. */
  onClick: PropTypes.func.isRequired,

  /** Style to the icon. */
  style: PropTypes.object
}

export default Filter
