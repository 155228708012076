import React from 'react'
import TimePicker from 'react-time-picker'

const TimeOnlyForm = ({ name, value, onChange, placeholder, disabled }) => {
  return (
    <TimePicker
      format="HH:mm"
      name={name}
      disableClock={true}
      disabled={disabled}
      value={value}
      onChange={val => onChange(val)}
      placeholderText={placeholder}
      hourPlaceholder={'Hora '}
      minutePlaceholder={' Min'}
      maxDetail="minute"
    />
  )
}

const TimeOnlyInputForm = field => {
  return (
    <div className="col-lg-12">
      <TimeOnlyForm {...field} />
    </div>
  )
}

export default TimeOnlyInputForm
