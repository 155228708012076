import {
  CLIENT_DETAIL_REQUEST,
  CLIENT_DETAIL_SUCCESS,
  CLIENT_DETAIL_FAILURE,
  MERCHANT_DETAIL_REQUEST,
  MERCHANT_DETAIL_SUCCESS,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAILURE,
  RESET_CONFIGCLIENT
} from '../../actions/clientActions'

const parseData = data => {
  const newData = { ...data }
  if (newData.price_configurations.length) {
    newData.price_configurations = newData.price_configurations.reduce(
      (acc, curr) => {
        if (curr.order_type.id) {
          const config = {
            order_type: curr.order_type,
            config: [
              {
                vehicle_type: curr.vehicle_type,
                price_formula: curr.price_formula,
                price_formula_value: curr.price_formula_value,
                coverage: curr.coverage
              }
            ]
          }
          acc.push(config)
        }
        return acc
      },
      []
    )
  }

  if (newData.order_types) {
    const arrOrder = []
    newData.order_types.map(el => {
      arrOrder[el.id - 1] = el
      return el
    })
    newData.order_types = arrOrder
  }

  if (newData.created_order_types) {
    const arrOrderCreated = []
    newData.created_order_types.map(el => {
      arrOrderCreated[el.id - 1] = el
      return el
    })
    newData.created_order_types = arrOrderCreated
  }

  return newData
}
export default function reducer(
  state = {
    isFetching: false,
    isUpdating: false,
    data: {},
    errors: [],
    clientData: false,
    clientErrors: false,
    needRefresh: false
  },
  action
) {
  switch (action.type) {
    case CLIENT_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case CLIENT_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: parseData(action.data)
      })

    case CLIENT_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case MERCHANT_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case MERCHANT_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case CLIENT_UPDATE_REQUEST:
      return Object.assign({}, state, {
        isUpdating: true
      })

    case CLIENT_UPDATE_SUCCESS:
      window.location = `/clients`
      return Object.assign({}, state, {
        isUpdating: false,
        clientData: action.data,
        needRefresh: true
      })

    case CLIENT_UPDATE_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false,
        clientErrors: action.data
      })

    case RESET_CONFIGCLIENT:
      return Object.assign({}, state, {
        data: {},
        errors: []
      })

    default:
      return state
  }
}
