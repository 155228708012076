import bonusActions, {
  UPDATE_BONUS_FILTERS,
  RESET_BONUS
} from '../../actions/bonusActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    data: [],
    dataDetail: {},
    bonusData: {},
    errors: [],
    filters: {
      search: '',
      page: 1,
      is_active: true
    }
  },
  action
) {
  switch (action.type) {
    case bonusActions.BONUS_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case bonusActions.BONUS_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case bonusActions.BONUS_LIST_FAILED:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case bonusActions.BONUS_CREATE_SUCCESS:
      window.location = `/couriers/bonuses/${action.data.id}`
      return Object.assign({}, state, {
        isFetching: false,
        bonusData: action.data
      })

    case bonusActions.BONUS_UPDATE_SUCCESS:
      window.location = `/couriers/bonuses`
      return Object.assign({}, state, {
        isFetching: false,
        bonusData: {}
      })

    case bonusActions.BONUS_DETAIL_REQUEST:
      return Object.assign({}, state, {
        isFetching: true
      })

    case bonusActions.BONUS_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        dataDetail: action.data
      })

    case bonusActions.BONUS_DETAIL_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_BONUS_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    case RESET_BONUS:
      return Object.assign({}, state, {
        isFetching: false,
        needRefresh: false,
        data: [],
        dataDetail: {},
        errors: {}
      })

    default:
      return state
  }
}
