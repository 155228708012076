import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAILURE,
  UPDATE_CLIENT_FILTERS,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAILURE,
  CLIENT_TOKEN_SUCCESS,
  SELECT_CLIENT,
  SHOW_MESSAGE,
  HIDE_MESSAGE,
  CLIENT_UPGRADE_REQUEST,
  CLIENT_UPGRADE_SUCCESS,
  CLIENT_UPGRADE_FAILURE
} from '../../actions/clientActions'

export default function reducer(
  state = {
    isFetching: false,
    isDeleting: false,
    data: {},
    errors: {},
    filters: {
      page: 1,
      search: '',
      type: [],
      credit: null,
      only_billable: null,
      only_positive_balance: null
    },
    needRefresh: false,
    clientData: {},
    selected: {}
  },
  action
) {
  switch (action.type) {
    case CLIENT_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })

    case CLIENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })

    case CLIENT_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data
      })

    case UPDATE_CLIENT_FILTERS:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })

    case CLIENT_DELETE_REQUEST:
      return Object.assign({}, state, {
        isDeleting: true
      })

    case CLIENT_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        activeAction: null,
        needRefresh: true
      })

    case CLIENT_DELETE_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false
      })

    case SELECT_CLIENT:
      return Object.assign({}, state, {
        selected: action.data
      })

    case SHOW_MESSAGE:
      return Object.assign({}, state, {
        message: true
      })

    case HIDE_MESSAGE:
      return Object.assign({}, state, {
        message: false
      })

    case CLIENT_TOKEN_SUCCESS:
      window.open(
        `${process.env.REACT_APP_CLIENT_WEB_URL}/login/?token=${action.data.token}`
      )
      return state

    case CLIENT_UPGRADE_REQUEST:
      return {
        ...state,
        isUpgrading: true
      }

    case CLIENT_UPGRADE_SUCCESS:
      return {
        ...state,
        isUpgrading: false,
        needRefresh: true
      }

    case CLIENT_UPGRADE_FAILURE:
      return {
        ...state,
        isUpgrading: false,
        errors: action.data
      }

    default:
      return state
  }
}
