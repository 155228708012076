import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// PURSETYPE GENERAL INFORMATION
export const PURSETYPE_LIST_REQUEST = 'PURSETYPE_LIST_REQUEST'
export const PURSETYPE_LIST_SUCCESS = 'PURSETYPE_LIST_SUCCESS'
export const PURSETYPE_LIST_FAILURE = 'PURSETYPE_LIST_FAILURE'

export function purseTypeList(params = {}) {
  let url = 'pursetype/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        PURSETYPE_LIST_REQUEST,
        PURSETYPE_LIST_SUCCESS,
        PURSETYPE_LIST_FAILURE
      ]
    }
  }
}
