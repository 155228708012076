import React from 'react'
import PropTypes from 'prop-types'

import clock from '../../assets/img/clock.svg'

/**
 * Choose className depending on the status
 * @param {string} status
 */
const chooseClassName = (status, logic_status) => {
  switch (status) {
    case 'approved':
    case 'searching':
    case 'pending':
      return 'label-primary'

    case 'accepted':
    case 'asigned':
      return 'label-success'

    case 'in_progress':
      return 'label-info'

    case 'idle':
    case 'willbecancel':
    case 'cancelled':
      return 'label-warning'

    case 'delay':
    case 'busy':
    case 'rejected':
      return 'label-danger'

    case 'ended':
      return logic_status === 'failed' ? 'm-failed ' : 'label-default'

    default:
      return 'label-default'
  }
}

/**
 * Status label Component
 * @param {Object} props Component properties
 * @param {Object} props.value Status object
 * @param {string} props.value.status Status (used for the label color)
 * @param {string} props.value.status_label Status label for displaying
 */
const Status = ({ value, className }) => {
  const { status_label, status, logic_status } = value
  return (
    <span
      className={`label ${className} ${chooseClassName(status, logic_status)}`}
    >
      {status === 'willbecancel' && (
        <object
          className="clock-svg"
          data={clock}
          type="image/svg+xml"
          aria-label="status"
        />
      )}
      {status_label}
    </span>
  )
}

Status.defaultProps = {
  className: ''
}

Status.propTypes = {
  value: PropTypes.shape({
    status_label: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  className: PropTypes.string
}

export default Status
