import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import $ from 'jquery'
import * as classNames from 'classnames'

import logo from '../assets/img/logoUrbanerBlackH.png'
import { loginUser } from '../actions/userActions'

class LoginPage extends React.Component {
  static defaultProps = {
    errors: {}
  }

  constructor(props) {
    super(props)

    this.state = { email: '', password: '' }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    $('body').addClass('gray-bg')
  }

  componentWillReceiveProps(nextProps) {
    const { history } = this.props
    if (nextProps.isAuthenticated) history.push('/')
  }

  componentWillUnmount() {
    $('body').removeClass('gray-bg')
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit(e) {
    e.preventDefault()
    const { login } = this.props
    login(this.state)
  }

  render() {
    const { isFetching, errors } = this.props
    const { email, password } = this.state
    const btnClass = classNames('btn btn-primary block full-width m-b', {
      disabled: isFetching
    })
    return (
      <div className="middle-box text-center loginscreen ">
        <div>
          <div>
            <h1 className="logo-name">
              <img alt="logo" src={logo} style={{ width: '300px' }} />
            </h1>
          </div>

          {errors.non_field_errors && (
            <div className="alert alert-danger">
              {errors.non_field_errors &&
                errors.non_field_errors.map((message, index) => (
                  <p key={index}>{message}</p>
                ))}
            </div>
          )}

          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              {errors.email &&
                errors.email.map((message, index) => (
                  <span
                    key={index}
                    className="help-block text-danger pull-left"
                  >
                    {message}
                  </span>
                ))}
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                required=""
                value={email}
                onChange={this.onChange}
              />
            </div>
            <div className="form-group">
              {errors.password &&
                errors.password.map((message, index) => (
                  <span
                    key={index}
                    className="help-block text-danger pull-left"
                  >
                    {message}
                  </span>
                ))}
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
                required=""
                value={password}
                onChange={this.onChange}
              />
            </div>
            <button type="submit" className={btnClass}>
              Login
            </button>
          </form>
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    non_field_errors: PropTypes.array,
    email: PropTypes.array,
    password: PropTypes.array
  }),
  isFetching: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
}

const mapStateToProps = state => state.login

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login: loginUser
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)
