import React from 'react'

const InputCheckbox = ({ id, text, ...rest }) => {
  const { disabled, checked } = rest

  return (
    <div style={{ display: 'flex', paddingLeft: '15px' }}>
      {disabled ? (
        // Mostrar un emoji basado en el estado `checked`
        <span style={{ paddingTop: disabled ? '8px' : '' }}>
          {checked ? '✅' : '❌'}
        </span>
      ) : (
        <>
          <input
            id={id}
            type="checkbox"
            style={{ marginRight: '5px' }}
            {...rest}
          />
          <label htmlFor={id}>{text}</label>
        </>
      )}
    </div>
  )
}

export default InputCheckbox
