import React from 'react'
import DatePicker from 'react-datepicker'
import { addMonths } from 'date-fns'

import { parseStateToValue, formatValueToState } from '../../utils/events'

const DateForm = ({
  minDate,
  maxDate,
  name,
  withTimePicker,
  value,
  onChange,
  placeholder
}) => {
  const objMinDate = {}
  if (minDate) {
    objMinDate.minDate = minDate
  }
  const objMaxDate = {}
  if (maxDate) {
    objMaxDate.maxDate = maxDate
  }

  const toRepresentValue = value => {
    if (!withTimePicker) {
      return value.format('YYYY-MM-DD')
    }
    return formatValueToState(value)
  }

  return (
    <DatePicker
      name={name}
      className="form-control"
      dateFormat={withTimePicker ? 'LLL' : 'LL'}
      showTimeSelect={withTimePicker}
      selected={parseStateToValue(value)}
      onChange={val => onChange(toRepresentValue(val))}
      placeholderText={placeholder}
      minDate={!withTimePicker ? new Date() : null}
      maxDate={!withTimePicker ? addMonths(new Date(), 2) : null}
      {...objMinDate}
      {...objMaxDate}
    />
  )
}

const DatePickerForm = field => {
  return (
    <div className="col-lg-12">
      <DateForm {...field} />
    </div>
  )
}

export default DatePickerForm
