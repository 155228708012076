// TODO:
// - Add documentation and proptypes

import React from 'react'

const Section = ({ children }) => (
  <div className="wrapper wrapper-content  full-height-wrapper">
    <div className="row">
      <div className="col-lg-12">
        <div className="ibox">{children}</div>
      </div>
    </div>
  </div>
)

export default Section
