import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import createSagaMiddleware from 'redux-saga'

import reducer from './reducers'
import { dashboardSaga } from './sagas'
import api from './middleware/api'

const sagaMiddleware = createSagaMiddleware()
const middleware = applyMiddleware(promise(), thunk, api, sagaMiddleware)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhancers(middleware))

sagaMiddleware.run(dashboardSaga)

export default store
