import {
  GET_GLOBAL_CONFIG_REQUEST,
  GET_GLOBAL_CONFIG_SUCCESS,
  GET_GLOBAL_CONFIG_FAILURE
} from '../../actions/globalConfigurationActions'

const initialState = {
  isFetching: false,
  data: {},
  errors: {}
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case GET_GLOBAL_CONFIG_REQUEST:
      return { ...state, isFetching: true }

    case GET_GLOBAL_CONFIG_SUCCESS:
      return { ...state, isFetching: false, data: actions.data }

    case GET_GLOBAL_CONFIG_FAILURE:
      return { ...state, isFetching: false, errors: actions.data }

    default:
      return state
  }
}
