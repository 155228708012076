import React from 'react'

export const FilterSearch = props => {
  const [searchInput, setSearchInput] = React.useState({})
  const { placeholder, label, name, updateCallback, customStyle } = props

  const updateSearchState = e => {
    setSearchInput({ [e.target.name]: e.target.value })
    if (e.target.value === '') {
      const { updateCallback } = props
      updateCallback({ [e.target.name]: '', page: 1 })
    }
  }

  const sendState = e => {
    if (e.key === 'Enter') {
      return updateCallback({
        [e.target.name]: searchInput[e.target.name],
        page: 1
      })
    }
    return false
  }

  return (
    <div className="js-filter-child" style={customStyle}>
      {label ? <label>{label}</label> : null}
      <input
        type="text"
        name={name || 'search'}
        placeholder={placeholder}
        className="form-control"
        value={searchInput[name]}
        onKeyPress={sendState}
        onChange={updateSearchState}
      />
    </div>
  )
}
