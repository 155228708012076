import {
  SELECT_COLLECTION,
  RESET_COLLECTION,
  COLLECTIONPAY_LIST_REQUEST,
  COLLECTIONPAY_LIST_SUCCESS,
  COLLECTIONPAY_LIST_FAILURE,
  UPDATE_COLLECTIONPAY_FILTERS,
  COLLECTIONPAY_DELETE_SUCCESS
} from '../../actions/collectionActions'

export default function reducer(
  state = {
    isFetching: false,
    needRefresh: false,
    data: {},
    selected: {},
    errors: {},
    filters: {
      page: 1,
      status: ['received', 'no_received'],
      created_at_0: null,
      created_at_1: null
    }
  },
  action
) {
  switch (action.type) {
    case COLLECTIONPAY_LIST_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        needRefresh: false
      })
    case COLLECTIONPAY_LIST_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errors: action.data || action.errors
      })
    case COLLECTIONPAY_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data
      })
    case UPDATE_COLLECTIONPAY_FILTERS:
      if (action.data.status && !action.data.status.length) {
        action.data.status = ['received', 'no_received']
      }
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, action.data),
        needRefresh: true
      })
    case RESET_COLLECTION:
      return Object.assign({}, state, {
        data: {},
        filters: {}
      })
    case SELECT_COLLECTION:
      return Object.assign({}, state, {
        selected: action.data
      })
    case COLLECTIONPAY_DELETE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.data,
        needRefresh: true
      })

    default:
      return state
  }
}
