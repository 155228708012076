import $ from 'jquery'
import { CALL_API } from '../middleware/api'

// PURSE GENERAL INFORMATION
export const PURSE_LIST_REQUEST = 'PURSE_LIST_REQUEST'
export const PURSE_LIST_SUCCESS = 'PURSE_LIST_SUCCESS'
export const PURSE_LIST_FAILURE = 'PURSE_LIST_FAILURE'
// PURSE GENERAL INFORMATION
export const PURSE_CREATE_REQUEST = 'PURSE_CREATE_REQUEST'
export const PURSE_CREATE_SUCCESS = 'PURSE_CREATE_SUCCESS'
export const PURSE_CREATE_FAILURE = 'PURSE_CREATE_FAILURE'
// PURSE UPDATE DATA
export const UPDATE_PURSE_FILTERS = 'UPDATE_PURSE_FILTERS'
export const CLEAN_PURSE = 'CLEAN_PURSE'

export const CHECK_CIPCODE_REQUEST = 'CHECK_CIPCODE_REQUEST'
export const CHECK_CIPCODE_SUCCESS = 'CHECK_CIPCODE_SUCCESS'
export const CHECK_CIPCODE_FAILURE = 'CHECK_CIPCODE_FAILURE'

export function purseList(params = {}) {
  let url = 'purse/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [PURSE_LIST_REQUEST, PURSE_LIST_SUCCESS, PURSE_LIST_FAILURE]
    }
  }
}

export function updatePurseFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_PURSE_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function cleanPurse() {
  return dispatch => {
    const action = {
      type: CLEAN_PURSE
    }
    dispatch(action)
  }
}
export function createPurse(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `purse/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [PURSE_CREATE_REQUEST, PURSE_CREATE_SUCCESS, PURSE_CREATE_FAILURE]
    }
  }
}

export function checkCipCode(data) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `purse/pago_efectivo_status/`,
      authenticated: true,
      data,
      localAPI: false,
      types: [
        CHECK_CIPCODE_REQUEST,
        CHECK_CIPCODE_SUCCESS,
        CHECK_CIPCODE_FAILURE
      ]
    }
  }
}
