import $ from 'jquery'
import { CALL_API } from '../middleware/api'

export const vehicle_typeS_REQUEST = 'vehicle_typeS_REQUEST'
export const vehicle_typeS_SUCCESS = 'vehicle_typeS_SUCCESS'
export const vehicle_typeS_FAILURE = 'vehicle_typeS_FAILURE'

export const vehicle_type_REQUEST = 'vehicle_type_REQUEST'
export const vehicle_type_SUCCESS = 'vehicle_type_SUCCESS'
export const vehicle_type_FAILURE = 'vehicle_type_FAILURE'

export const UPDATE_VEHICLETYPES_LIST_FILTERS =
  'UPDATE_VEHICLETYPES_LIST_FILTERS'
export const vehicle_typeS_RESET = 'vehicle_typeS_RESET'

export function getVehicleTypes(params = {}) {
  let url = 'vehicle_types/'
  const qs = $.param(params, true)
  if (qs) url += `?${qs}`

  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: url,
      authenticated: true,
      extra: params,
      types: [
        vehicle_typeS_REQUEST,
        vehicle_typeS_SUCCESS,
        vehicle_typeS_FAILURE
      ]
    }
  }
}

export function getVehicleTypeById(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      localAPI: false,
      endpoint: `vehicle_types/${id}/`,
      authenticated: true,
      types: [vehicle_type_REQUEST, vehicle_type_SUCCESS, vehicle_type_FAILURE]
    }
  }
}

export function updateListFilters(data) {
  return dispatch => {
    const action = {
      type: UPDATE_VEHICLETYPES_LIST_FILTERS,
      data
    }
    dispatch(action)
  }
}

export function resetVehicleTypes() {
  return dispatch => {
    const action = {
      type: vehicle_typeS_RESET
    }
    dispatch(action)
  }
}
